import { getLongDate } from '../../shared/utils';
import note from './note';

export default summary => `
<ul class="nav nav-tabs" role="tablist">
  <li class="active" role="presentation"><a id="tableTab" href="#patients" aria-controls="patients" role="tab" data-toggle="tab" data-id="1">Patients</a></li>
</ul>
<!-- Tab panes-->
<div class="tab-content">
  <div class="tab-pane tab-borders fade active in" id="patients" role="tabpanel">
    ${summary.patients && summary.patients.length > 0
    ? `<table class="table" id="patientTable">
    <thead>
      <tr>
        <th tooltip="The patient's NHS number" tooltip-append-to-body="true" tooltip-placement="bottom">NHS number</th>
        <th tooltip="A list of prescribing safety indicators that the patient is currently affected by" tooltip-append-to-body="true" tooltip-placement="bottom">Indicators breached</th>
        <th tooltip="The date since which the patient was originally found to be affected by this indicator" tooltip-append-to-body="true" tooltip-placement="bottom">Since</th>
        <th tooltip="Any notes associated with the patient are displayed here" tooltip-append-to-body="true" tooltip-placement="bottom">Notes</th>
      </tr>
    </thead>
    <tbody>
      ${summary.patients.map(patient => `
      <tr class="no-priority">
        <td data-patient-id="${patient.id}">${patient.nhs}</td>
        <td>
          ${patient.indicators.map(indicator => `
            <div title="${indicator.long_name}" data-toggle="tooltip" data-container="body" data-placement="left">${indicator.short_name}</div>
          `).join('')}
        </td>
        <td>
          ${patient.indicators.map(indicator => `
            <div>${getLongDate(indicator.since)}</div>
          `).join('')}
        </td>
        <td data-patient-id="${patient.id}" class="note-field">
           ${note(patient)}
        </td>
      </tr>
      `).join('')}
    </tbody>
  </table>`
    : 'No patients found'}

  </div>
</div>
  `;
