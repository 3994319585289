const indicatorList = indicators => `
<div class="form-group">
  <select class="form-control inline-select selectpicker" id="indicatorList" data-live-search="true" data-width="auto">
  ${indicators.sort((a, b) => {
    if (a.short_name > b.short_name) return 1;
    if (a.short_name === b.short_name) return 0;
    return -1;
  }).map(indicator => `
      <option value="${indicator._id}" ${indicator.isSelected ? 'selected' : ''}>${indicator.short_name}</option>`).join('')}
  </select>
</div>
`;

const dateList = (dates, id, name, title) => `
<div class="form-group left-buffer align-bottom">
  <label for="${name}" style="display:block;">${title}:</label>
  <select class="inline-select" id="${id}" name="${name}">
    ${dates
    .filter(date => date.shouldDisplay)
    .map(date => `
      <option value="${date._id}" ${date.isSelected ? 'selected' : ''}>${date.value}</option>
    `).join('')}
  </select>
</div>
`;

const reportTypeList = reportType => `
<div class="form-group left-buffer align-bottom">
  <label for="reportType" style="display:block;">Report type:</label>
  <select class="inline-select" id="typeList" name="reportType">
    <option value="affected" ${reportType === 'affected' ? 'selected' : ''}>Affected patients</option>
    <option value="resolved" ${reportType === 'resolved' ? 'selected' : ''}>Successful intervention</option>
    <option value="existing" ${reportType === 'existing' ? 'selected' : ''}>Action pending</option>
    <option value="new" ${reportType === 'new' ? 'selected' : ''}>New cases</option>
  </select>
</div>
`;

export default (indicators, reportType, dates, comparisonDates) => `
<form id="filterBarPracticeIndicator" class="form-inline">  
  ${indicatorList(indicators)}
  ${reportTypeList(reportType)}
  ${dateList(dates, 'dateList', 'reportDate', 'Report date')}
  ${dateList(comparisonDates, 'dateCompareList', 'comparisonDate', 'Comparison date')}
</form>
  `;
