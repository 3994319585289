import { escapeHTML } from '../../../shared/utils';

const getPracticeRows = (practices, ccgs) => practices.sort((a, b) => {
  if (a._id < b._id) return -1;
  return a._id === b._id ? 0 : 1;
}).map((practice, idx) => {
  const ccgOptions = ccgs
    .sort((a, b) => {
      if (a.short_name > b.short_name) return 1;
      if (a.short_name < b.short_name) return -1;
      return 0;
    })
    .map(ccg => `
    <option value="${ccg._id}" ${practice && practice.ccg === ccg._id && 'selected'}>
      ${ccg.short_name}
    </option>
  `).join('');
  return `
    <tr ${idx % 2 === 0 ? 'style="background-color: #eee"' : ''}>
      <td style="line-height:33px">${practice._id}</td>
      <td>
        <input type="text" class="form-control short_name" data-practice-id="${practice._id}" data-original-value="${practice.short_name || ''}" value="${practice.short_name || ''}">
      </td>
      <td>
        <input type="text" class="form-control long_name" data-practice-id="${practice._id}" data-original-value="${practice.long_name || ''}" value="${practice.long_name || ''}">
      </td>
      <td style="width:120px">
        <select class="form-control selectpicker ccg" data-size="6" name="ccgs" data-practice-id="${practice._id}" data-original-value="${practice.ccg}" title="Select a CCG">
          ${ccgOptions}
        </select>
      </td>
      <td style="line-height:33px">
          ${practice.first_report_date
    ? practice.first_report_date
    : ''}
      </td>
      <td>
        <button class="btn btn-warning save-practice" data-practice-id="${practice._id}" disabled>Save</button>
      </td>
    </tr>
    <tr ${idx % 2 === 0 ? 'style="background-color: #eee"' : ''}>
      <th title="User approvers in this practice - can't edit here - do it in the appserver json file" data-toggle="tooltip" data-container="body" data-placement="top" style="border-top-width: 0">Approvers:</th>
      <td colspan="5" style="border-top-width: 0">
        ${!practice.approvers || practice.approvers.length === 0
    ? 'No approvers assigned'
    : practice.approvers.map(escapeHTML).join(', ')}
      </td>
    </tr>
    `;
}).join('');

export default (practices, indicators, ccgs) => {
  // those without a ccg assigned
  const existingPracticeRows = getPracticeRows(practices.filter(x => x.ccg), indicators, ccgs);
  // those with a ccg assigned
  const newPracticeRows = getPracticeRows(practices.filter(x => !x.ccg), indicators, ccgs);
  const table = rowData => `
  <table class="table table-condensed">
    <thead>
      <tr>
        <th title="The practice code - nationally recognised system - and mongo _id field" data-toggle="tooltip" data-container="body" data-placement="top">Code</th>
        <th title="Short name for displaying when not much space" data-toggle="tooltip" data-container="body" data-placement="top">Short name</th>
        <th title="Longer name for when there is more space" data-toggle="tooltip" data-container="body" data-placement="top">Long name</th>
        <th title="The CCG to which the practice is associated" data-toggle="tooltip" data-container="body" data-placement="top">CCG</th>
        <th title="The earliest date that this practice can view historic data for" data-toggle="tooltip" data-container="body" data-placement="top">First date</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      ${rowData}
    </tbody>
  </table>
  `;
  return `
    <h2>YOU PROBABLY SHOULDN'T UPDATE ANYTHING HERE. That was before everything was put in git. To update the info check out deploy/appserver/data/Readme.md</h2>
    <h1>New practices</h1>
    <p>The following have recently appeared in the data but haven't yet been assigned to a CCG. You should do that if you want them to appear in SMASH.</p>
    ${table(newPracticeRows)}
    <hr>
    <h1>Existing practices</h1>
    <p>The following already appear in SMASH.</p>
    ${table(existingPracticeRows)}
  `;
};
