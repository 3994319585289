import userTemplate from './user';

export default (practices, ccgs, user) => `
    <div>
      <div class="btn-toolbar"><a class="btn btn-info" href="javascript:void(0)" data-href="/user/list">Back to user list</a></div>
      <form id="editUserForm" class="form-horizontal">
        ${userTemplate(ccgs, practices, user, true)}
        <div class="form-group">
          <div class="col-sm-offset-3 col-sm-9">
            <button class="btn btn-purple btn-block history" type="submit">Update <i class="fas fa-arrow-circle-right"></i></button>
          </div>
        </div>
      </form>
    </div>
  `;
