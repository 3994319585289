export default () => `
  <div class="container container-small">
    <form id="changePasswordForm">
      <legend>Change Password</legend>
      <input type="hidden" name="_csrf" />
      <div class="form-group">
        <label for="password">New Password</label>
        <input class="form-control" type="password" name="password" id="password" placeholder="New password" autofocus="autofocus" required="required" />
      </div>
      <div class="form-group">
        <label for="confirm">Confirm Password</label>
        <input class="form-control" type="password" name="confirm" id="confirm" placeholder="Confirm password" required="required" />
      </div>
      <div class="form-group">
        <button class="btn btn-primary btn-block btn-reset" type="submit">Change Password <i class="fas fa-key"></i></button>
      </div>
    </form>
  </div>
`;
