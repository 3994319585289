export default (isLoggedIn, isOnlyCcg) => `
  <div class="col-md-offset-1 col-md-5">
    <h1>SMASH</h1>
    <p>Welcome to SMASH, the medication safety dashboard!</p>
    <p>
      This system examines electronic health records and identifies patients who may be potentially at risk due to the
      medications that they are receiving. These lists of patients are then displayed to support health professionals
      when making decisions about patient care which could lead to improvements in medication safety in primary care.
    </p>
    <p>
      The system has been evaluated in Salford Clinical Commissioning Group (CCG), with general practices
      receiving pharmacist support to help address medication safety challenges highlighted by the dashboard. User
      feedback was incorporated into this second version of the dashboard which is now rolling out across Greater
      Manchester.
    </p>
    <p>Please sign in to access the dashboard.</p>
    <p>If you would like more information, please contact <a href="mailto:SMASH.Info@nhs.net">SMASH.Info@nhs.net</a>.</p>
    <p><small>This dashboard has been developed by the <a href="http://www.patientsafety.manchester.ac.uk/" target="_blank" rel="noopener">NIHR Greater Manchester Patient Safety Translational Research Centre (GM PSTRC)</a>.</small></p>
    <p><small><small>The views expressed are those of the authors and not necessarily those of the NHS, the NIHR or the Department of Health.</small></small></p>
    <div class="row">
      <div class="col-sm-4 col-xs-6"><img src="/img/logo_uom.png" alt="University of Manchester logo" style="max-width: 100%; max-height: 100%;" /></div>
      <div class="col-sm-4 col-xs-6"><img src="/img/logo_nihr.png" alt="National Institute for Health Research logo" style="max-width: 100%; max-height: 100%;" /></div>
    </div>
    </div>
    <div class="col-md-6" style="margin-top:100px;">
    <div class="well">
      ${isLoggedIn
    ? `
      <h3>Welcome!</h3>
      <p>You're already logged in</p>
      <a class="btn btn-primary" href="javascript:void(0)" data-href="${isOnlyCcg ? '/ccg' : '/practice'}">Open dashboard</a>
      `
    : `
      <h3>Sign in</h3>
      <form class="signin form-horizontal" id="loginForm">
        <div class="form-group">
          <label class="col-lg-2 col-md-2 control-label" for="username">Email</label>
          <div class="col-lg-8 col-md-10">
            <input class="form-control" id="email" required="" type="email" name="username" placeholder="Email" autofocus/>
          </div>
        </div>
        <div class="form-group">
          <label class="col-lg-2 col-md-2 control-label" for="password">Password</label>
          <div class="col-lg-8 col-md-10">
            <input class="form-control" id="password" required="" type="password" name="password" placeholder="Password" />
          </div>
        </div>
        <div class="form-group">
          <div class="col-md-offset-2 col-lg-8 col-md-10">
            <button class="btn btn-primary btn-block" type="submit">Sign in <i class="fas fa-arrow-circle-right"></i></button>
          </div>
          <div class="col-md-offset-2 col-lg-3 col-md-4">
            <a href="javascript:void(0)" data-href="/forgot"> Forgot password?</a>
          </div>
          <div class="col-lg-5 col-md-6" style="text-align:right">
            <span>New to SMASH? </span>
            <a href="javascript:void(0)" data-href="/register">Register here.</a>
          </div>
        </div>
      </form>`}      
    </div>
</div>
`;
