import userTemplate from './user';

export default (practices, ccgs) => `
    <div>
      <div class="btn-toolbar"><a class="btn btn-info" href="javascript:void(0)" data-href="/user/list">Back to user list</a></div>
      <form id="createUserForm" class="form-horizontal">
        ${userTemplate(ccgs, practices, null, true)}
        <div class="form-group">
          <div class="col-sm-offset-3 col-sm-9">
            <button class="btn btn-purple btn-block history" type="submit">Create <i class="fas fa-arrow-circle-right"></i></button>
          </div>
        </div>
      </form>
    </div>
  `;
