import state from '../state';
import api from '../api';
import {
  updateActive,
  updateBreadcrumbs,
  hideLogin,
  getDate,
  getComparisonDate,
} from './common';
import { displayCCGChart, updateChartDateRange } from '../charts';
import messageComponent from '../components/alert';
import filterBarCcgComponent from '../components/filterBarCcg';
import ccgPage from '../components/ccg';
import { displayLoader } from '../loader';

const loadingDataPriorToPageLoad = (
  ccgId,
  dateId,
  comparisonDateId,
  tabId,
  indicatorId,
  chartId,
  sort,
  dir
) =>
  Promise.all([api.datesForDisplay(), api.ccgs()]).then(([dates, ccgs]) => {
    const comparisonDates = JSON.parse(JSON.stringify(dates.slice(1)));

    if (indicatorId && indicatorId !== 'undefined') {
      state.indicatorId = indicatorId;
    } else {
      state.indicatorId = state.indicatorId || 0;
    }

    if (dateId && dateId !== 'undefined') {
      state.dateId = getDate(dates, dateId);
    } else if (!state.dateId) {
      state.dateId = dates[0]._id;
    }

    if (ccgId && ccgId !== 'undefined') {
      state.ccgId = ccgId;
    } else if (!state.ccgId) {
      state.ccgId = ccgs.length > 0 ? ccgs[0]._id : '01G';
    }

    if (comparisonDateId && comparisonDateId !== 'undefined') {
      state.comparisonDateId = getDate(dates, comparisonDateId, () =>
        getComparisonDate(comparisonDates, state.dateId)
      );
    } else if (!state.comparisonDateId) {
      state.comparisonDateId = getComparisonDate(
        comparisonDates,
        state.stateId
      );
    }

    if (tabId && tabId !== 'undefined') {
      state.ccgTabId = tabId;
    } else if (!state.ccgTabId) {
      state.ccgTabId = 1;
    }

    if (chartId && chartId !== 'undefined') {
      state.ccgChartId =
        (+state.indicatorId > 0 && +chartId < 7) ||
        (+state.indicatorId === 0 && +chartId > 6)
          ? 0
          : chartId;
    } else if (!state.ccgChartId) {
      state.ccgChartId = 0;
    }

    if (+state.indicatorId > 0) {
      if (sort && sort !== 'undefined') {
        state.ccgSingleSort = sort;
      } else if (!state.ccgSingleSort) {
        state.ccgSingleSort = 1;
      }
      if (dir && dir !== 'undefined') {
        state.ccgSingleSortDirection = dir;
      } else if (!state.ccgSingleSortDirection) {
        state.ccgSingleSortDirection = 'desc';
      }
      state.ccgSort = state.ccgSingleSort;
      state.ccgSortDirection = state.ccgSingleSortDirection;
    } else {
      if (sort && sort !== 'undefined') {
        state.ccgAllSort = sort;
      } else if (!state.ccgAllSort) {
        state.ccgAllSort = 1;
      }
      if (dir && dir !== 'undefined') {
        state.ccgAllSortDirection = dir;
      } else if (!state.ccgAllSortDirection) {
        state.ccgAllSortDirection = 'desc';
      }
      state.ccgSort = state.ccgAllSort;
      state.ccgSortDirection = state.ccgAllSortDirection;
    }

    window.Router.shift(
      `/ccg/${state.ccgId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/tab/${state.ccgTabId}/indicator/${state.indicatorId}/chart/${state.ccgChartId}/sort/${state.ccgSort}/dir/${state.ccgSortDirection}`
    );

    return Promise.all([
      api.visibleIndicators(),
      api.ccgSummary(
        state.indicatorId,
        state.dateId,
        state.comparisonDateId,
        state.ccgId
      ),
      Promise.resolve(dates),
      Promise.resolve(comparisonDates),
      Promise.resolve(ccgs),
    ]);
  });

export default (
  callback,
  dateId,
  comparisonDateId,
  tabId,
  indicatorId,
  chartId,
  sort,
  dir
) => {
  displayLoader(
    loadingDataPriorToPageLoad(
      dateId,
      comparisonDateId,
      tabId,
      indicatorId,
      chartId,
      sort,
      dir
    ),
    ([indicators, summary, dates, comparisonDates, ccgs]) => {
      hideLogin();

      delete summary.indicator;
      let selectedIndicator;
      indicators.forEach((p) => {
        if (p._id === +state.indicatorId) {
          p.isSelected = true;
          selectedIndicator = p;
          summary.indicator = selectedIndicator;
          state.indicatorName = p.short_name;
        } else {
          delete p.isSelected;
        }
      });

      ccgs.forEach((d) => {
        if (d._id === state.ccgId) {
          d.isSelected = true;
        } else {
          delete d.isSelected;
        }
      });

      dates.forEach((d) => {
        if (d._id === +state.dateId) {
          d.isSelected = true;
        } else {
          delete d.isSelected;
        }
        d.shouldDisplay = d.value.indexOf('day') < 0;
      });

      comparisonDates.forEach((c) => {
        if (c._id === +state.comparisonDateId) {
          c.isSelected = true;
        }
        c.shouldDisplay = c._id < +state.dateId;
      });

      if (!summary.comparisonDateId) {
        summary.comparisonDateId = state.comparisonDateId;
      }

      updateActive('tab-ccg');
      const crumbs = [{ label: 'All Practices' }];
      updateBreadcrumbs(crumbs);

      let finalHtml = '';

      if (summary.status === 'error') {
        // user not allowed to see this
        state.ccgId = undefined;
        finalHtml = messageComponent(summary.message, 'danger');
      } else {
        const displayComparisonDate =
          state.indicatorId && +state.indicatorId > 0;
        const filterBarHtml = filterBarCcgComponent(
          ccgs,
          indicators,
          dates,
          comparisonDates,
          displayComparisonDate
        );
        const ccgHtml = ccgPage(
          summary,
          +state.indicatorId,
          +state.ccgTabId,
          +state.ccgChartId
        );
        finalHtml = filterBarHtml + ccgHtml;
      }

      document.getElementById('page').innerHTML = finalHtml;

      $('.tooltip').tooltip('hide');
      $('[data-toggle="tooltip"]').tooltip();

      // wire up the bootstrap-select thing
      $('.selectpicker').selectpicker();

      // cause navigation on the select drop down changing
      const indicatorList = document.getElementById('indicatorList');
      if (indicatorList) {
        indicatorList.addEventListener('change', (event) => {
          if (event.target.value !== state.indicatorId) {
            if (+event.target.value === 0) {
              window.Router.navigate(
                `/ccg/${state.ccgId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/tab/${state.ccgTabId}/indicator/${event.target.value}/chart/${state.ccgChartId}/sort/${state.ccgAllSort}/dir/${state.ccgAllSortDirection}`
              );
            } else {
              window.Router.navigate(
                `/ccg/${state.ccgId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/tab/${state.ccgTabId}/indicator/${event.target.value}/chart/${state.ccgChartId}/sort/${state.ccgSingleSort}/dir/${state.ccgSingleSortDirection}`
              );
            }
          }
        });
      }
      const ccgList = document.getElementById('ccgList');
      if (ccgList) {
        ccgList.addEventListener('change', (event) => {
          if (event.target.value !== state.ccgId) {
            window.Router.navigate(
              `/ccg/${event.target.value}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/tab/${state.ccgTabId}/indicator/${state.indicatorId}/chart/${state.ccgChartId}/sort/${state.ccgSort}/dir/${state.ccgSortDirection}`
            );
          }
        });
      }
      const dateList = document.getElementById('dateList');
      if (dateList) {
        dateList.addEventListener('change', (event) => {
          if (event.target.value !== state.dateId) {
            window.Router.navigate(
              `/ccg/${state.ccgId}/date/${event.target.value}/comparedWith/${state.comparisonDateId}/tab/${state.ccgTabId}/indicator/${state.indicatorId}/chart/${state.ccgChartId}/sort/${state.ccgSort}/dir/${state.ccgSortDirection}`
            );
          }
        });
      }
      const dateCompareList = document.getElementById('dateCompareList');
      if (dateCompareList) {
        dateCompareList.addEventListener('change', (event) => {
          if (event.target.value !== state.comparisonDateId) {
            window.Router.navigate(
              `/ccg/${state.ccgId}/date/${state.dateId}/comparedWith/${event.target.value}/tab/${state.ccgTabId}/indicator/${state.indicatorId}/chart/${state.ccgChartId}/sort/${state.ccgSort}/dir/${state.ccgSortDirection}`
            );
          }
        });
      }
      const chart = document.getElementById('id_chart');
      if (chart) {
        chart.addEventListener('change', (event) => {
          if (event.target.value !== state.ccgChartId) {
            window.Router.navigate(
              `/ccg/${state.ccgId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/tab/${state.ccgTabId}/indicator/${state.indicatorId}/chart/${event.target.value}/sort/${state.ccgSort}/dir/${state.ccgSortDirection}`
            );
          }
        });
      }

      const chartSortPicker = document.getElementById('id_chart_sort');
      if (chartSortPicker) {
        chartSortPicker.addEventListener('change', (event) => {
          const newChartSort = event.target.value;

          state.currentChart = displayCCGChart(
            state.ccgChartId,
            summary,
            null,
            null,
            newChartSort
          );
          addStartEndDateListeners();
        });
      }

      const addStartEndDateListeners = () => {
        const endDateElement = document.getElementById('endDate');
        if (endDateElement) {
          endDateElement.addEventListener('change', (event) => {
            if (event.target.value !== state.practiceChartEndDate) {
              state.practiceChartEndDate = event.target.value;
              updateChartDateRange(
                state.ccgChartId,
                summary,
                state.practiceChartStartDate,
                state.practiceChartEndDate
              );
              addStartEndDateListeners();
            }
          });
        }

        const startDateElement = document.getElementById('startDate');
        if (startDateElement) {
          startDateElement.addEventListener('change', (event) => {
            if (event.target.value !== state.practiceChartStartDate) {
              state.practiceChartStartDate = event.target.value;
              updateChartDateRange(
                state.ccgChartId,
                summary,
                state.practiceChartStartDate,
                state.practiceChartEndDate
              );
              addStartEndDateListeners();
            }
          });
        }
      };

      state.tables.indicatorTable = $('#indicatorTable').DataTable({
        info: false, // we don't want showing 1 to n of n
        searching: false, // we don't want a search box
        stateSave: true, // let's remember which page/sorting etc
        order: [[state.ccgSort, state.ccgSortDirection]],
        paging: false, // always want all indicators
        scrollY: '50vh',
        scrollCollapse: true,
      });

      $('#indicatorTable').on('order.dt', () => {
        // This will show: "Ordering on column 1 (asc)", for example
        const order = state.tables.indicatorTable.order();
        if (+state.indicatorId === 0) {
          state.ccgAllSort = order[0][0];
          state.ccgAllSortDirection = order[0][1];
        } else {
          state.ccgSingleSort = order[0][0];
          state.ccgSingleSortDirection = order[0][1];
        }
        state.ccgSort = order[0][0];
        state.ccgSortDirection = order[0][1];
        window.Router.shift(
          `/ccg/${state.ccgId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/tab/${state.ccgTabId}/indicator/${state.indicatorId}/chart/${state.ccgChartId}/sort/${state.ccgSort}/dir/${state.ccgSortDirection}`,
          true
        );
      });

      // add chart
      if (+state.ccgChartId) {
        setTimeout(() => {
          state.currentChart = displayCCGChart(state.ccgChartId, summary);
          addStartEndDateListeners();
        }, 0);
      }

      const $exportButton = $('#export');

      $exportButton.on('click', () => {
        window.location =
          +state.indicatorId === 0
            ? `/api/indicator/all/summaryfordate/${state.dateId}/ccg/${state.ccgId}/sort/${state.ccgSort}/dir/${state.ccgSortDirection}/export`
            : `/api/indicator/${state.indicatorId}/summaryfordate/${state.dateId}/comparedWith/${state.comparisonDateId}/ccg/${state.ccgId}/sort/${state.ccgSort}/dir/${state.ccgSortDirection}/export`;
      });

      $('#tableTab').on('hidden.bs.tab', () => {
        $exportButton.hide(); // only want export button on table tab
      });

      $('li a[role="tab"]').on('shown.bs.tab', (e) => {
        state.ccgTabId = $(e.currentTarget).data('id');
        window.Router.shift(
          `/ccg/${state.ccgId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/tab/${state.ccgTabId}/indicator/${state.indicatorId}/chart/${state.ccgChartId}/sort/${state.ccgSort}/dir/${state.ccgSortDirection}`,
          true
        );

        if (e.currentTarget.id === 'tableTab') {
          // ensure headers display correctly on hidden tab
          state.tables.indicatorTable.columns.adjust().draw(false);
          $exportButton.show(); // only want export button on table tab
        }
      });

      if (callback) callback();
    }
  ).catch((err) => {
    if (err.message === 'Not logged in') {
      window.Router.navigate('/login');
    }
    if (callback) callback();
  });
};
