export default (ccgs, practices, user) => {
  const ccgNameLookup = ccgs.reduce((lookup, nextCCG) => {
    lookup[nextCCG._id] = nextCCG.long_name;
    return lookup;
  }, {});
  const currentlySelectedPractices = practices
    .filter(practice => user && user.practices.map(p => p).indexOf(practice._id) > -1)
    .sort((a, b) => {
      if (a.short_name > b.short_name) return 1;
      if (a.short_name < b.short_name) return -1;
      return 0;
    });
  const currentlyUnselectedPractices = practices
    .filter(practice => !user || user.practices.map(p => p).indexOf(practice._id) < 0)
    .sort((a, b) => {
      if (a.short_name > b.short_name) return 1;
      if (a.short_name < b.short_name) return -1;
      return 0;
    });
  const optGroupSelectedPractices = currentlySelectedPractices.length > 0
    ? `<optgroup label="Current practices with access">
    ${currentlySelectedPractices
    .map(practice => `
      <option value="${practice._id}" selected>
        ${practice.short_name} (${ccgNameLookup[practice.ccg]})
      </option>
    `)}</optgroup>`
    : '';
  const optGroupUnselectedByCCG = ccgs
    .sort((a, b) => {
      if (a.short_name > b.short_name) return 1;
      if (a.short_name < b.short_name) return -1;
      return 0;
    })
    .map((ccg) => {
      const ccgPractices = (!user || !user.ccgs || user.ccgs.indexOf(ccg._id) > -1)
        ? currentlyUnselectedPractices.filter(practice => practice.ccg === ccg._id)
        : [];
      return { ccgPractices, name: ccg.short_name, ccgId: ccg._id };
    })
    .filter(group => group.ccgPractices.length > 0)
    .map(group => `<optgroup label="${group.name} practices">
    ${group.ccgPractices
    .map(practice => `
      <option value="${practice._id}">
        ${practice.short_name}
      </option>
    `)}</optgroup>
    `)
    .join('');

  return optGroupSelectedPractices + optGroupUnselectedByCCG;
};
