import state from '../state';
import api from '../api';
import {
  updateActive,
  updateBreadcrumbs,
  hideLogin,
  getComparisonDate,
  getDate,
} from './common';
import { displaySinglePracticeChart, updateChartDateRange } from '../charts';
import practiceContentComponent from '../components/practiceContent';
import filterBarPracticeComponent from '../components/filterBarPractice';
import { displayLoader } from '../loader';

const loadingDataPriorToPageLoad = (
  practiceId,
  dateId,
  comparisonDateId,
  tabId,
  chartId,
  sort,
  dir
) =>
  Promise.all([api.practices(), api.datesForDisplay()]).then(
    ([practices, dates]) => {
      const comparisonDates = JSON.parse(JSON.stringify(dates.slice(1)));

      // for testing single practice uncomment this line
      // practices = [practices[0]];

      if (practices.length === 1) {
        state.practiceId = practices[0]._id;
      } else if (practiceId && practiceId !== 'undefined') {
        state.practiceId = practiceId;
      } else {
        state.practiceId = state.practiceId || 0;
      }

      if (dateId && dateId !== 'undefined') {
        state.dateId = getDate(dates, dateId);
      } else if (!state.dateId) {
        state.dateId = dates[0]._id;
      }

      if (comparisonDateId && comparisonDateId !== 'undefined') {
        state.comparisonDateId = getDate(dates, comparisonDateId, () =>
          getComparisonDate(comparisonDates, state.dateId)
        );
      } else if (!state.comparisonDateId) {
        state.comparisonDateId = getComparisonDate(
          comparisonDates,
          state.dateId
        );
      }

      if (tabId && tabId !== 'undefined') {
        state.practiceTabId = tabId;
      } else if (!state.practiceTabId) {
        state.practiceTabId = 1;
      }

      if (chartId && chartId !== 'undefined') {
        state.practiceChartId = chartId;
      } else if (!state.practiceChartId) {
        state.practiceChartId = 0;
      }

      if (sort && sort !== 'undefined') {
        state.practiceSort = sort;
      } else if (!state.practiceSort) {
        state.practiceSort = 1;
      }

      if (dir && dir !== 'undefined') {
        state.practiceSortDirection = dir;
      } else if (!state.practiceSortDirection) {
        state.practiceSortDirection = 'desc';
      }

      // changes url from /practice to /practice/:practiceId but
      // ensures the history is correct. E.g. back goes to the thing
      // before /practice was called
      window.Router.shift(
        `/practice/${state.practiceId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/tab/${state.practiceTabId}/chart/${state.practiceChartId}/sort/${state.practiceSort}/dir/${state.practiceSortDirection}`
      );

      return Promise.all([
        api.summary(state.practiceId, state.dateId, state.comparisonDateId),
        Promise.resolve(practices),
        Promise.resolve(dates),
        Promise.resolve(comparisonDates),
        api.getUser(),
        api.ccgs(),
      ]);
    }
  );

export default (
  callback,
  practiceId,
  dateId,
  comparisonDateId,
  tabId,
  chartId,
  sort,
  dir
) => {
  displayLoader(
    loadingDataPriorToPageLoad(
      practiceId,
      dateId,
      comparisonDateId,
      tabId,
      chartId,
      sort,
      dir
    ),
    ([summary, practices, dates, comparisonDates, user, ccgs]) => {
      hideLogin();

      // test for no multiple
      // summary.summaryData.multiple = 0;

      let selectedPractice;
      const practiceLookup = {};
      practices.forEach((p) => {
        if (user.ccgs && user.ccgs.length > 1) {
          const ccgNames = ccgs.filter((x) => x._id === p.ccg);
          if (ccgNames.length > 0) p.ccgName = ccgNames[0].short_name;
        }
        if (p._id === state.practiceId) {
          p.isSelected = true;
          selectedPractice = p;
          state.practiceName = p.short_name;
        } else {
          delete p.isSelected;
        }
        practiceLookup[p._id] = p;
      });

      dates.forEach((d) => {
        if (d._id === +state.dateId) {
          d.isSelected = true;
        } else {
          delete d.isSelected;
        }
        d.shouldDisplay =
          d.value.indexOf('day') < 0 || d.value.indexOf('1 ') === 0;
        const bracketIndex = d.value.indexOf('(');
        if (bracketIndex > -1 && d.value.indexOf('Latest') < 0) {
          d.valueToShow = d.value.substr(0, bracketIndex - 1);
        }
      });

      comparisonDates.forEach((c) => {
        if (c._id === +state.comparisonDateId) {
          c.isSelected = true;
        }
        c.shouldDisplay = c._id < +state.dateId;
        delete c.valueToShow;
      });

      updateActive('tab-practice');
      const crumbs = selectedPractice
        ? [{ label: selectedPractice.short_name }]
        : [{ label: 'Single Practice', path: '/practice' }];
      updateBreadcrumbs(crumbs);

      const userPractices = user.practices || [];
      const filterBarHtml = filterBarPracticeComponent(
        practices,
        dates.slice(0, -1),
        comparisonDates,
        userPractices
      );

      const practiceHtml = selectedPractice
        ? practiceContentComponent(
            summary,
            state.practiceTabId,
            state.practiceChartId,
            selectedPractice.intervention_start_date
          )
        : '';

      document.getElementById('page').innerHTML = filterBarHtml + practiceHtml;

      $('.tooltip').tooltip('hide');
      $('[data-toggle="tooltip"]').tooltip();

      // wire up the bootstrap-select thing
      $('.selectpicker').selectpicker();

      // cause navigation on the select drop down changing
      const practiceList = document.getElementById('practiceList');
      if (practiceList) {
        practiceList.addEventListener('change', (event) => {
          if (event.target.value !== state.practiceId) {
            window.Router.navigate(
              `/practice/${event.target.value}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/tab/${state.practiceTabId}/chart/${state.practiceChartId}/sort/${state.practiceSort}/dir/${state.practiceSortDirection}`
            );
          }
        });
      }
      document
        .getElementById('dateList')
        .addEventListener('change', (event) => {
          if (event.target.value !== state.dateId) {
            window.Router.navigate(
              `/practice/${state.practiceId}/date/${event.target.value}/comparedWith/${state.comparisonDateId}/tab/${state.practiceTabId}/chart/${state.practiceChartId}/sort/${state.practiceSort}/dir/${state.practiceSortDirection}`
            );
          }
        });
      document
        .getElementById('dateCompareList')
        .addEventListener('change', (event) => {
          if (event.target.value !== state.comparisonDateId) {
            window.Router.navigate(
              `/practice/${state.practiceId}/date/${state.dateId}/comparedWith/${event.target.value}/tab/${state.practiceTabId}/chart/${state.practiceChartId}/sort/${state.practiceSort}/dir/${state.practiceSortDirection}`
            );
          }
        });
      const chart = document.getElementById('id_chart');
      if (chart) {
        chart.addEventListener('change', (event) => {
          if (event.target.value !== state.practiceChartId) {
            window.Router.navigate(
              `/practice/${state.practiceId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/tab/${state.practiceTabId}/chart/${event.target.value}/sort/${state.practiceSort}/dir/${state.practiceSortDirection}`
            );
          }
        });
      }

      if (selectedPractice) {
        state.tables.practiceTable = $('#indicatorTable').DataTable({
          info: false, // we don't want showing 1 to n of n
          searching: false, // we don't want a search box
          stateSave: true, // let's remember which page/sorting etc
          order: [[state.practiceSort, state.practiceSortDirection]],
          paging: false, // always want all indicators
          scrollY: '50vh',
          scrollCollapse: true,
        });

        $('#indicatorTable').on('order.dt', () => {
          // This will show: "Ordering on column 1 (asc)", for example
          const order = state.tables.practiceTable.order();
          state.practiceSort = order[0][0];
          state.practiceSortDirection = order[0][1];
          window.Router.shift(
            `/practice/${state.practiceId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/tab/${state.practiceTabId}/chart/${state.practiceChartId}/sort/${state.practiceSort}/dir/${state.practiceSortDirection}`,
            true
          );
        });
      }

      const addStartEndDateListeners = () => {
        const endDateElement = document.getElementById('endDate');
        if (endDateElement) {
          endDateElement.addEventListener('change', (event) => {
            if (event.target.value !== state.practiceChartEndDate) {
              state.practiceChartEndDate = event.target.value;
              updateChartDateRange(
                state.practiceChartId,
                summary,
                state.practiceChartStartDate,
                state.practiceChartEndDate
              );
              addStartEndDateListeners();
            }
          });
        }

        const startDateElement = document.getElementById('startDate');
        if (startDateElement) {
          startDateElement.addEventListener('change', (event) => {
            if (event.target.value !== state.practiceChartStartDate) {
              state.practiceChartStartDate = event.target.value;
              updateChartDateRange(
                state.practiceChartId,
                summary,
                state.practiceChartStartDate,
                state.practiceChartEndDate
              );
              addStartEndDateListeners();
            }
          });
        }
      };

      // add chart
      if (+state.practiceChartId) {
        setTimeout(() => {
          summary.selectedPracticeName = selectedPractice.long_name;
          state.currentChart = displaySinglePracticeChart(
            state.practiceChartId,
            summary
          );
          addStartEndDateListeners();
        }, 0);
      }

      const $exportButton = $('#export');

      $exportButton.on('click', () => {
        window.location = `/api/practice/${state.practiceId}/summaryfordate/${state.dateId}/comparedWith/${state.comparisonDateId}/sort/${state.practiceSort}/dir/${state.practiceSortDirection}/export`;
      });

      $('#tableTab').on('hidden.bs.tab', () => {
        $exportButton.hide(); // only want export button on table tab
      });

      $('li a[role="tab"]').on('shown.bs.tab', (e) => {
        state.practiceTabId = $(e.currentTarget).data('id');
        window.Router.shift(
          `/practice/${state.practiceId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/tab/${state.practiceTabId}/chart/${state.practiceChartId}/sort/${state.practiceSort}/dir/${state.practiceSortDirection}`,
          true
        );

        if (e.currentTarget.id === 'tableTab') {
          // ensure headers display correctly on hidden tab
          state.tables.practiceTable.columns.adjust().draw(false);
          $exportButton.show(); // only want export button on table tab
        }
      });

      if (callback) callback();
    }
  ).catch((err) => {
    if (err.message === 'Not logged in') {
      window.Router.navigate('/login');
    }
    if (callback) callback();
  });
};
