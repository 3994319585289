import messageTemplate from '../alert';

export default response => `
  <div class="container container-small">
    ${response.status === 'success'
    ? `
      <h1>Email address confirmed</h1>
      <p>Thanks for confirming your email address. You can now login. However before you can view information in SMASH you need to be authorised by an administrator. They have been notified and you will receive another email when this has happened. Thanks for your patience.</p>
      <div class="btn-toolbar"><a class="btn btn-info btn-block" href="javascript:void(0)" data-href="/login">Go To Login <i class="fas fa-arrow-circle-right"></i></a></div>
    `
    : `
      <div class="message">${messageTemplate(response.message, response.status === 'error' ? 'danger' : 'info')}</div>
    `}
  </div>
`;
