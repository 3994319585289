import { getShortDate } from '../../shared/utils';

export default summary => `
<div class="pull-right">
  <button class="btn btn-primary" id="export" type="button">Export</button>
</div>
<div>
  <table class="table table-striped" id="usageTable">
    <thead>
      <tr>
        <th class="pointer" title="The GP practice" data-toggle="tooltip" data-container="body" data-placement="top">Practice</th>
        <th class="pointer" title="The date that the SMASH intervention commenced in this practice" data-toggle="tooltip" data-container="body" data-placement="top"">Start date</th>
        <th class="pointer" title="The number of SMASH users with patient level access to this practice" data-toggle="tooltip" data-container="body" data-placement="top"">Users</th>
        <th class="pointer" title="The number of views of this practice in the last week" data-toggle="tooltip" data-container="body" data-placement="top"">Views in last week</th>
        <th class="pointer" title="The number of views of this practice in the last month" data-toggle="tooltip" data-container="body" data-placement="top"">Views in last month</th>
        <th class="pointer" title="The number of views of this practice in the last 3 months" data-toggle="tooltip" data-container="body" data-placement="top">Views in last quarter</th>
      </tr>
    </thead>
    <tbody>
${summary.tableData.map(item => `
      <tr class="no-priority">
        <td class="prominentLink" title="Click to view practice" data-toggle="tooltip" data-container="body" data-placement="left" data-href="/practice/${item._id}/date/${summary.dateId}/comparedWith/undefined/tab/undefined"><a class="prominentLink" href="javascript:void(0)">${item.short_name}</a></td>
        <td>${getShortDate(item.intervention_start_date) || '-'}</td>
        <td>${item.numberOfUsers}</td>
        <td>${item.viewsLastWeek}</td>
        <td>${item.viewsLastMonth}</td>
        <td>${item.viewsLastQuarter}</td>
      </tr>
`).join('')}
    </tbody>
  </table>
</div>
`;
