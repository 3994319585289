import { updateActive, updateBreadcrumbs, hideLogin } from './common';
import helpPage from '../components/help';
import { displayLoaderSync } from '../loader';

export default (callback) => {
  displayLoaderSync(() => {
    hideLogin();

    updateActive('tab-help');
    updateBreadcrumbs([{ label: 'Contact / Help' }]);

    document.getElementById('page').innerHTML = helpPage();

    if (callback) callback();
  });
};
