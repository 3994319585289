import state from '../state';
import api from '../api';
import {
  updateActive,
  updateBreadcrumbs,
  hideLogin,
  getDate,
  getComparisonDate,
} from './common';
import messageComponent from '../components/alert';
import filterBarPcnComponent from '../components/filterBarPcn';
import pcnPage from '../components/pcn';
import { displayLoader } from '../loader';

const loadingDataPriorToPageLoad = (
  pcnId,
  dateId,
  comparisonDateId,
  indicatorId,
  sort,
  dir
) =>
  Promise.all([api.datesForDisplay(), api.pcns()]).then(([dates, pcns]) => {
    const comparisonDates = JSON.parse(JSON.stringify(dates.slice(1)));

    if (indicatorId && indicatorId !== 'undefined') {
      state.indicatorId = indicatorId;
    } else {
      state.indicatorId = state.indicatorId || 0;
    }

    if (dateId && dateId !== 'undefined') {
      state.dateId = getDate(dates, dateId);
    } else if (!state.dateId) {
      state.dateId = dates[0]._id;
    }

    if (pcnId && pcnId !== 'undefined') {
      state.pcnId = pcnId;
    } else if (!state.pcnId) {
      state.pcnId = pcns.length > 0 ? pcns[0]._id : 'XXX';
    }

    if (comparisonDateId && comparisonDateId !== 'undefined') {
      state.comparisonDateId = getDate(dates, comparisonDateId, () =>
        getComparisonDate(comparisonDates, state.dateId)
      );
    } else if (!state.comparisonDateId) {
      state.comparisonDateId = getComparisonDate(comparisonDates, state.dateId);
    }

    if (+state.indicatorId > 0) {
      if (sort && sort !== 'undefined') {
        state.pcnSingleSort = sort;
      } else if (!state.pcnSingleSort) {
        state.pcnSingleSort = 1;
      }
      if (dir && dir !== 'undefined') {
        state.pcnSingleSortDirection = dir;
      } else if (!state.pcnSingleSortDirection) {
        state.pcnSingleSortDirection = 'desc';
      }
      state.pcnSort = state.pcnSingleSort;
      state.pcnSortDirection = state.pcnSingleSortDirection;
    } else {
      if (sort && sort !== 'undefined') {
        state.pcnAllSort = sort;
      } else if (!state.pcnAllSort) {
        state.pcnAllSort = 1;
      }
      if (dir && dir !== 'undefined') {
        state.pcnAllSortDirection = dir;
      } else if (!state.pcnAllSortDirection) {
        state.pcnAllSortDirection = 'desc';
      }
      state.pcnSort = state.pcnAllSort;
      state.pcnSortDirection = state.pcnAllSortDirection;
    }

    window.Router.shift(
      `/pcn/${state.pcnId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/indicator/${state.indicatorId}/sort/${state.pcnSort}/dir/${state.pcnSortDirection}`
    );

    return Promise.all([
      api.visibleIndicators(),
      state.pcnId === 'XXX'
        ? Promise.resolve({
            status: 'error',
            message:
              'This is only available to users authorised to view PCN level data. If you think this is incorrect please contact <a href="mailto:SMASH.Info@nhs.net">SMASH.Info@nhs.net</a>.',
          })
        : api.pcnSummary(
            state.indicatorId,
            state.dateId,
            state.comparisonDateId,
            state.pcnId
          ),
      Promise.resolve(dates),
      Promise.resolve(comparisonDates),
      Promise.resolve(pcns),
    ]);
  });

export default (callback, dateId, comparisonDateId, indicatorId, sort, dir) => {
  displayLoader(
    loadingDataPriorToPageLoad(
      dateId,
      comparisonDateId,
      indicatorId,
      sort,
      dir
    ),
    ([indicators, summary, dates, comparisonDates, pcns]) => {
      hideLogin();

      delete summary.indicator;
      let selectedIndicator;
      indicators.forEach((p) => {
        if (p._id === +state.indicatorId) {
          p.isSelected = true;
          selectedIndicator = p;
          summary.indicator = selectedIndicator;
          state.indicatorName = p.short_name;
        } else {
          delete p.isSelected;
        }
      });

      pcns.forEach((d) => {
        if (d._id === state.pcnId) {
          d.isSelected = true;
        } else {
          delete d.isSelected;
        }
      });

      dates.forEach((d) => {
        if (d._id === +state.dateId) {
          d.isSelected = true;
        } else {
          delete d.isSelected;
        }
        d.shouldDisplay = d.value.indexOf('day') < 0;
      });

      comparisonDates.forEach((c) => {
        if (c._id === +state.comparisonDateId) {
          c.isSelected = true;
        }
        c.shouldDisplay = c._id < +state.dateId;
      });

      if (!summary.comparisonDateId) {
        summary.comparisonDateId = state.comparisonDateId;
      }

      updateActive('tab-pcn');
      const crumbs = [{ label: 'PCNs' }];
      updateBreadcrumbs(crumbs);

      let finalHtml = '';

      if (summary.status === 'error') {
        // user not allowed to see this
        finalHtml = messageComponent(summary.message, 'danger');
      } else {
        const displayComparisonDate =
          state.indicatorId && +state.indicatorId > 0;
        const filterBarHtml = filterBarPcnComponent(
          pcns,
          indicators,
          dates,
          comparisonDates,
          displayComparisonDate
        );
        const pcnHtml = pcnPage(summary, +state.indicatorId);
        finalHtml = filterBarHtml + pcnHtml;
      }

      document.getElementById('page').innerHTML = finalHtml;

      $('.tooltip').tooltip('hide');
      $('[data-toggle="tooltip"]').tooltip();

      // wire up the bootstrap-select thing
      $('.selectpicker').selectpicker();

      // cause navigation on the select drop down changing
      const indicatorList = document.getElementById('indicatorList');
      if (indicatorList) {
        indicatorList.addEventListener('change', (event) => {
          if (event.target.value !== state.indicatorId) {
            if (+event.target.value === 0) {
              window.Router.navigate(
                `/pcn/${state.pcnId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/indicator/${event.target.value}/sort/${state.pcnAllSort}/dir/${state.pcnAllSortDirection}`
              );
            } else {
              window.Router.navigate(
                `/pcn/${state.pcnId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/indicator/${event.target.value}/sort/${state.pcnSingleSort}/dir/${state.pcnSingleSortDirection}`
              );
            }
          }
        });
      }
      const pcnList = document.getElementById('pcnList');
      if (pcnList) {
        pcnList.addEventListener('change', (event) => {
          if (event.target.value !== state.pcnId) {
            window.Router.navigate(
              `/pcn/${event.target.value}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/indicator/${state.indicatorId}/sort/${state.pcnSort}/dir/${state.pcnSortDirection}`
            );
          }
        });
      }
      const dateList = document.getElementById('dateList');
      if (dateList) {
        dateList.addEventListener('change', (event) => {
          if (event.target.value !== state.dateId) {
            window.Router.navigate(
              `/pcn/${state.pcnId}/date/${event.target.value}/comparedWith/${state.comparisonDateId}/indicator/${state.indicatorId}/sort/${state.pcnSort}/dir/${state.pcnSortDirection}`
            );
          }
        });
      }
      const dateCompareList = document.getElementById('dateCompareList');
      if (dateCompareList) {
        dateCompareList.addEventListener('change', (event) => {
          if (event.target.value !== state.comparisonDateId) {
            window.Router.navigate(
              `/pcn/${state.pcnId}/date/${state.dateId}/comparedWith/${event.target.value}/indicator/${state.indicatorId}/sort/${state.pcnSort}/dir/${state.pcnSortDirection}`
            );
          }
        });
      }

      state.tables.indicatorTable = $('#indicatorTable').DataTable({
        info: false, // we don't want showing 1 to n of n
        searching: false, // we don't want a search box
        stateSave: true, // let's remember which page/sorting etc
        order: [[state.pcnSort, state.pcnSortDirection]],
        paging: false, // always want all indicators
        scrollY: '50vh',
        scrollCollapse: true,
      });

      $('#indicatorTable').on('order.dt', () => {
        // This will show: "Ordering on column 1 (asc)", for example
        const order = state.tables.indicatorTable.order();
        if (+state.indicatorId === 0) {
          state.pcnAllSort = order[0][0];
          state.pcnAllSortDirection = order[0][1];
        } else {
          state.pcnSingleSort = order[0][0];
          state.pcnSingleSortDirection = order[0][1];
        }
        state.pcnSort = order[0][0];
        state.pcnSortDirection = order[0][1];
        window.Router.shift(
          `/pcn/${state.pcnId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/indicator/${state.indicatorId}/sort/${state.pcnSort}/dir/${state.pcnSortDirection}`,
          true
        );
      });

      const $exportButton = $('#export');

      $exportButton.on('click', () => {
        window.location =
          +state.indicatorId === 0
            ? `/api/indicator/all/summaryfordate/${state.dateId}/pcn/${state.pcnId}/sort/${state.pcnSort}/dir/${state.pcnSortDirection}/export`
            : `/api/indicator/${state.indicatorId}/summaryfordate/${state.dateId}/comparedWith/${state.comparisonDateId}/pcn/${state.pcnId}/sort/${state.pcnSort}/dir/${state.pcnSortDirection}/export`;
      });

      if (callback) callback();
    }
  ).catch((err) => {
    if (err.message === 'Not logged in') {
      window.Router.navigate('/login');
    }
    if (callback) callback();
  });
};
