import api from '../api';
import { showAlert } from './common';
import noteComponent from '../components/note';
import modalNoteComponent from '../components/modal-note';
import modalNoteSureComponent from '../components/modal-note-sure';
import modalTrainingComplete from '../components/modal-training-status';

const modal = {

  showTrainingCompleteModal: (trainingComplete, callback) => {
    modal.trainingComplete = trainingComplete;

    const closeModal = () => {
      $('#modal')
        .modal('hide')
        .off('hidden.bs.modal');
      callback();
    };

    $('#modal')
      .html(modalTrainingComplete(trainingComplete === 'notyet'))
      .modal()
      .on('hidden.bs.modal', closeModal);

    const modalClick = (e) => {
      if (e.target.classList.contains('close-me')) {
        closeModal();
      } else if (e.target.tagName.toLowerCase() === 'button') {
        const radio = document.querySelector('input[name="trainingComplete"]:checked');
        if (radio && radio.value !== modal.trainingComplete) {
          api.setTrainingComplete(radio.value)
            .then(() => {
              closeModal();
            })
            .catch(() => {
              closeModal();
            });
        } else {
          closeModal();
        }
      }
    };

    document.getElementById('modal').onclick = modalClick;
  },

  initialize: (patientLookup) => {
    modal.patientLookup = patientLookup;

    const patientTable = document.getElementById('patientTable');
    if (patientTable) {
      patientTable.addEventListener('click', (e) => {
        if (e.target.tagName.toLowerCase() !== 'button') return;

        const { type, patientId } = e.target.dataset;

        if (type === 'delete') {
          $('#modal')
            .html(modalNoteSureComponent(patientLookup[patientId]))
            .modal();
        } else {
          $('#modal')
            .html(modalNoteComponent(modal.patientLookup[patientId]))
            .modal();
        }
      });
    }

    const modalClick = (e) => {
      if (e.target.classList.contains('close-me')) {
        $('#modal').modal('hide');
      } else if (e.target.tagName.toLowerCase() === 'button') {
        if (e.target.classList.contains('btn-danger')) { // delete note
          api.notesDelete(e.target.dataset.patientId)
            .then(() => {
              const currentPatient = modal.patientLookup[e.target.dataset.patientId];
              // update ui;
              currentPatient.indicatorNotesToDisplay = [];
              currentPatient.isNote = false;
              currentPatient.patientNote = '';
              currentPatient.indicatorNotes = [];

              $(`td.note-field[data-patient-id=${e.target.dataset.patientId}]`)
                .html(noteComponent(currentPatient));

              $('#modal').modal('hide');
            })
            .catch((err) => {
              if (err.message !== 'Not logged in') {
                showAlert('Hmm - that might not have worked', 'danger');
              } else {
                showAlert('Sorry - your session has expired. The note has not been deleted. Redirecting to login...', 'danger');
              }
              $('#modal').modal('hide');
            });
        } else { // upsert note
          const timeNow = new Date();

          // create note
          const note = {
            id: e.target.dataset.patientId,
            patientNote: $('#patientNote').val(),
            patientNoteUpdated: timeNow.toISOString(),
            patientNoteUpdatedBy: $('#usersName').text(),
            patientNoteUpdatedLocaleString: timeNow.toLocaleString('en-GB'),
            indicatorNotes: [],
          };

          const indicatorTextareas = document.querySelectorAll('textarea.indicator-note');
          for (let i = 0; i < indicatorTextareas.length; i += 1) {
            if (indicatorTextareas[i].value && indicatorTextareas[i].value.replace(/ /g, '').length > 0) {
              note.indicatorNotes.push({
                id: +indicatorTextareas[i].dataset.indicatorId,
                name: indicatorTextareas[i].dataset.indicatorName,
                note: indicatorTextareas[i].value,
              });
            }
          }

          // upsert note
          api.notesUpsert(note)
            .then(() => {
              const currentPatient = modal.patientLookup[e.target.dataset.patientId];
              currentPatient.indicatorNotes = note.indicatorNotes;
              currentPatient.indicatorNotesToDisplay = note.indicatorNotes
                .filter(nt => currentPatient.indicators.some(indicator => indicator.id === nt.id));
              currentPatient.isNote = true;
              currentPatient.indicators.forEach((indicator) => {
                indicator.note = '';
                currentPatient.indicatorNotes.forEach((nt) => {
                  if (nt.id === indicator.id) {
                    indicator.note = nt.note;
                  }
                });
              });
              currentPatient.patientNote = note.patientNote;
              currentPatient.patientNoteUpdatedBy = note.patientNoteUpdatedBy;
              currentPatient.patientNoteUpdatedLocaleString = note.patientNoteUpdatedLocaleString;

              currentPatient.isNote = !!currentPatient.patientNote
              || currentPatient.indicatorNotesToDisplay.length > 0;

              $(`td.note-field[data-patient-id=${e.target.dataset.patientId}]`).html(
                noteComponent(currentPatient)
              );

              $('#modal').modal('hide');
            })
            .catch((err) => {
              if (err.message !== 'Not logged in') {
                showAlert('Hmm - that might not have worked', 'danger');
              } else {
                showAlert('Sorry - your session has expired. The note has not been updated. Redirecting to login...', 'danger');
              }
              $('#modal').modal('hide');
            });
        }
      }
    };

    document.getElementById('modal').onclick = modalClick;
  },

};
export default modal;
