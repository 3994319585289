const indicatorList = indicators => `
<div class="form-group">
  <select class="form-control inline-select selectpicker" id="indicatorList" data-live-search="true" data-width="auto">
    <option value="0">All Indicators</option>
  ${indicators.sort((a, b) => {
    if (a.short_name > b.short_name) return 1;
    if (a.short_name === b.short_name) return 0;
    return -1;
  }).map(indicator => `
      <option value="${indicator._id}" ${indicator.isSelected ? 'selected' : ''}>${indicator.short_name}</option>`).join('')}
  </select>
</div>
`;

const dateList = (dates, id, name, title) => `
<div class="form-group left-buffer align-bottom">
  <label for="${name}" style="display:block;">${title}:</label>
  <select class="inline-select" id="${id}" name="${name}">
    ${dates
    .filter(date => date.shouldDisplay)
    .map(date => `
      <option value="${date._id}" ${date.isSelected ? 'selected' : ''}>${date.value}</option>
    `).join('')}
  </select>
</div>
`;

export default (indicators, dates) => `
<form id="filterBarAllCcgs" class="form-inline">  
  ${indicatorList(indicators)}
  ${dateList(dates, 'dateList', 'reportDate', 'Report date')}
</form>
  `;
