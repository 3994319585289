import state from '../state';
import api from '../api';
import { updateActive, updateBreadcrumbs, hideLogin } from './common';
import filterBarUsageComponent from '../components/filterBarUsage';
import messageComponent from '../components/alert';
import usagePage from '../components/usage';
import { displayLoader } from '../loader';

const loadingDataPriorToPageLoad = (ccgId, sort, dir) =>
  Promise.all([api.ccgs()]).then(([ccgs]) => {
    if (ccgId && ccgId !== 'undefined') {
      state.ccgId = ccgId;
    } else if (!state.ccgId) {
      state.ccgId = ccgs.length > 0 ? ccgs[0]._id : '01G';
    }

    if (sort && sort !== 'undefined') {
      state.usageSort = sort;
    } else if (!state.usageSort) {
      state.usageSort = 1;
    }
    if (dir && dir !== 'undefined') {
      state.usageSortDirection = dir;
    } else if (!state.usageSortDirection) {
      state.usageSortDirection = 'desc';
    }

    window.Router.shift(
      `/usage/${state.ccgId}/sort/${state.usageSort}/dir/${state.usageSortDirection}`
    );

    return Promise.all([Promise.resolve(ccgs), api.usage(state.ccgId)]);
  });

export default (callback, ccgId, sort, dir) => {
  displayLoader(
    loadingDataPriorToPageLoad(ccgId, sort, dir),
    ([ccgs, summary]) => {
      hideLogin();

      ccgs.forEach((d) => {
        if (d._id === state.ccgId) {
          d.isSelected = true;
        } else {
          delete d.isSelected;
        }
      });

      // selected tab and breadcrumbs
      updateActive('tab-usage');
      const crumbs = [{ label: 'SMASH Usage', path: '/usage' }];
      updateBreadcrumbs(crumbs);

      // render page
      let finalHtml = '';

      if (summary.status === 'error') {
        // user not allowed to see this
        finalHtml = messageComponent(summary.message, 'danger');
      } else {
        const filterBarHtml = filterBarUsageComponent(ccgs);
        const ccgHtml = usagePage(summary);
        finalHtml = filterBarHtml + ccgHtml;
      }

      document.getElementById('page').innerHTML = finalHtml;

      // wire up the bootstrap-select thing
      $('.selectpicker').selectpicker();

      const ccgList = document.getElementById('ccgList');
      if (ccgList) {
        ccgList.addEventListener('change', (event) => {
          if (event.target.value !== state.ccgId) {
            window.Router.navigate(
              `/usage/${event.target.value}/sort/${state.usageSort}/dir/${state.usageSortDirection}`
            );
          }
        });
      }

      const $exportButton = $('#export');

      $exportButton.on('click', () => {
        window.location = `/api/usage/${state.ccgId}/sort/${state.usageSort}/dir/${state.usageSortDirection}/export`;
      });

      state.tables.usageTable = $('#usageTable').DataTable({
        info: false, // we don't want showing 1 to n of n
        searching: false, // we don't want a search box
        stateSave: true, // let's remember which page/sorting etc
        order: [[state.usageSort, state.usageSortDirection]],
        paging: false, // always want all indicators
        columnDefs: [{ type: 'date-with-blanks', targets: 1 }],
        scrollY: '50vh',
        scrollCollapse: true,
      });

      $('#usageTable').on('order.dt', () => {
        // This will show: "Ordering on column 1 (asc)", for example
        const order = state.tables.usageTable.order();
        state.usageSort = order[0][0];
        state.usageSortDirection = order[0][1];
        window.Router.shift(
          `/usage/${state.ccgId}/sort/${state.usageSort}/dir/${state.usageSortDirection}`,
          true
        );
      });

      if (callback) callback();
    }
  ).catch((err) => {
    if (err.message === 'Not logged in') {
      window.Router.navigate('/login');
    }
    if (callback) callback();
  });
};
