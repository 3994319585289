import Router from 'rw-router';
import routes from './routes';
import listenForEvents from './events';
import state from './state';
// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-select';
// import 'bootstrap-select/dist/css/bootstrap-select.min.css';
// import './styles.scss';
// import 'datatables.net-bs';
// import 'datatables.net-buttons-bs';
// import 'datatables.net-buttons/js/buttons.colVis';
// import 'datatables.net-buttons/js/buttons.html5'  ;
// import('datatables.net-bs/css/dataTables.bootstrap.css'),

// allows the table to be sorted based on number of indicators breached
$.fn.dataTable.ext.type.order['no-indicators-pre'] = (data) =>
  data.split('\n').length;
// allows the table to be sorted based on a date string
$.fn.dataTable.ext.type.order['any-date-pre'] = (data) =>
  new Date(data.split('</div>')[0].replace('<div>', '')).getTime();
// sort by date, but if no date then always comes last
$.fn.dataTable.ext.type.order['date-with-blanks-asc'] = (a, b) => {
  if (a === b) return 0;
  if (b === '-') return -1;
  if (a === '-') return 1;
  return new Date(a) - new Date(b);
};
$.fn.dataTable.ext.type.order['date-with-blanks-desc'] = (a, b) => {
  if (a === b) return 0;
  if (b === '-') return -1;
  if (a === '-') return 1;
  return new Date(b) - new Date(a);
};

const fade = (el, targetOpacity) => {
  // if currently hidden and target opacity is non-zero
  if (el.style.display === 'none') {
    el.style.opacity = 0;
    el.style.display = '';
  } else if (el.style.opacity === '') {
    el.style.opacity = 1;
  }

  let last = +new Date();
  const isDecreasingOpacity = el.style.opacity > targetOpacity;
  const tick = () => {
    const diff = (new Date() - last) / 400;
    el.style.opacity = isDecreasingOpacity
      ? Math.max(targetOpacity, +el.style.opacity - diff)
      : Math.min(targetOpacity, +el.style.opacity + diff);
    last = +new Date();

    if (Math.abs(+el.style.opacity - targetOpacity) > 0.01) {
      requestAnimationFrame(tick);
    } else if (targetOpacity === 1) {
      el.style.opacity = 1;
    } else if (targetOpacity === 0) {
      el.style.display = 'none';
      el.style.opacity = 1;
    }
  };

  tick();
};

const fadeIn = (el) => fade(el, 1);
const fadeOut = (el) => fade(el, 0);

const hideSplashScreen = () => {
  // Prevent showSplashScreen if get here within 500ms
  clearTimeout(window.SMASH_SPLASH_ID);
  fadeOut(document.getElementById('welcome'));
  fadeIn(document.getElementById('container'));
};

window.Router = Router;

// The main entry point for the js code

// Initialize the router
Router.config(() => {
  // called everytime the user clicks a link
  state.initialPath = '';
});

// This script is only called on a server load so we might
// need to do some client routing

// First get the path
state.initialPath = window.location.pathname.replace(/^\/+|\/+$/g, '');

// Now look for a match in the routes
let controller = false;
let parameters = [null];
let defaultController;
routes.forEach((route) => {
  // Add all the routes to the Router
  Router.add(route.regex, route.controller);

  if (controller) return;
  if (route.isDefault) defaultController = route.controller;
  const result = state.initialPath.match(route.regex);
  if (result) {
    ({ controller } = route);
    parameters = result;
  }
});

// Start the router listening
Router.listen();

if (!controller) {
  // Shouldn't actually get here - but if we do
  // just load the homepage
  controller = defaultController;
}
const initialize = () => {
  parameters[0] = () => {
    // unhide everything on initial page load after content is generated
    hideSplashScreen();
    // Wire up window click/hover listener
    listenForEvents();
    // ensure headers display correctly on hidden tab
    state.redrawTables();
  };
  controller.apply({}, parameters);
};

if (
  document.attachEvent
    ? document.readyState === 'complete'
    : document.readyState !== 'loading'
) {
  // dom loaded so safe to call controller
  initialize();
} else {
  // dom not loaded so let's wait before firing the controller
  document.addEventListener('DOMContentLoaded', initialize);
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js');
}
