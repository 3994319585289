import api from '../api';
import {
  updateActive,
  updateBreadcrumbs,
  showAlert,
  hideLogin,
} from './common';
import userListComponent from '../components/admin/userList';
import createUserComponent from '../components/admin/createUser';
import editUserComponent from '../components/admin/editUser';
import practiceListComponent from '../components/admin/practiceList';
import deleteUserModalComponent from '../components/admin/deleteUserModal';
import { displayLoader } from '../loader';

export function createUser(callback) {
  displayLoader(
    Promise.all([api.allPractices(), api.allCcgs()]),
    ([practices, ccgs]) => {
      hideLogin();

      updateActive('link-user-list');
      const crumbs = [{ label: 'Users' }];
      updateBreadcrumbs(crumbs);

      const userHtml = createUserComponent(practices, ccgs);

      document.getElementById('page').innerHTML = userHtml;

      $('.tooltip').tooltip('hide');
      $('[data-toggle="tooltip"]').tooltip();

      // wire up the bootstrap-select thing
      $('.selectpicker').selectpicker();

      $('[name="ccgs"]').on('change', () => {
        const ccgIds = Array.prototype.slice
          .call(document.querySelectorAll('[name="ccgs"] option:checked'), 0)
          .map((v) => v.value);
        const practiceIds = Array.prototype.slice
          .call(
            document.querySelectorAll('[name="practices"] option:checked'),
            0
          )
          .map((v) => v.value);
        const practiceSelect = document.querySelector('[name="practices"]');
        practiceSelect.innerHTML = practiceListComponent(ccgs, practices, {
          ccgs: ccgIds,
          practices: practiceIds,
        });

        // wire up the bootstrap-select thing
        $('.selectpicker[name="practices"]').selectpicker('refresh');
      });

      // form post login
      $('#createUserForm').submit((e) => {
        e.preventDefault();
        const formdata = $('#createUserForm').serialize();
        api
          .userCreate(formdata)
          .then((resp) => {
            if (resp.status === 'success') {
              // redirect to user list
              window.Router.navigate('/user/list');
              setTimeout(() => {
                showAlert(resp.message, 'success');
              }, 150);
            } else {
              showAlert(resp.message, 'danger');
            }
          })
          .catch(() => {
            showAlert('Might not have worked', 'danger');
          });
      });

      if (callback) callback();
    }
  ).catch((err) => {
    if (err.message === 'Not logged in') {
      window.Router.navigate('/login');
    }
    if (callback) callback();
  });
}

export function editUser(callback, email) {
  displayLoader(
    Promise.all([api.user(email), api.allPractices(), api.allCcgs()]),
    ([user, practices, ccgs]) => {
      hideLogin();

      updateActive('link-user-list');
      const crumbs = [{ label: 'Users' }];
      updateBreadcrumbs(crumbs);

      const userHtml = editUserComponent(practices, ccgs, user);

      document.getElementById('page').innerHTML = userHtml;

      $('.tooltip').tooltip('hide');
      $('[data-toggle="tooltip"]').tooltip();

      // wire up the bootstrap-select thing
      $('.selectpicker').selectpicker();

      $('[name="ccgs"]').on('change', () => {
        const ccgIds = Array.prototype.slice
          .call(document.querySelectorAll('[name="ccgs"] option:checked'), 0)
          .map((v) => v.value);
        const practiceIds = Array.prototype.slice
          .call(
            document.querySelectorAll('[name="practices"] option:checked'),
            0
          )
          .map((v) => v.value);
        const practiceSelect = document.querySelector('[name="practices"]');
        practiceSelect.innerHTML = practiceListComponent(ccgs, practices, {
          ccgs: ccgIds,
          practices: practiceIds,
        });
        // wire up the bootstrap-select thing
        $('.selectpicker[name="practices"]').selectpicker('refresh');
      });

      // form post login
      $('#editUserForm').submit((e) => {
        e.preventDefault();
        const formdata = $('#editUserForm').serialize();
        api
          .userEdit(email, formdata)
          .then((resp) => {
            if (resp.status === 'success') {
              // redirect to user list
              window.Router.navigate('/user/list');
              setTimeout(() => {
                showAlert(resp.message, 'success');
              }, 150);
            } else {
              showAlert(resp.message, 'danger');
            }
          })
          .catch(() => {
            showAlert('Might not have worked', 'danger');
          });
      });

      $('#ccgsGroup').show();
      $('#practicesGroup').show();

      if (callback) callback();
    }
  ).catch((err) => {
    if (err.message === 'Not logged in') {
      window.Router.navigate('/login');
    }
    if (callback) callback();
  });
}

export function listUsers(callback) {
  displayLoader(
    Promise.all([api.users(), api.practiceLookup(), api.ccgLookup()]),
    ([users, practiceLookup, ccgLookup]) => {
      hideLogin();

      updateActive('link-user-list');
      const crumbs = [{ label: 'Users' }];
      updateBreadcrumbs(crumbs);

      // add practice names to user objects and pull out those who need authorising
      const ccgUsersToAuthorise = [];
      const practiceUsersToAuthorise = [];
      const GMUsersToAuthorise = [];
      users.forEach((user) => {
        if (user.practices && user.practices.length) {
          user.practiceNames = user.practices.map((practice) =>
            practiceLookup[practice.id || practice]
              ? practiceLookup[practice.id || practice].short_name
              : 'unknown'
          );
        }
        user.ccgNames = user.ccgs.map(
          (ccg) => ccgLookup[ccg.id || ccg].short_name
        );
        if (user.ccgs && user.ccgs.length && user.roles.indexOf('ccg') > -1) {
          user.ccgs
            .filter((x) => x.needsApproval)
            .forEach((x) => {
              if (x.needsApproval) {
                ccgUsersToAuthorise.push({
                  ...user,
                  ccgId: x.id,
                  ccgName: ccgLookup[x.id].short_name,
                });
              }
            });
        }
        if (user.practices && user.practices.length) {
          user.practices
            .filter((x) => x.needsApproval)
            .forEach((x) => {
              if (x.needsApproval) {
                practiceUsersToAuthorise.push({
                  ...user,
                  practiceId: x.id,
                  practiceName: practiceLookup[x.id].short_name,
                });
              }
            });
        }
        if (
          user.roles.indexOf('pan-ccg') > -1 &&
          user.roles.indexOf('authenticated') < 0
        ) {
          GMUsersToAuthorise.push(user);
        }
      });

      const usersAlreadyAuthorised = users.filter(
        (user) => user.roles.indexOf('authenticated') > -1
      );

      // split out those

      const userHtml = userListComponent(
        usersAlreadyAuthorised,
        ccgUsersToAuthorise,
        practiceUsersToAuthorise,
        GMUsersToAuthorise
      );

      document.getElementById('page').innerHTML = userHtml;

      $('.tooltip').tooltip('hide');
      $('[data-toggle="tooltip"]').tooltip();

      $('.user-decision').on('click', (e) => {
        const {
          email: userEmail,
          ccgId,
          practiceId,
          authorise,
          placeName,
        } = e.currentTarget.dataset;

        const apiPromise = authorise ? api.userAuthorise : api.userReject;

        const row = ccgId
          ? $(
              `.user-decision[data-email="${userEmail}"][data-ccg-id="${ccgId}"]`
            )
              .parent()
              .parent()
          : $(
              `.user-decision[data-email="${userEmail}"][data-practice-id="${practiceId}"]`
            )
              .parent()
              .parent();

        row.addClass('removing');
        apiPromise(userEmail, ccgId, practiceId, placeName)
          .then((resp) => {
            if (resp.status === 'success') {
              // remove row
              row.hide();
              showAlert(resp.message, 'success');
            } else {
              showAlert(resp.message, 'danger');
            }
          })
          .catch(() => {
            showAlert('Might not have worked', 'danger');
            row.removeClass('removing');
          });
      });

      $('.delete-user').on('click', (e) => {
        // launch modal
        $('#modal')
          .html(deleteUserModalComponent(e.currentTarget.dataset.email))
          .modal();

        const modalClick = (modalEvent) => {
          if (modalEvent.target.classList.contains('close-me')) {
            $('#modal').modal('hide');
          } else {
            // delete user
            api
              .userDelete(modalEvent.target.dataset.patientEmail)
              .then((resp) => {
                $('#modal').modal('hide');
                if (resp.status === 'success') {
                  // remove row
                  const row = $(
                    `.delete-user[data-email="${modalEvent.target.dataset.patientEmail}"]`
                  )
                    .parent()
                    .parent();
                  row.hide();
                  showAlert(resp.message, 'success');
                } else {
                  showAlert(resp.message, 'danger');
                }
              })
              .catch(() => {
                showAlert('Might not have worked', 'danger');
              });
          }
        };

        const modalElement = document.getElementById('modal');
        if (!modalElement.classList.contains('click-handler-added')) {
          modalElement.addEventListener('click', modalClick);
          modalElement.classList.add('click-handler-added');
        }
      });

      if (callback) callback();
    }
  ).catch((err) => {
    if (err.message === 'Not logged in') {
      window.Router.navigate('/login');
    }
    if (callback) callback();
  });
}
