const trendChartData = (
  trendData,
  startDate = new Date(2000, 1, 1),
  endDate = new Date(),
  hide
) => {
  const rtn = { x: 'x' };
  if (hide) rtn.hide = true;
  const from = new Date(startDate);
  from.setHours(from.getHours() - 6);
  const to = new Date(endDate);
  to.setHours(to.getHours() + 6);
  rtn.columns = [];
  trendData.columns[0].forEach((v, i) => {
    if (i === 0) {
      trendData.columns.forEach((vv) => {
        rtn.columns.push([vv[0]]);
      });
    } else if (new Date(v) >= from && new Date(v) <= to) {
      trendData.columns.forEach((vv, ii) => {
        rtn.columns[ii].push(trendData.columns[ii][i]);
      });
    }
  });
  rtn.columns[0] = rtn.columns[0].map((v) => {
    let date = v;
    if (v.toISOString) {
      date = v.toISOString();
    }
    return date.substr(0, 10);
  });

  if (trendData.types) rtn.types = trendData.types;
  if (trendData.groups) rtn.groups = trendData.groups;
  rtn.order = null;
  return rtn;
};

const allPracticeTrendObj = (bindto, data, yLabel) => ({
  bindto,
  size: { height: 400 },
  data,
  point: {
    r: 1,
    focus: { expand: { r: 5 } },
  },
  // legend: { reverse: true },
  axis: {
    x: {
      type: 'timeseries',
      tick: {
        format: '%Y-%m-%d',
        rotate: 60,
        multiline: false,
      },
      height: 60,
    },
    y: {
      label: {
        text: yLabel,
        position: 'outer-middle',
      },
    },
  },
  grid: {
    x: { show: true },
    y: { show: true },
  },
  padding: { right: 20 },
  transition: { duration: 0 },
});

export { trendChartData, allPracticeTrendObj };
