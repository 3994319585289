export default (placeName, isPractice, id) => `
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close"><span aria-hidden="true" class="close-me">&times;</span></button>
        <h4 class="modal-title" id="myModalLabel">Revoke access</h4>
      </div>
      <div class="modal-body">
        <h4>Are you sure you want to remove your access to ${placeName}?</h4>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-is-practice="${isPractice}" data-id="${id}">Revoke access</button>
        <button type="button" class="btn btn-default close-me">Cancel</button>
      </div>
    </div>
  </div>
`;
