export default (indicators, selectedIndicator) => `
<div class="row">
  <div class="col-xs-12 col-md-8">
    <select class="form-control selectpicker" id="indicatorList" data-live-search="true" data-style="btn-lg btn-default" title="Select an indicator to view the evidence summary">
  ${indicators.sort((a, b) => {
    if (a.short_name > b.short_name) return 1;
    if (a.short_name === b.short_name) return 0;
    return -1;
  }).map(indicator => `
        <option value="${indicator._id}" ${indicator.isSelected ? 'selected' : ''}>${indicator.short_name}</option>`).join('')}  
    </select>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-md-8">
    <hr/>
    <div id="info" style="height:70vh;overflow-y:auto;">
    ${selectedIndicator
    ? selectedIndicator.info
    : 'The evidence summaries for each indicator are displayed here - please select an indicator from the drop down list above.'}
    </div>
  </div>
</div>
  `;
