import state from '../state';
import api from '../api';
import { updateActive, updateBreadcrumbs, hideLogin } from './common';
import modal from './modalController';
import messageComponent from '../components/alert';
import filterBarJustDateComponent from '../components/filterBarJustDate';
import multiplePatientComponent from '../components/multiplePatient';
import { displayLoader } from '../loader';

export default (callback, practiceId, dateId) => {
  displayLoader(
    Promise.all([
      api.practices(),
      api.datesForDisplay(),
      api.multiple(practiceId, dateId),
    ]),
    ([practices, dates, summary]) => {
      hideLogin();

      state.practiceId = practiceId;
      state.dateId = dateId;

      practices.forEach((p) => {
        if (p._id === state.practiceId) {
          p.isSelected = true;
          state.practiceName = p.short_name;
        } else {
          delete p.isSelected;
        }
      });

      dates.forEach((d) => {
        if (d._id === +state.dateId) {
          d.isSelected = true;
        } else {
          delete d.isSelected;
        }
        d.shouldDisplay = d.value.indexOf('day') < 0;
      });

      const patientLookup = {};
      if (summary.patients) {
        summary.patients.forEach((p) => {
          if (!p.indicatorNotes) p.indicatorNotes = [];
          p.indicatorNotesToDisplay = p.indicatorNotes.filter((note) =>
            p.indicators.some((indicator) => indicator.id === note.id)
          );
          p.isNote = !!p.patientNote || p.indicatorNotesToDisplay.length > 0;
          p.indicators.forEach((indicator) => {
            indicator.note = '';
            p.indicatorNotes.forEach((note) => {
              if (note.id === indicator.id) {
                indicator.note = note.note;
              }
            });
          });
          p.patientNote = p.patientNote || '';
          patientLookup[p.id] = p;
        });
      }

      updateActive('tab-practice');
      const crumbs = [
        {
          label: state.practiceName,
          path: `/practice/${state.practiceId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/tab/${state.practiceTabId}`,
        },
        { label: 'Patients affected by more than one indicator' },
      ];
      updateBreadcrumbs(crumbs);

      const filterBarHtml = filterBarJustDateComponent(dates);

      const practiceHtml =
        summary.status === 'error'
          ? messageComponent(summary.message, 'danger')
          : multiplePatientComponent(summary);

      document.getElementById('page').innerHTML = filterBarHtml + practiceHtml;

      $('.tooltip').tooltip('hide');
      $('[data-toggle="tooltip"]').tooltip();

      // cause navigation on the select drop down changing
      document
        .getElementById('dateList')
        .addEventListener('change', (event) => {
          if (event.target.value !== state.dateId) {
            window.Router.navigate(
              `/practice/${state.practiceId}/date/${event.target.value}/multiple`
            );
          }
        });

      state.tables.multiplePatientTable = $('#patientTable').DataTable({
        info: false, // we don't want showing 1 to n of n
        searching: false, // we don't want a search box
        stateSave: true, // let's remember which page/sorting etc
        paging: false, // always want all indicators
        order: [[1, 'desc']],
        columnDefs: [
          { type: 'no-indicators', targets: 1 },
          { type: 'any-date', targets: 2 },
        ],
        scrollY: '50vh',
        scrollCollapse: true,
      });

      modal.initialize(patientLookup);
      if (callback) callback();
    }
  ).catch((err) => {
    if (err.message === 'Not logged in') {
      window.Router.navigate('/login');
    }
    if (callback) callback();
  });
};
