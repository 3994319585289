import userTemplate from '../admin/user';

export default (isLoggedIn, ccgs, practices) => `
  <div class="container container-small">
    <div class="btn-toolbar" style="float:left;margin-right:15px">
      <a class="btn btn-info" href="javascript:void(0)" data-href="/login">
        <i class="fas fa-arrow-circle-left"></i> Back to SMASH Login
      </a>
    </div>
    <h1 style="margin-top:0">Register</h1>
    ${isLoggedIn
    ? `
      <p>
      You appear to already be logged in so you shouldn't need register. If you want to register someone else,
      or register a different email address, please sign out via the drop down in the top right hand corner of the screen.
      </p>
      `
    : `
      <form id="registerForm" class="form-horizontal">
        ${userTemplate(ccgs, practices, null, false)}
        <div class="form-group">
          <div class="col-sm-offset-3 col-sm-9">
            <button class="btn btn-success btn-block history" type="submit">Register <i class="fas fa-arrow-circle-right"></i></button>
          </div>
        </div>
      </form>`}
  </div>
`;
