import practiceSummary from './practiceSummary';
import tableCellLink from './tableCellLink';

const tableRow = (summary, data) => `
  <tr class="no-priority">
    <td title="${data.long_name}" data-toggle="tooltip" data-container="body" data-placement="top">${data.short_name}</td>
    ${tableCellLink(data.num, `/practice/${summary.practiceId}/date/${summary.dateId}/comparedWith/${summary.comparisonDateId}/indicator/${data.id}/affected`)}
    <td>${data.denom}</td>
    <td>${data.avg}</td>
    <td>${data.ccg}</td>
    ${tableCellLink(data.resolved, `/practice/${summary.practiceId}/date/${summary.dateId}/comparedWith/${summary.comparisonDateId}/indicator/${data.id}/resolved`)}
    ${tableCellLink(data.existing, `/practice/${summary.practiceId}/date/${summary.dateId}/comparedWith/${summary.comparisonDateId}/indicator/${data.id}/existing`)}
    ${tableCellLink(data.new, `/practice/${summary.practiceId}/date/${summary.dateId}/comparedWith/${summary.comparisonDateId}/indicator/${data.id}/new`)}
    <td>${data.trendValue}</td>
  </tr>
`;

export default (summary, activeTab, activeChart, interventionStartDate) => `
<div class="pull-right">
<button class="btn btn-primary" id="export" type="button" ${+activeTab === 2 ? '' : 'style="display:none;"'}>Export</button>
</div>
<ul class="nav nav-tabs" role="tablist">
<li ${+activeTab === 1 ? 'class="active"' : ''} role="presentation"><a href="#summary" aria-controls="summary" role="tab" data-toggle="tab" data-id="1">Practice summary</a></li>
<li ${+activeTab === 2 ? 'class="active"' : ''} role="presentation"><a id="tableTab" href="#table" aria-controls="table" role="tab" data-toggle="tab" data-id="2">Table</a></li>
<li ${+activeTab === 3 ? 'class="active"' : ''} role="presentation"><a href="#charts" aria-controls="charts" role="tab" data-toggle="tab" data-id="3">Charts</a></li>
</ul>
<div class="tab-content">
<div class="tab-pane tab-borders fade${+activeTab === 1 ? ' active in' : ''}" id="summary" role="tabpanel">
  ${practiceSummary(summary, interventionStartDate)}
</div>
<div class="tab-pane tab-borders fade${+activeTab === 2 ? ' active in' : ''}" id="table" role="tabpanel">
  <div class="clearfix"></div>
  <div class="top-buffer">
    <table class="table table-striped" id="indicatorTable">
      <thead>
        <tr>
          <th class="pointer" title="The patient safety indicator name" data-toggle="tooltip" data-container="body" data-placement="top">Indicator</th>
          <th class="pointer" title="The number of patients affected by this indicator for this practice" data-toggle="tooltip" data-container="body" data-placement="top">Affected patients</th>
          <th class="pointer" title="The population of patients from this practice that this indicator could affect" data-toggle="tooltip" data-container="body" data-placement="top">Eligible patients</th>
          <th class="pointer" title="Proportion of the population in focus that are affected by this indicator for this practice" data-toggle="tooltip" data-container="body" data-placement="top">% of eligible patients affected</th>
          <th class="pointer" title="Proportion of the population in focus that are affected by this indicator across all practices in the local CCG" data-toggle="tooltip" data-container="body" data-placement="top">CCG Avg (%)</th>
          <th class="pointer" title="Patients no longer affected by this indicator since the last comparison date" data-toggle="tooltip" data-container="body" data-placement="top">Successful intervention</th>
          <th class="pointer" title="Patients who remain affected by this indicator since the last comparison date" data-toggle="tooltip" data-container="body" data-placement="top">Action pending</th>
          <th class="pointer" title="Patients affected by this indicator who weren't affected on the last comparison date" data-toggle="tooltip" data-container="body" data-placement="top">New cases</th>
          <th class="pointer" title="Difference in the number of affected patients between last comparison date and current" data-toggle="tooltip" data-container="body" data-placement="top">Trend</th>
        </tr>
      </thead>
      <tbody>
        ${summary.tableData.map(data => tableRow(summary, data)).join('')}
      </tbody>
    </table>
  </div>
</div>
<div class="tab-pane tab-borders fade${+activeTab === 3 ? ' active in' : ''}" id="charts" role="tabpanel">
  <div style="padding:10px;">
    <select class="selectpicker" id="id_chart" data-width="auto" title="Please select a chart to view...">
      <option value="1"${+activeChart === 1 ? ' selected' : ''}>Proportion of eligible patients affected</option>
      <option value="2"${+activeChart === 2 ? ' selected' : ''}>Number of affected patients over time</option>
      <option value="3"${+activeChart === 3 ? ' selected' : ''}>Proportion of eligible patients affected over time</option>
    </select>
  </div>
  <div id="chartPanel"></div>
</div>
</div>
  `;
