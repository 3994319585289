import tableCellLink from './tableCellLink';

export default (summary, interventionStartDate) => `
<table class="table table-narrow right-border">
  <thead>
    <tr>
      <th title="Number of patients in the practice" data-toggle="tooltip" data-container="body" data-placement="left" style="border-bottom-width:1px;">Practice size</th>
      <td>${summary.summaryData.practiceSize}</td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th title="Number of patients flagged up by at least one of the indicators" data-toggle="tooltip" data-container="body" data-placement="left">Patients affected by at least one indicator</th>
      <td>${summary.summaryData.totalPatients}</td>
    </tr>
    <tr>
      <th title="Number of patients flagged up by 2 or more indicators" data-toggle="tooltip" data-container="body" data-placement="left">Patients affected by more than one indicator</th>
      ${tableCellLink(summary.summaryData.multiple, `/practice/${summary.practiceId}/date/${summary.dateId}/multiple`)}
    </tr>
    <tr>
      <th title="Number of patients flagged up by an indicator who also live in a care home" data-toggle="tooltip" data-container="body" data-placement="left">Care home patients affected by an indicator</th>
      ${tableCellLink(summary.summaryData.careHome, `/practice/${summary.practiceId}/date/${summary.dateId}/carehome`)}
    </tr>
    <tr>
      <th title="Out of all patients eligible for each indicator, this is the average proportion of affected patients" data-toggle="tooltip" data-container="body" data-placement="left">All indicator average</th>
      <td>${summary.summaryData.allIndicatorAverage}</td>
    </tr>
    ${interventionStartDate
    ? `<tr>
        <th title="The date the SMASH intervention was introduced to the practice" data-toggle="tooltip" data-container="body" data-placement="left">SMASH start date</th>
        <td>${interventionStartDate.substr(0, 10)}</td>
      </tr>`
    : ''}      
  </tbody>
</table>`;
