// import 'c3/c3.css';
import { dateSelect } from './components/datePicker';
import chartContainerCmpt from './components/chartContainer';
import { allPracticeTrendObj, trendChartData } from './charts/chartHelper';
import state from './state';

// dynamically import the c3 library as it's quite large
const generate = (chartOptions) => {
  import(/* webpackChunkName: "c3" */ /* webpackPrefetch: true */ 'c3').then(
    ({ default: c3 }) => {
      c3.generate(chartOptions);
    }
  );
};

const getPatientsMultipleChartData = (data, chartSort) => {
  const rtn = {};

  let json = [];
  let item;

  for (let i = 0; i < data.length; i += 1) {
    item = data[i];
    json.push({
      id: item.id,
      x: item.short_name,
      single: item.num,
      multiple: item.patientsMultiple,
    });
  }

  switch (Number(chartSort)) {
    case 2:
      json = json.sort((a, b) => b.multiple - a.multiple);
      break;
    case 1:
    default:
      json = json.sort(
        (a, b) => b.single + b.multiple - (a.single + a.multiple)
      );
      break;
  }

  rtn.json = json;
  rtn.keys = { x: 'x', value: ['single', 'multiple'] };

  // rtn.onclick = $scope.patientsMultipleChartSelectHandler;
  rtn.type = 'bar';
  rtn.groups = [['single', 'multiple']];
  rtn.names = {
    single: '1 indicator',
    multiple: '2+ indicators',
  };
  rtn.colors = { single: 'red', multiple: 'blue' };

  return rtn;
};

const singlePracticeComparison = (
  bindto,
  data,
  practiceId,
  date,
  comparisonDate
) =>
  generate({
    bindto,
    size: { height: 400 },
    data: {
      ...data,
      onclick: (d) => {
        navigateToIndicator(d, data.json, practiceId, date, comparisonDate);
      },
    },
    axis: {
      x: {
        type: 'category',
        tick: {
          rotate: 60,
          multiline: false,
        },
      },
      y: {
        label: {
          text: '% of eligible patients affected',
          position: 'outer-middle',
        },
      },
    },
    point: { r: 6 },
    grid: { y: { show: true } },
  });

const allPracticeTrend = (bindto, data, yLabel) =>
  generate(allPracticeTrendObj(bindto, data, yLabel));

const singlePracticeTrend = (bindto, data, yLabel) =>
  generate({
    bindto,
    size: { height: 400 },
    data,
    axis: {
      x: {
        type: 'timeseries',
        tick: {
          format: '%Y-%m-%d',
          rotate: 60,
          multiline: false,
        },
        height: 60,
      },
      y: {
        label: {
          text: yLabel,
          position: 'outer-middle',
        },
      },
    },
    grid: {
      x: { show: true },
      y: { show: true },
    },
    padding: { right: 20 },
  });

const ccgAllIndicatorsAtRisk = (bindto, data, date, comparedWith) =>
  generate({
    bindto,
    size: { height: 400 },
    data: {
      ...data,
      onclick: (d) => {
        navigateToPractice(d, data.json, date, comparedWith);
      },
    },
    axis: {
      x: {
        type: 'category',
        tick: {
          rotate: 60,
          multiline: false,
        },
      },
      y: {
        label: {
          text: '% of eligible patients affected',
          position: 'outer-middle',
        },
      },
    },
    grid: { y: { show: true } },
  });

const ccgAllIndicatorsMultiple = (bindto, data, date, comparedWith) =>
  generate({
    bindto,
    size: { height: 400 },
    data: {
      ...data,
      onclick: (d) => {
        navigateToPractice(d, data.json, date, comparedWith);
      },
    },
    axis: {
      x: {
        type: 'category',
        tick: {
          rotate: 60,
          multiline: false,
        },
      },
      y: {
        label: {
          text: 'Number of patients affected',
          position: 'outer-middle',
        },
      },
    },
    grid: { y: { show: true } },
  });

const ccgSingleIndicatorAtRisk = (bindto, data, date, comparedWith) =>
  generate({
    bindto,
    size: { height: 400 },
    data: {
      ...data,
      onclick: (d) => {
        navigateToPractice(d, data.json, date, comparedWith);
      },
    },
    axis: {
      x: {
        type: 'category',
        tick: {
          rotate: 60,
          multiline: false,
        },
      },
      y: {
        label: {
          text: '% of eligible patients affected',
          position: 'outer-middle',
        },
      },
    },
    grid: { y: { show: true } },
  });

const atRiskChartData = (data, name) => {
  const rtn = {};
  const json = [];

  for (let i = 0; i < data.length; i += 1) {
    const item = data[i];
    json.push({
      id: item.id,
      x: item.short_name,
      indicator: item.avg.toFixed(2),
    });
  }

  rtn.json = json.sort((a, b) => b.indicator - a.indicator);
  rtn.keys = { x: 'x', value: ['indicator'] };

  rtn.type = 'bar';
  rtn.names = { indicator: name };
  rtn.colors = { indicator: 'red' };

  return rtn;
};

const comparisonChartData = (tableData, selectedPracticeName) => {
  const chartData = {
    keys: { x: 'x', value: ['ccg', 'practice'] },
    types: { ccg: 'line', practice: 'bar' },
    names: { ccg: 'CCG Average', practice: selectedPracticeName },
    colors: { ccg: 'black', practice: 'red' },
  };
  chartData.json = tableData
    .map((item) => ({
      id: item.id,
      x: item.short_name,
      ccg: item.ccg,
      practice: item.avg,
    }))
    .sort((a, b) => b.practice - a.practice);

  return chartData;
};

const shortDateFormat = (date) => {
  const d1 = new Date(date).toDateString().substr(4); // Jan 01 2016
  const dateBits = d1.split(' '); // ['Jan', '01', '2016']
  return `${+dateBits[1]} ${dateBits[0]} ${dateBits[2]}`;
};

const doDates = (start, end, trendData) => {
  const dates = trendData.columns[0]
    .slice(1) // don't want the "x"
    .sort((a, b) => new Date(b) - new Date(a)); // sort date descending

  const startDate = new Date(start || dates[dates.length - 1]);
  startDate.setHours(startDate.getHours() + 5);
  const endDate = new Date(end || dates[0]);
  const endDateForComparison = new Date(end || dates[0]);
  endDateForComparison.setHours(1);

  const endDates = trendData.columns[0]
    .slice(1) // don't want the "x"
    .sort((a, b) => new Date(b) - new Date(a)) // sort date descending
    .filter((d) => new Date(d) > startDate)
    .map((d) => ({
      date: shortDateFormat(d),
      isSelected: shortDateFormat(d) === shortDateFormat(endDate),
    }));

  const startDates = trendData.columns[0]
    .slice(1) // don't want the "x"
    .sort((a, b) => new Date(b) - new Date(a)) // sort date descending
    .filter((d) => new Date(d) < endDateForComparison)
    .map((d) => ({
      date: shortDateFormat(d),
      isSelected: shortDateFormat(d) === shortDateFormat(startDate),
    }));
  return { endDates, startDates, endDate, startDate };
};

const updateChartDateRange = (id, data, start, end) => {
  const { endDates, startDates, startDate, endDate } = doDates(
    start,
    end,
    data.trendChartData[+id === 1 ? 'num' : 'avg']
  );

  if (!state.currentChart || !state.currentChart.axis) return;

  const startDateSelectHtml = dateSelect(startDates, 'startDate');
  const endDateSelectHtml = dateSelect(endDates, 'endDate');

  $('#startDate').replaceWith(startDateSelectHtml);
  $('#endDate').replaceWith(endDateSelectHtml);
  state.currentChart.axis.range({
    min: { x: startDate.getTime() - 12 * 60 * 60 * 1000 },
    max: { x: endDate.getTime() + 12 * 60 * 60 * 1000 },
  });
};

const displayCCGChart = (id, data, start, end, chartSort) => {
  // determine the valid dates for the start/end dropdowns
  const { endDates, startDates, startDate, endDate } = doDates(
    start,
    end,
    data.trendChartData[+id === 1 ? 'num' : 'avg']
  );
  const areDates = [1, 2, 7].indexOf(+id) < 0;

  // create chart panel
  const chartContainerHtml = chartContainerCmpt(areDates, startDates, endDates);
  $('#chartPanel').html(chartContainerHtml);

  let currentChart;

  switch (+id) {
    case 1: // Proportion of eligible patients affected
      ccgAllIndicatorsAtRisk(
        '#chart',
        atRiskChartData(data.tableData, 'All indicators'),
        data.dateId,
        data.comparisonDataId
      );
      break;
    case 2: // Split of patients affected by 1 indicator and those affected by 2 or more
      ccgAllIndicatorsMultiple(
        '#chart',
        getPatientsMultipleChartData(data.tableData, chartSort),
        data.dateId,
        data.comparisonDataId
      );
      break;
    case 3: // Number of affected patients over time
      return singlePracticeTrend(
        '#chart',
        trendChartData(data.trendChartData.num, startDate, endDate, true),
        'Number of affected patients'
      );
    case 4: // Number of affected patients over time (all practices combined)
      return allPracticeTrend(
        '#chart',
        trendChartData(data.trendMegaChartData, startDate, endDate, false),
        'Number of affected patients'
      );
    case 5: // Proportion of eligible patients affected over time
      currentChart = singlePracticeTrend(
        '#chart',
        trendChartData(data.trendChartData.avg, startDate, endDate, true),
        '% of eligible patients affected'
      );
      currentChart.show('CCG Avg');
      return currentChart;
    case 6: // Number of patients affected by more than one indicator over time
      return singlePracticeTrend(
        '#chart',
        trendChartData(
          data.trendChartData.patientsMultiple,
          startDate,
          endDate,
          true
        ),
        'Patients affected by more than one indicator'
      );
    case 7: // Proportion of eligible patients affected
      ccgSingleIndicatorAtRisk(
        '#chart',
        atRiskChartData(data.tableData, data.indicator.short_name),
        data.dateId,
        data.comparisonDataId
      );
      break;
    case 8: // Number of affected patients over time
      return singlePracticeTrend(
        '#chart',
        trendChartData(data.trendChartData.num, startDate, endDate, true),
        'Number of affected patients'
      );
    default: // Proportion of eligible patients affected over time
      currentChart = singlePracticeTrend(
        '#chart',
        trendChartData(data.trendChartData.avg, startDate, endDate, true),
        '% of eligible patients affected'
      );
      currentChart.show('CCG Avg');
      return currentChart;
  }
};

const displaySinglePracticeChart = (id, data, start, end) => {
  // determine the valid dates for the start/end dropdowns
  const { endDates, startDates, startDate, endDate } = doDates(
    start,
    end,
    data.trendChartData[+id === 1 ? 'num' : 'avg']
  );
  const areDates = +id !== 1;

  // create chart panel
  const chartContainerHtml = chartContainerCmpt(areDates, startDates, endDates);
  $('#chartPanel').html(chartContainerHtml);

  switch (+id) {
    case 2:
      return singlePracticeTrend(
        '#chart',
        trendChartData(data.trendChartData.num, startDate, endDate),
        'Number of affected patients'
      );
    case 3:
      return singlePracticeTrend(
        '#chart',
        trendChartData(data.trendChartData.avg, startDate, endDate),
        '% of eligible patients affected'
      );
    default:
      singlePracticeComparison(
        '#chart',
        comparisonChartData(data.tableData, data.selectedPracticeName),
        data.practiceId,
        data.dateId,
        data.comparisonDateId
      );
  }
};

const displayPracticeIndicatorChart = (data, start, end) => {
  const { endDates, startDates, startDate, endDate } = doDates(
    start,
    end,
    data
  );
  const chartContainerHtml = chartContainerCmpt(true, startDates, endDates);
  $('#chartPanel').html(chartContainerHtml);

  singlePracticeTrend(
    '#chart',
    trendChartData(data, startDate, endDate),
    'Number of affected patients'
  );
};

const navigateToPractice = (clickedColumn, columns, date, comparedWith) => {
  const practice = columns[clickedColumn.x];
  window.Router.navigate(
    `/practice/${practice.id}/date/${date}/comparedWith/${comparedWith}/tab/undefined`
  );
};

const navigateToIndicator = (
  clickedColumn,
  columns,
  practiceId,
  date,
  comparisonDate
) => {
  const item = columns[clickedColumn.x];
  window.Router.navigate(
    `/practice/${practiceId}/date/${date}/comparedWith/${comparisonDate}/indicator/${item.id}/affected/show/table/tab/1`
  );
};

export {
  displaySinglePracticeChart,
  displayPracticeIndicatorChart,
  displayCCGChart,
  updateChartDateRange,
  allPracticeTrend,
};
