import { getLongDate } from '../../shared/utils';
import note from './note';

const reports = {
  affected: {
    tooltip: 'The number of patients affected by this indicator',
    heading: 'Affected patients',
  },
  existing: {
    tooltip: 'Patients no longer affected by this indicator since the last comparison date',
    heading: 'Successful intervention',
  },
  resolved: {
    tooltip: 'Patients who remain affected by this indicator since the last comparison date',
    heading: 'Action pending',
  },
  new: {
    tooltip: 'Patients affected by this indicator who weren\'t affected on the last comparison date',
    heading: 'New cases',
  },
};

export default (summary, reportType, showTable, tabId, info) => `
<div class="pull-right">
<button class="btn btn-primary" id="practiceIndicatorExport" type="button" ${+tabId !== 1 ? 'style="display:none;"' : ''}>Export</button>
  <a class="btn btn-primary" data-href="/practice/${summary.practiceId}/date/${summary.dateId}/comparedWith/${summary.comparisonDateId}/indicator/${summary.indicatorId}/${reportType}/show/${showTable ? 'chart' : 'table'}" href="javascript:void()" id="toggleChart" role="button"${+tabId !== 2 ? ' style="display: none"' : ''}>View as ${showTable ? 'chart' : 'table'}</a>
</div>

<ul class="nav nav-tabs" role="tablist">
  <li ${+tabId === 1 ? 'class="active"' : ''} role="presentation"><a id="tableTab" href="#patients" aria-controls="patients" role="tab" data-toggle="tab" data-id="1">Patients</a></li>
  <li ${+tabId === 2 ? 'class="active"' : ''} role="presentation"><a id="trendTableTab" href="#trend" aria-controls="trend" role="tab" data-toggle="tab" data-id="2">Trend</a></li>
  <li ${+tabId === 3 ? 'class="active"' : ''} role="presentation"><a href="#information" aria-controls="information" role="tab" data-toggle="tab" data-id="3">Information</a></li>
</ul>
<!-- Tab panes-->
<div class="tab-content">
  <div class="tab-pane tab-borders fade${+tabId === 1 ? ' active in' : ''}" id="patients" role="tabpanel">
    ${summary.patients && summary.patients.length > 0
    ? `<table class="table" id="patientTable">
    <thead>
      <tr>
        <th title="The patient's NHS number" data-toggle="tooltip" data-container="body" data-placement="bottom">NHS number</th>
        <th title="A list of prescribing safety indicators that the patient is currently affected by" data-toggle="tooltip" data-container="body" data-placement="bottom">Indicators breached</th>
        <th title="The date since which the patient was originally found to be affected by this indicator" data-toggle="tooltip" data-container="body" data-placement="bottom">Since</th>
        <th title="Any notes associated with the patient are displayed here" data-toggle="tooltip" data-container="body" data-placement="bottom">Notes</th>
      </tr>
    </thead>
    <tbody>
      ${summary.patients.map(patient => `
      <tr class="no-priority">
        <td data-patient-id="${patient.id}">${patient.nhs}</td>
        <td>
          ${patient.indicators.map(indicator => `
            <div title="${indicator.long_name}" data-toggle="tooltip" data-container="body" data-placement="left">${indicator.short_name}</div>
          `).join('')}
        </td>
        <td>
          ${patient.indicators.map(indicator => `
            <div>${getLongDate(indicator.since)}</div>
          `).join('')}
        </td>
        <td data-patient-id="${patient.id}" class="note-field">
           ${note(patient)}
        </td>
      </tr>
      `).join('')}
    </tbody>
  </table>`
    : 'No patients found'}

  </div>
  <div class="tab-pane tab-borders fade${+tabId === 2 ? ' active in' : ''}" id="trend" role="tabpanel">
    <div class="clearfix">
      <div>
    ${showTable
    ? `<table class="table" id="trendTable">
        <thead>
          <tr>
            <th title="Date of the report" data-toggle="tooltip" data-container="body" data-placement="bottom">Date</th>
            <th title="${reports[reportType].tooltip}" data-toggle="tooltip" data-container="body" data-placement="bottom">${reports[reportType].heading}</th>
            <th title="Difference from previous report" data-toggle="tooltip" data-container="body" data-placement="bottom">Trend</th>
          </tr>
        </thead>
        <tbody>
          ${summary.tableData.map(datum => `
          <tr>
            <td>${getLongDate(datum.date)}</td>
            <td>${datum.num}</td>
            <td>
              <img src='/img/${datum.trend}.png' />
              ${datum.trendValue}
            </td>
          </tr>
          `).join('')}
        </tbody>
      </table>`
    : '<div id="chartPanel"></div>'}
      </div>
    </div>
  </div>
  <div class="tab-pane tab-borders fade${+tabId === 3 ? ' active in' : ''}" id="information" role="tabpanel" style="height:60vh;overflow-y:auto;">
    ${info}
  </div>
</div>
  `;
