import alertComponent from '../components/alert';
import messageComponent from '../components/message';
import userMenuComponent from '../components/userMenu';
import api from '../api';
import breadcrumbComponent from '../components/breadcrumbs';

const loginStatus = 'status';

const updateBreadcrumbs = (crumbs) => {
  const html = breadcrumbComponent(crumbs);
  document.getElementById('breadcrumbs').innerHTML = html;
};

const updateActive = (id) => {
  const activeLinks = document.querySelector('li.active');
  if (activeLinks) activeLinks.classList.remove('active');

  const activeLink = document.querySelector(`#${id}`);
  if (activeLink) activeLink.classList.add('active');
};

const showAlert = (message, type) => {
  const messageContainer = document.getElementById('message');
  if (messageContainer) {
    messageContainer.innerHTML = alertComponent(message, type);
  }
};

const hideAlert = () => {
  const messageContainer = document.getElementById('message');
  if (messageContainer) {
    messageContainer.innerHTML = '';
  }
};

const showMessage = (
  title,
  message,
  buttonLink,
  buttonText
) => messageComponent(title, message, buttonLink, buttonText);

const updateUserDropdown = () => {
  api.getUser()
    .then((user) => {
      if (user && user.name) {
        const userMenuHtml = userMenuComponent(user.name, user.roles.includes('admin'));
        document.getElementById('userDropdown').innerHTML = userMenuHtml;
        document.getElementById('userDropdown').style.display = 'block';
        document.getElementById('logout-button').onclick = () => {
          resetLoginSession();
        };
      } else {
        document.getElementById('userDropdown').style.display = 'none';
      }
    })
    .catch(() => {
      document.getElementById('userDropdown').style.display = 'none';
    });
};

const showLogin = () => {
  resetLoginSession();

  document.getElementById('loginContent').style.display = 'block';
  document.getElementById('smash-update-message').style.display = 'block';

  document.getElementById('mainContent').style.display = 'none';
  updateUserDropdown();
};

const hideLogin = () => {
  refreshLoginSession();


  document.getElementById('loginContent').style.display = 'none';
  document.getElementById('smash-update-message').style.display = 'none';
  document.getElementById('mainContent').style.display = 'block';

  updateUserDropdown();
};


const resetLoginSession = () => {
  sessionStorage.removeItem(loginStatus);
};


const refreshLoginSession = () => {
  if (!sessionStorage.getItem(loginStatus)) {
    sessionStorage.setItem(loginStatus, 'signedin');
  }
};


const getLoginSession = () => sessionStorage.getItem(loginStatus);

const getComparisonDate = (comparisonDates, dateId) => {
  // If there is a "30 days" ago then use that
  const thirtyDaysAgo = comparisonDates.filter(d => d.value.indexOf('30 days') > -1);
  if (thirtyDaysAgo.length > 0) {
    return thirtyDaysAgo[0]._id;
  }
  // If there is a date more than 30 days ago take the most recent of them
  const moreThan30DaysAgo = comparisonDates.filter(d => d._id < (parseInt(dateId) - 30));
  if (moreThan30DaysAgo.length > 0) {
    return moreThan30DaysAgo[0]._id;
  }
  // do the oldest
  return comparisonDates[comparisonDates.length - 1]._id;
};

const getDate = (dates, dateId, defaultDate) => {
  const isDateCorrect = dates.filter(date => date._id === parseInt(dateId))[0];
  if (!isDateCorrect) {
    if (defaultDate) {
      return defaultDate();
    }


    const latestDate = dates.filter(date => date.value.includes('Latest'))[0];
    if (latestDate) {
      return latestDate._id;
    }
  }
  return dateId;
};

export { updateActive, updateBreadcrumbs, showAlert, hideAlert, hideLogin, showLogin, showMessage, getLoginSession, getDate, getComparisonDate };
