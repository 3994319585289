export default (placeName, isPractice, id) => `
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close"><span aria-hidden="true" class="close-me">&times;</span></button>
        <h4 class="modal-title" id="myModalLabel">Request access</h4>
      </div>
      <div class="modal-body">
        <h4>We will contact the approver for ${placeName}. Please confirm that you require access to ${placeName}</h4>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-is-practice="${isPractice}" data-id="${id}">Request access</button>
        <button type="button" class="btn btn-default close-me">Cancel</button>
      </div>
    </div>
  </div>
`;
