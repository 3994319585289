import messageTemplate from '../alert';

export default response => `
  <div class="container container-small">
    ${response.status === 'success'
    ? `
        <form id="resetPasswordForm">
          <legend>Reset Password</legend>
          <input type="hidden" name="_csrf" />
          <div class="form-group">
            <label for="password">New Password</label>
            <input class="form-control" type="password" name="password" id="password" placeholder="New password" autofocus="autofocus" required="required" />
          </div>
          <div class="form-group">
            <label for="confirm">Confirm Password</label>
            <input class="form-control" type="password" name="confirm" id="confirm" placeholder="Confirm password" required="required" />
          </div>
          <div class="form-group">
            <button class="btn btn-success btn-block btn-reset" type="submit">Change Password <i class="fas fa-key"></i></button>
          </div>
        </form>`
    : `
      <div class="message">${messageTemplate(response.message, response.status === 'error' ? 'danger' : 'info')}</div>
      <a class="btn btn-info" href="javascript:void(0)" data-href="/forgot">Click to resend the reset email</a>
    `}
  </div>
`;
