const status = {
  APPROVED: 'Access approved',
  PENDING: 'Awaiting approval',
  NONE: 'No access',
};

const PRACTICE = {
  id: 'practice',
  title: 'Practice',
};
const CCG = {
  id: 'ccg',
  title: 'CCG',
};

const approvalStatus = (item, userItems) => {
  if (userItems.indexOf(`${item}`) > -1 || userItems.indexOf(+item) > -1) return status.APPROVED;
  if (userItems.filter(x => x.needsApproval && (+x.id === +item || x.id === item)).length) {
    return status.PENDING;
  }
  return status.NONE;
};

const approvalStatusSpan = (item, userItems) => {
  switch (approvalStatus(item, userItems)) {
    case status.APPROVED:
      return `<span class="badge" style="background-color:green">${status.APPROVED}</span>`;
    case status.PENDING:
      return `<span class="badge" style="background-color:orange">${status.PENDING}</span>`;
    default:
      return `<span class="badge">${status.NONE}</span>`;
  }
};

const actionButton = (id, name, userItems, practiceOrCcg) => {
  switch (approvalStatus(id, userItems)) {
    case status.APPROVED:
      return `<a class="btn btn-sm btn-danger request-access" href="javascript:void(0)" data-place-name="${name}" data-${practiceOrCcg.id}-id="${id}">
                <i class="fas fa-fw fa-exclamation-triangle"></i> Revoke access
              </a>`;
    case status.PENDING:
      return `<a class="btn btn-sm btn-warning resend-email" href="javascript:void(0)" data-place-name="${name}" data-${practiceOrCcg.id}-id="${id}">
                <i class="fas fa-fw fa-envelope"></i> Resend email
              </a>`;
    default:
      return `<a class="btn btn-sm btn-primary request-access" href="javascript:void(0)" data-place-name="${name}" data-${practiceOrCcg.id}-id="${id}" data-authorise="true">
                <i class="fas fa-fw fa-key"></i> Request access
              </a>`;
  }
};

const actionTable = (places, userPlaces, practiceOrCcg) => `
  <table class="table table-striped table-condensed table-approval-buttons">
    <thead>
      <tr>
        <th>${practiceOrCcg.title}</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      ${places.sort((a, b) => {
    if (a.short_name > b.short_name) return 1;
    if (a.short_name < b.short_name) return -1;
    return 0;
  }).map(place => `
        <tr>
          <td>${place.short_name}</td>
          <td>${approvalStatusSpan(place._id, userPlaces)}</td>
          <td>${actionButton(place._id, place.short_name, userPlaces, practiceOrCcg)}</td>
        </tr>
      `).join('')}
    </tbody>
  </table>
`;

const practiceActionTable = (ccgs, practices, user) => {
  if (user.ccgs && user.ccgs.length > 0) {
    // Display practices in ccgs already assigned to user first
    // for ease of finding.
    ccgs.sort((a, b) => {
      if (user.ccgs.indexOf(a._id) > -1 && user.ccgs.indexOf(b._id) < 0) {
        return -1;
      }
      if (user.ccgs.indexOf(b._id) > -1 && user.ccgs.indexOf(a._id) < 0) {
        return 1;
      }
      if (a.short_name > b.short_name) return 1;
      if (a.short_name < b.short_name) return -1;
      return 0;
    });
  }
  return `<div id="practice-action-tables">${ccgs
    .map(ccg => `<h4>${ccg.short_name}</h4>${actionTable(
      practices.filter(p => p.ccg === ccg._id), user.practices, PRACTICE
    )}`)
    .join('')}</div>`;
};

const ccgActionTable = (ccgs, user) => `<div id="ccg-action-table">${actionTable(ccgs, user.ccgs, CCG)}</div>`;

const accountComponent = (practices, ccgs, user, cacheStats) => `
  <div id="accountComponent">
    ${user.roles.indexOf('admin') > -1 ? `
      <h2>Cache</h2>
      <div>
      <p>Keys: ${cacheStats.keys}</p>
      <p>Key size (in bytes): ${cacheStats.ksize}</p>
      <p>Value size (in bytes): ${cacheStats.vsize}</p>
      </div>
      <a class="btn btn-sm btn-danger clear-cache" href="javascript:void(0)" >
            Clear cache
      </a>

    ` : ''}
    ${user.roles.indexOf('ccg') > -1
    ? `
    <h2>CCGs</h2>
    <p>You have the "ccg user" role, which means you can see how practices within a CCG compare. The table below shows where you currently have access.</p>
    ${ccgActionTable(ccgs, user)}`
    : ''}

    ${user.practices.length
    ? `   
    <h2>Practices</h2>
    <p>You have a patient level role, which means you can see NHS numbers in practices where you have approval. See the table below for practices where you currently have access.</p>
    ${practiceActionTable(ccgs, practices, user)}`
    : `   
    <h2>Practices</h2>
    <p>You do not currently have access to any individual practices. If you wish to gain access to patient level access at a practice, please use the table below. All requests for access will be directed to the practice approver which is typically the practice manager.</p>
    ${practiceActionTable(ccgs, practices, user)}`}
  </div>
`;

export {
  practiceActionTable,
  ccgActionTable,
  accountComponent,
};
