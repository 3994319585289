export default (summary, indicatorId, approvedCCGsLookup) => `
<div class="pull-right">
  <button class="btn btn-primary" id="export" type="button">Export</button>
</div>

<div class="row">
  <table class="table table-striped" id="indicatorTable">
    <thead>
      <tr>
        <th class="pointer" title="The CCG" data-toggle="tooltip" data-container="body" data-placement="top">CCG</th>
${indicatorId === 0
    ? `
        <th class="pointer" title="The number of patients affected by any one of the prescribing safety indicators across all practices in each CCG" data-toggle="tooltip" data-container="body" data-placement="top"">Affected patients</th>
        <th class="pointer" title="Proportion of eligible patients across all practices in each CCG who are affected by at least one indicator" data-toggle="tooltip" data-container="body" data-placement="top"">% of eligible patients affected</th>
        <th class="pointer" title="Proportion of eligible patients across all practices in Greater Manchester" data-toggle="tooltip" data-container="body" data-placement="top"">GM Avg (%)</th>
        <th class="pointer" title="The number of patients affected by more than 1 prescribing safety indicator across all CCGs" data-toggle="tooltip" data-container="body" data-placement="top"">More than one indicator</th>`
    : `
        <th class="pointer" title="The number of patients affected by this indicator across all practices in each CCG" data-toggle="tooltip" data-container="body" data-placement="top">Affected patients</th>
        <th class="pointer" title="The population of patients across all practices in each CCG that this indicator could affect" data-toggle="tooltip" data-container="body" data-placement="top">Eligible patients</th>
        <th class="pointer" title="Proportion of the population in focus that are affected by this indicator across all practices in each CCG" data-toggle="tooltip" data-container="body" data-placement="top">% of eligible patients affected</th>
        <th class="pointer" title="Proportion of the population in focus that are affected by this indicator across all practices in Greater Manchester" data-toggle="tooltip" data-container="body" data-placement="top">GM Avg (%)</th>
`}
      </tr>
    </thead>
    <tbody>
${summary.tableData.map(item => `
      <tr class="no-priority">

      ${approvedCCGsLookup[item.id] ? `<td class="prominentLink" title="Click to view CCG" data-toggle="tooltip" data-container="body" data-placement="left" data-href="/ccg/${item.id}/date/${summary.dateId}/comparedWith/${summary.comparisonDateId}/tab/undefined"><a class="prominentLink" href="javascript:void(0)">${item.short_name}</a></td>`
    : `<td class="prominentLink disabled" title="You currently don't have access to this CCG" data-toggle="tooltip" data-container="body" data-placement="left" >${item.short_name}</td>`}

        <td>${item.num}</td>
${indicatorId === 0
    ? `     <td>${item.avg}</td>
        <td>${item.ccg}</td>
        <td>${item.patientsMultiple}</td>`
    : `     <td>${item.denom}</td>
        <td>${item.avg}</td>
        <td>${item.ccg}</td>
`}
      </tr>
`).join('')}
    </tbody>
  </table>
</div>
`;
