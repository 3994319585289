const CCGList = (ccgs, user) => { 
    const ccgOptions = ccgs
    .sort((a, b) => {
      if (a.short_name > b.short_name) return 1;
      if (a.short_name < b.short_name) return -1;
      return 0;
    })
    .map(ccg => `
    <option value="${ccg._id}" ${(user && user.ccgs.indexOf(ccg._id) > -1) ? 'selected' : ''}>
      ${ccg.short_name}
    </option>
  `).join('');

    return ccgOptions;
}


export default CCGList; 