import { escapeHTML } from '../../../shared/utils';

const practicesInCCG = (practices, ccgId) => practices
  .filter(practice => practice.ccg === ccgId)
  .map(practice => practice.short_name);


const getCCGRows = (ccgs, practices) => ccgs.sort((a, b) => {
  if (a.short_name < b.short_name) return -1;
  return a.short_name === b.short_name ? 0 : 1;
}).map((ccg, idx) => `
  <tr ${idx % 2 === 0 ? 'style="background-color: #eee"' : ''}>
    <td style="line-height:33px">${ccg._id}</td>
    <td>
      <input type="text" class="form-control short_name" data-ccg-id="${ccg._id}" data-original-value="${ccg.short_name}" value="${ccg.short_name}">
    </td>
    <td>
      <input type="text" class="form-control long_name" data-ccg-id="${ccg._id}" data-original-value="${ccg.long_name}" value="${ccg.long_name}">
    </td>
    <td>
      <button class="btn btn-warning save-ccg" data-ccg-id="${ccg._id}" disabled>Save</button>
    </td>
  </tr>
  <tr ${idx % 2 === 0 ? 'style="background-color: #eee"' : ''}>
    <th title="Practices in the CCG - can't edit here - do it via the practice editor page" data-toggle="tooltip" data-container="body" data-placement="top" style="border-top-width: 0">Practices:</th>
    <td colspan="3" style="border-top-width: 0">
      ${practicesInCCG(practices, ccg._id).join(', ')}
    </td>
  </tr>
  <tr ${idx % 2 === 0 ? 'style="background-color: #eee"' : ''}>
    <th title="User approvers in the CCG - can't edit here - do it in the appserver json file" data-toggle="tooltip" data-container="body" data-placement="top" style="border-top-width: 0">Approvers:</th>
    <td colspan="3" style="border-top-width: 0">
      ${!ccg.approvers || ccg.approvers.length === 0
    ? 'No approvers assigned'
    : ccg.approvers.map(escapeHTML).join(', ')}
  </tr>
  `).join('');

export default (ccgs, practices) => {
  const ccgRows = getCCGRows(ccgs, practices);
  const table = rowData => `
  <table class="table table-condensed">
    <thead>
      <tr>
        <th title="The CCG code - nationally recognised system" data-toggle="tooltip" data-container="body" data-placement="top">Code</th>
        <th title="Short name for displaying when not much space" data-toggle="tooltip" data-container="body" data-placement="top">Short name</th>
        <th title="Longer name for when there is more space" data-toggle="tooltip" data-container="body" data-placement="top">Long name</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      ${rowData}
    </tbody>
  </table>
  `;
  return `
    <h1>CCGs</h1>
    <h2>YOU PROBABLY SHOULDN'T UPDATE THE NAMES HERE. That was before everything was put in git. To update the info check out deploy/appserver/data/Readme.md</h2>
    ${table(ccgRows)}
  `;
};
