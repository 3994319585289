import api from './api';
import { getLoginSession } from './controllers/common';

// const SESSION_TIMEOUT = 2 * 3600 * 1000;

// let timer = setTimeout(() => {
//   window.location.href = '/signout';
// }, SESSION_TIMEOUT); // set session logout to 2 hours

// const refreshSession = () => {
//   // console.log('Session refreshed...');
//   clearTimeout(timer);
//   timer = setTimeout(() => {
//     window.location.href = '/signout';
//   }, SESSION_TIMEOUT); // set session logout to 2 hours
// };

/**
 * Gets an XPath for an element which describes its hierarchical location.
 * Copied from firebug with a BSD licence - https://code.google.com/p/fbug/source/browse/branches/firebug1.6/content/firebug/lib.js?spec=svn12950&r=8828#1332
 */
const getElementTreeXPath = (element) => {
  const paths = [];
  let el = element;

  // Use nodeName (instead of localName) so namespace prefix is included (if any).
  for (; el && el.nodeType === 1; el = el.parentNode) {
    let index = 0;
    for (
      let sibling = el.previousSibling;
      sibling;
      sibling = sibling.previousSibling
    ) {
      // Ignore document type declaration.
      if (
        sibling.nodeType !== Node.DOCUMENT_TYPE_NODE &&
        sibling.nodeName === el.nodeName
      ) {
        index += 1;
      }
    }

    const tagName = el.nodeName.toLowerCase();
    const pathIndex = index ? `[${index + 1}]` : '';
    paths.splice(0, 0, tagName + pathIndex);
  }

  return paths.length ? `/${paths.join('/')}` : null;
};

const getElementXPath = (el) => {
  if (el && el.id) return `//*[@id="${el.id}"]`;
  return getElementTreeXPath(el);
};

const logInfo = (event, type, href) => {
  // refreshSession();
  let xpath = '';
  let text = '';
  if (event) {
    xpath = getElementXPath(event.target);

    let t = event.target;

    /* SMASH specific stuff - might need it after testing */
    if (
      t.nodeName.toLowerCase() === 'span' &&
      t.parentNode &&
      t.parentNode.nodeName
    ) {
      if (t.parentNode.nodeName.toLowerCase() === 'button') {
        t = t.parentNode;
      } else if (t.parentNode.nodeName.toLowerCase() === 'a') {
        t = t.parentNode;
      }
    }

    if (xpath === '/html/body') {
      text = '';
    } else if (t.nodeName.toLowerCase() === 'a') {
      text = $(t).text().trim();
    } else if (t.nodeName.toLowerCase() === 'select') {
      if (t.selectedIndex) {
        text = $(t.item(t.selectedIndex)).text().trim();
      }
    } else if (t.nodeName.toLowerCase() === 'button') {
      text = $(t).text().trim();
    } else if ($(t).data('patient-id')) {
      text = `NHS number for patient id ${$(t).data('patient-id')}`;
      // Don't want to capture nhs numbers in the events
    } else if ($(t).text().trim() !== '') {
      text = $(t).text().trim();
    } else {
      text = $(t).text().trim().replace(/\n/g, '').replace(/ +/g, ' ');
    }

    // Final double check
    text = text
      .replace(/(?:^|\D)(\d{10})(?=\D|$)/g, 'xxxxxxxxxx')
      .substring(0, 50); // replaces any 10 digit characters with xxxxxxxxxx
  } // if(event)

  const loginSession = getLoginSession();
  if (type === 'click' && loginSession) {
    checkForExpiredSession();
  }

  // console.log(obj);
  setTimeout(() => {
    api.event(type, href || window.location.href, text, xpath);
  }, 1);
};

const delay = 500;
const setTimeoutConst = {};
let isListening = false;

const checkForExpiredSession = () => {
  api
    .isLoggedIn()
    .then((response) => {
      if (
        response &&
        !response.isLoggedIn &&
        !window.location.pathname.includes('/login')
      ) {
        window.location.href = '/login?sessionExpired=true';
      }
    })
    .catch((error) => {
      console.error('Error when calling isLoggedIn() in event.js:', error);
    });
};

export default () => {
  if (isListening) {
    console.error('Hmm - trying to do the window listening more than once..');
    return;
  }

  isListening = true;

  $(document)
    .on('keyup', (event) => {
      // if enter, tab, arrows then record
      if (event.which === 13) {
        // enter
        logInfo(event, 'Key_ENTER');
      } else if (event.which === 9) {
        // tab
        logInfo(event, 'Key_TAB');
      } else if (event.which === 38) {
        // arrow up
        logInfo(event, 'Key_ARROW_UP');
      } else if (event.which === 40) {
        // arrow down
        logInfo(event, 'Key_ARROW_DOWN');
      } else if (event.which === 37) {
        // arrow left
        logInfo(event, 'Key_ARROW_LEFT');
      } else if (event.which === 39) {
        // arrow up
        logInfo(event, 'Key_ARROW_RIGHT');
      }
    })
    .on('mouseover', (event) => {
      setTimeoutConst[event.target] = setTimeout(() => {
        logInfo(event, 'hover');
      }, delay);
    })
    .on('mouseout', (event) => {
      clearTimeout(setTimeoutConst[event.target]);
    })
    .on('click', (event) => {
      // don't log 'triggered' clicks
      if (!event.which) return;
      clearTimeout(setTimeoutConst[event.target]);
      setTimeoutConst[event.target] = setTimeout(() => {
        logInfo(event, 'hover');
      }, delay);
      logInfo(event, 'click');
    });
};
