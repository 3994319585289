import api from '../api';
import {
  updateActive,
  updateBreadcrumbs,
  showAlert,
  hideLogin,
} from './common';
import indicatorEditorComponent from '../components/admin/indicatorEditor';
import { displayLoader } from '../loader';

const isIndicatorDirty = (indicatorId) => {
  let isDirty = false;

  // Things with a single value
  $(
    `input[type="text"][data-indicator-id="${indicatorId}"], textarea[data-indicator-id="${indicatorId}"]`
  ).each(function eachInput() {
    isDirty = isDirty || $(this).val() !== $(this).data('originalValue');
  });

  // Checkboxes
  $(`input[type="checkbox"][data-indicator-id="${indicatorId}"]`).each(
    function eachInput() {
      isDirty =
        isDirty || $(this).is(':checked') !== $(this).data('originalValue');
    }
  );

  // Things with multiple values
  $(`select[data-indicator-id="${indicatorId}"]`).each(function eachInput() {
    isDirty =
      isDirty || $(this).val().join('') !== $(this).data('originalValue');
  });

  return isDirty;
};

export default (callback) => {
  displayLoader(
    Promise.all([api.allIndicators(), api.users(), api.allPractices()]),
    ([indicators, users, practices]) => {
      hideLogin();

      updateActive('link-indicators');
      const crumbs = [{ label: 'Indicator Editor' }];
      updateBreadcrumbs(crumbs);

      const modifiedIndicators = indicators.map((indicator) => {
        indicator.usersWithAccess = indicator.usersWithAccess || [];
        indicator.practicesWithAccess = indicator.practicesWithAccess || [];
        indicator.isVisible = indicator.isVisible || false;
        return indicator;
      });

      const adminHtml = indicatorEditorComponent(
        modifiedIndicators,
        users,
        practices
      );

      document.getElementById('page').innerHTML = adminHtml;

      $('.tooltip').tooltip('hide');
      $('[data-toggle="tooltip"]').tooltip();

      // wire up the bootstrap-select thing
      $('.selectpicker').selectpicker();

      $('.save-indicator').on('click', (e) => {
        const { indicatorId } = e.target.dataset;
        const indicator = {
          short_name: $(
            `.short_name[data-indicator-id="${indicatorId}"]`
          ).val(),
          long_name: $(`.long_name[data-indicator-id="${indicatorId}"]`).val(),
          info: $(`.info_text[data-indicator-id="${indicatorId}"]`).val(),
          isVisible: $(
            `[name="isVisible"][data-indicator-id="${indicatorId}"]`
          ).is(':checked'),
          usersWithAccess: $(
            `[name="usersWithAccess"][data-indicator-id="${indicatorId}"]`
          ).val(),
          practicesWithAccess: $(
            `[name="practicesWithAccess"][data-indicator-id="${indicatorId}"]`
          ).val(),
        };

        api
          .updateIndicator(indicatorId, indicator)
          .then((resp) => {
            if (resp.status === 'success') {
              showAlert(resp.message, 'success');
            } else {
              showAlert(resp.message, 'danger');
            }
            $(
              `input[data-indicator-id="${indicatorId}"], textarea[data-indicator-id="${indicatorId}"], select[data-indicator-id="${indicatorId}"]`
            ).each(function eInput() {
              $(this).data('originalValue', $(this).val());
            });
            const isDirty = isIndicatorDirty(indicatorId);
            $(`button[data-indicator-id="${indicatorId}"]`).prop(
              'disabled',
              !isDirty
            );
            setTimeout(() => {
              window.location.reload();
            }, 400);
          })
          .catch(() => {
            showAlert('Some sort of error occurred', 'danger');
          });
      });

      $('input, textarea').on('keyup', (e) => {
        const { indicatorId } = e.target.dataset;

        const isDirty = isIndicatorDirty(indicatorId);

        $(`button[data-indicator-id="${indicatorId}"]`).prop(
          'disabled',
          !isDirty
        );
      });
      $('.fa-edit').on('click', (e) => {
        const { indicatorId } = e.target.dataset;
        $(`.info_text[data-indicator-id="${indicatorId}"]`).show();
      });

      $('select, [type="checkbox"]').on('change', (e) => {
        const { indicatorId } = e.target.dataset;

        const isDirty = isIndicatorDirty(indicatorId);

        $(`button[data-indicator-id="${indicatorId}"]`).prop(
          'disabled',
          !isDirty
        );
      });
      if (callback) callback();
    }
  ).catch((err) => {
    if (err.message === 'Not logged in') {
      window.Router.navigate('/login');
    }
    if (callback) callback();
  });
};
