const dateSelect = (dates, id) => `
<select id="${id}">
  ${dates
    .map(
      (d) =>
        `<option value="${d.date}"${d.isSelected ? ' selected' : ''}>${
          d.date
        }</option>`
    )
    .join('')}
</select>`;

const datePicker = (dates, label, id) => `
  <div class="inline-element left-buffer align-bottom pull-right">
    <div>
      <label>${label}:</label>
    </div>
    <div>
      ${dateSelect(dates, id)}
    </div>
  </div>
`;

export { dateSelect, datePicker };
