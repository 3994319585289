export default (summary, indicatorId) => `
<div class="pull-right">
  <button class="btn btn-primary" id="export" type="button">Export</button>
</div>
<div class="row">
  <table class="table table-striped" id="indicatorTable">
    <thead>
      <tr>
        <th class="pointer" title="The GP practice" data-toggle="tooltip" data-container="body" data-placement="top">Practice</th>
${indicatorId === 0
    ? `
        <th class="pointer" title="The number of patients affected by any one of the prescribing safety indicators across all practices" data-toggle="tooltip" data-container="body" data-placement="top"">Affected patients</th>
        <th class="pointer" title="Proportion of eligible patients across all practices who are affected by at least one indicator" data-toggle="tooltip" data-container="body" data-placement="top"">% of eligible patients affected</th>
        <th class="pointer" title="Proportion of eligible patients across practices in this PCN who are affected by at least one indicator" data-toggle="tooltip" data-container="body" data-placement="top"">PCN Avg (%)</th>
        <th class="pointer" title="The number of patients affected by more than 1 prescribing safety indicator across all practices" data-toggle="tooltip" data-container="body" data-placement="top"">More than one indicator</th>`
    : `
        <th class="pointer" title="The number of patients affected by this indicator across all practices" data-toggle="tooltip" data-container="body" data-placement="top">Affected patients</th>
        <th class="pointer" title="The population of patients across all practices that this indicator could affect" data-toggle="tooltip" data-container="body" data-placement="top">Eligible patients</th>
        <th class="pointer" title="Proportion of the population in focus that are affected by this indicator across all practices" data-toggle="tooltip" data-container="body" data-placement="top">% of eligible patients affected</th>
        <th class="pointer" title="Proportion of the population in focus that are affected by this indicator across all practices in the local PCN" data-toggle="tooltip" data-container="body" data-placement="top">PCN Avg (%)</th>
        <th class="pointer" title="The number of patients affected by more than 1 indicator across all practices" data-toggle="tooltip" data-container="body" data-placement="top">More than one indicator</th>
        <th class="pointer" title="Patients no longer affected by this indicator since the last comparison date across all practices" data-toggle="tooltip" data-container="body" data-placement="top">Successful intervention</th>
        <th class="pointer" title="Patients who remain affected by this indicator since the last comparison date" data-toggle="tooltip" data-container="body" data-placement="top">Action pending</th>
        <th class="pointer" title="Patients affected by this indicator who weren't affected on the last comparison date" data-toggle="tooltip" data-container="body" data-placement="top">New cases</th>
        <th class="pointer" title="Difference in the number of affected patients between last comparison date and current" data-toggle="tooltip" data-container="body" data-placement="top">Trend</th>
`}

      </tr>
    </thead>
    <tbody>
${summary.tableData.map(item => `
      <tr class="no-priority">
        <td class="prominentLink" title="Click to view practice" data-toggle="tooltip" data-container="body" data-placement="left" data-href="/practice/${item.id}/date/${summary.dateId}/comparedWith/${summary.comparisonDateId}/tab/undefined"><a class="prominentLink" href="javascript:void(0)">${item.short_name}</a></td>
        <td>${item.num}</td>
${indicatorId === 0
    ? `     <td>${item.avg}</td>
        <td>${item.ccg}</td>
        <td>${item.patientsMultiple}</td>`
    : `     <td>${item.denom}</td>
        <td>${item.avg}</td>
        <td>${item.ccg}</td>
        <td>${item.patientsMultiple}</td>
        <td>${item.resolved}</td>
        <td>${item.existing}</td>
        <td>${item.new}</td>
        <td>${item.trendValue}</td>
`}
      </tr>
`).join('')}
    </tbody>
  </table>
</div>
`;
