import state from '../state';
import api from '../api';
import { updateActive, updateBreadcrumbs, hideLogin, getDate } from './common';
import messageComponent from '../components/alert';
import filterBarAllCcgsComponent from '../components/filterBarAllCcgs';
import allCcgPage from '../components/allccgs';
import { displayLoader } from '../loader';

const loadingDataPriorToPageLoad = (dateId, indicatorId, sort, dir) =>
  Promise.all([api.datesForDisplay()]).then(([dates]) => {
    if (indicatorId && indicatorId !== 'undefined') {
      state.indicatorId = indicatorId;
    } else {
      state.indicatorId = state.indicatorId || 0;
    }

    if (dateId && dateId !== 'undefined') {
      state.dateId = getDate(dates, dateId);
    } else if (!state.dateId) {
      state.dateId = dates[0]._id;
    }

    if (+state.indicatorId > 0) {
      if (sort && sort !== 'undefined') {
        state.allCcgSingleSort = sort;
      } else if (!state.allCcgSingleSort) {
        state.allCcgSingleSort = 1;
      }
      if (dir && dir !== 'undefined') {
        state.allCcgSingleSortDirection = dir;
      } else if (!state.allCcgSingleSortDirection) {
        state.allCcgSingleSortDirection = 'desc';
      }
      state.allCcgSort = state.allCcgSingleSort;
      state.allCcgSortDirection = state.allCcgSingleSortDirection;
    } else {
      if (sort && sort !== 'undefined') {
        state.allCcgAllSort = sort;
      } else if (!state.allCcgAllSort) {
        state.allCcgAllSort = 1;
      }
      if (dir && dir !== 'undefined') {
        state.allCcgAllSortDirection = dir;
      } else if (!state.allCcgAllSortDirection) {
        state.allCcgAllSortDirection = 'desc';
      }
      state.allCcgSort = state.allCcgAllSort;
      state.allCcgSortDirection = state.allCcgAllSortDirection;
    }

    window.Router.shift(
      `/allccgs/date/${state.dateId}/indicator/${state.indicatorId}/sort/${state.allCcgSort}/dir/${state.allCcgSortDirection}`
    );

    return Promise.all([
      api.visibleIndicators(),
      api.allCcgSummary(state.indicatorId, state.dateId),
      Promise.resolve(dates),
      api.ccgs(),
    ]);
  });

export default (callback, dateId, indicatorId, sort, dir) => {
  displayLoader(
    loadingDataPriorToPageLoad(dateId, indicatorId, sort, dir),
    ([indicators, summary, dates, approvedCCGs]) => {
      hideLogin();
      delete summary.indicator;
      let selectedIndicator;
      indicators.forEach((p) => {
        if (p._id === +state.indicatorId) {
          p.isSelected = true;
          selectedIndicator = p;
          summary.indicator = selectedIndicator;
          state.indicatorName = p.short_name;
        } else {
          delete p.isSelected;
        }
      });

      dates.forEach((d) => {
        if (d._id === +state.dateId) {
          d.isSelected = true;
        } else {
          delete d.isSelected;
        }
        d.shouldDisplay = d.value.indexOf('day') < 0;
      });

      updateActive('tab-allccgs');
      const crumbs = [{ label: 'All CCGs' }];
      updateBreadcrumbs(crumbs);

      let finalHtml = '';

      const approvedCCGsLookup = approvedCCGs.reduce((lookupObject, ccg) => {
        lookupObject[ccg._id] = ccg;
        return lookupObject;
      }, {});

      if (summary.status === 'error') {
        // user not allowed to see this
        finalHtml = messageComponent(summary.message, 'danger');
      } else {
        const filterBarHtml = filterBarAllCcgsComponent(indicators, dates);
        const ccgHtml = allCcgPage(
          summary,
          +state.indicatorId,
          approvedCCGsLookup
        );
        finalHtml = filterBarHtml + ccgHtml;
      }

      document.getElementById('page').innerHTML = finalHtml;

      $('.tooltip').tooltip('hide');
      $('[data-toggle="tooltip"]').tooltip();

      // wire up the bootstrap-select thing
      $('.selectpicker').selectpicker();

      // cause navigation on the select drop down changing
      const indicatorList = document.getElementById('indicatorList');
      if (indicatorList) {
        indicatorList.addEventListener('change', (event) => {
          if (event.target.value !== state.indicatorId) {
            if (+event.target.value === 0) {
              window.Router.navigate(
                `/allccgs/date/${state.dateId}/indicator/${event.target.value}/sort/${state.allCcgAllSort}/dir/${state.allCcgAllSortDirection}`
              );
            } else {
              window.Router.navigate(
                `/allccgs/date/${state.dateId}/indicator/${event.target.value}/sort/${state.allCcgSingleSort}/dir/${state.allCcgSingleSortDirection}`
              );
            }
          }
        });
      }
      const ccgList = document.getElementById('ccgList');
      if (ccgList) {
        ccgList.addEventListener('change', (event) => {
          if (event.target.value !== state.ccgId) {
            window.Router.navigate(
              `/allccgs/date/${state.dateId}/indicator/${state.indicatorId}/sort/${state.allCcgSort}/dir/${state.allCcgSortDirection}`
            );
          }
        });
      }
      const dateList = document.getElementById('dateList');
      if (dateList) {
        dateList.addEventListener('change', (event) => {
          if (event.target.value !== state.dateId) {
            window.Router.navigate(
              `/allccgs/date/${event.target.value}/indicator/${state.indicatorId}/sort/${state.allCcgSort}/dir/${state.allCcgSortDirection}`
            );
          }
        });
      }

      state.tables.indicatorTable = $('#indicatorTable').DataTable({
        info: false, // we don't want showing 1 to n of n
        searching: false, // we don't want a search box
        stateSave: true, // let's remember which page/sorting etc
        order: [[state.allCcgSort, state.allCcgSortDirection]],
        paging: false, // always want all indicators
        scrollY: '50vh',
        scrollCollapse: true,
      });

      $('#indicatorTable').on('order.dt', () => {
        // This will show: "Ordering on column 1 (asc)", for example
        const order = state.tables.indicatorTable.order();
        if (+state.indicatorId === 0) {
          state.allCcgAllSort = order[0][0];
          state.allCcgAllSortDirection = order[0][1];
        } else {
          state.allCcgSingleSort = order[0][0];
          state.allCcgSingleSortDirection = order[0][1];
        }
        state.allCcgSort = order[0][0];
        state.allCcgSortDirection = order[0][1];
        window.Router.shift(
          `/allccgs/date/${state.dateId}/indicator/${state.indicatorId}/sort/${state.allCcgSort}/dir/${state.allCcgSortDirection}`,
          true
        );
      });

      const $exportButton = $('#export');

      $exportButton.on('click', () => {
        window.location =
          +state.indicatorId === 0
            ? `/api/indicator/all/summaryfordate/${state.dateId}/allccgs/sort/${state.allCcgSort}/dir/${state.allCcgSortDirection}/export`
            : `/api/indicator/${state.indicatorId}/summaryfordate/${state.dateId}/allccgs/sort/${state.allCcgSort}/dir/${state.allCcgSortDirection}/export`;
      });

      if (callback) callback();
    }
  ).catch((err) => {
    if (err.message === 'Not logged in') {
      window.Router.navigate('/login');
    }
    if (callback) callback();
  });
};
