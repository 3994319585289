export default isLoggedIn => `
  <div class="container container-small">
    ${isLoggedIn
    ? `
      <div class="btn-toolbar">
        <a class="btn btn-info" href="javascript:void(0)" data-href="/practice">
          <i class="fas fa-arrow-circle-left"></i> Back to SMASH
        </a>
      </div>
      <h1>Reset password</h1>
      <p>
      You appear to already be logged in so you shouldn't need to reset your password like this.
      Please use the 'Change password' link in the drop down in the top right hand corner of the screen.
      </p>
      `
    : `    
      <div class="btn-toolbar">
        <a class="btn btn-info" href="javascript:void(0)" data-href="/login">
          <i class="fas fa-arrow-circle-left"></i> Back to SMASH login
        </a>
      </div>
      <h1>Reset password</h1>
      <form class="signin form-horizontal" id="forgotForm">
        <p>Please enter your email and click 'Send reset link'. We will then email you details of how to change your password.</p>
        <div class="form-group">
          <label class="col-md-2 control-label" for="email">Email</label>
          <div class="col-md-10">
            <input class="form-control" id="email" required="" type="email" name="email" placeholder="Email" autofocus/>
          </div>
        </div>
        <div class="form-group">
          <div class="col-md-12">
            <button class="btn btn-success btn-block" type="submit">Send reset link <i class="fas fa-envelope"></i></button>
          </div>
        </div>
      </form>`}
  </div>
`;
