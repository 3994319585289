const ccgList = ccgs => ((!ccgs || ccgs.length < 2)
  ? ''
  : `
  <select class="form-control inline-select selectpicker" id="ccgList" data-width="auto" title="Select a CCG">
  ${ccgs.sort((a, b) => {
    if (a.short_name > b.short_name) return 1;
    if (a.short_name === b.short_name) return 0;
    return -1;
  }).map(ccg => `
      <option value="${ccg._id}" ${ccg.isSelected ? 'selected' : ''}>${ccg.short_name}</option>`).join('')}
  </select>
`);

const indicatorList = indicators => `
<div class="form-group">
  <select class="form-control inline-select selectpicker" id="indicatorList" data-live-search="true" data-width="auto">
    <option value="0">All Indicators</option>
  ${indicators.sort((a, b) => {
    if (a.short_name > b.short_name) return 1;
    if (a.short_name === b.short_name) return 0;
    return -1;
  }).map(indicator => `
      <option value="${indicator._id}" ${indicator.isSelected ? 'selected' : ''}>${indicator.short_name}</option>`).join('')}
  </select>
</div>
`;

const dateList = (dates, id, name, title) => `
<div class="form-group left-buffer align-bottom">
  <label for="${name}" style="display:block;">${title}:</label>
  <select class="inline-select" id="${id}" name="${name}">
    ${dates
    .filter(date => date.shouldDisplay)
    .map(date => `
      <option value="${date._id}" ${date.isSelected ? 'selected' : ''}>${date.value}</option>
    `).join('')}
  </select>
</div>
`;

export default (ccgs, indicators, dates, comparisonDates, displayComparisonDate) => `
<form id="filterBarCcg" class="form-inline">  
  <div class="form-group">
  ${ccgs && ccgs.length === 1
    ? `<h2>${ccgs[0].short_name}</h2>`
    : ccgList(ccgs)}
  </div>
  ${indicatorList(indicators)}
  ${dateList(dates, 'dateList', 'reportDate', 'Report date')}
  ${displayComparisonDate ? dateList(comparisonDates, 'dateCompareList', 'comparisonDate', 'Comparison date') : ""}
</form>
  `;
