export default isNotYet => `
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close"><span aria-hidden="true" class="close-me">&times;</span></button>
      <h4 class="modal-title" id="myModalLabel">Training status</h4>
    </div>
    <div class="modal-body">      
      <p>For audit purposes we need to know whether you have completed the SMASH training. ${isNotYet
    ? 'You previously told us that you were planning on completing the training. Has this now been done?'
    : 'From our records we are unaware of whether you have completed the training. Can you please let us know?'}
      <div class="radio">
        <label>
          <input type="radio" name="trainingComplete" value="yes">
          I have completed the SMASH training
        </label>
      </div>
      <div class="radio">
        <label>
          <input type="radio" name="trainingComplete" value="notyet">
          I have not completed the SMASH training yet but intend to
        </label>
      </div>
      <div class="radio">
        <label>
          <input type="radio" name="trainingComplete" value="no">
          I do not need to complete the training
        </label>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary">Submit</button>
    </div>
  </div>
</div>
`;
