import { datePicker } from './datePicker';

export default (areDates, startDates, endDates) => `
<div class="panel-body">
  ${areDates
    ? `
    ${datePicker(endDates, 'End date', 'endDate')}
    ${datePicker(startDates, 'Start date', 'startDate')}
    <div class="clearfix"></div>`
    : ''}
  <div id="chart"></div>
</div>
  `;
