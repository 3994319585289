import state from './state';
import { objFromArray } from '../shared/utils';

// credentials: 'same-origin' is usually the default but not in some
// older browsers
const doGet = (url) =>
  fetch(url, { credentials: 'same-origin' }).then((response) => {
    if (response.status === 401) {
      // user not logged in

      // Race conditions if you navigate here - e.g. if 5 simultaneous requests all
      // reject because of auth issues we don't want 5 redirects to /login
      // window.Router.navigate('/login');
      return Promise.reject(new Error('Not logged in'));
    }
    if (response.status === 500) {
      // server might have crashed
      return Promise.reject(new Error('No response from server'));
    }
    if (response.status === 403) {
      return response.json();
    }
    return response.json();
  });

const doPostJson = (url, dataToSend) =>
  fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(dataToSend),
  }).then((response) => {
    if (response.status === 401) {
      // typically dopostjson is not part of multiple requests
      // so it's ok to do redirect here. But first we throw an
      // error so that it can be dealt with by the component
      // e.g. to disply a message that it didn't work
      setTimeout(() => {
        window.Router.navigate('/login');
      }, 3000);
      return Promise.reject(new Error('Not logged in'));
    }
    return response.json();
  });
const doPostForm = (url, formData) =>
  fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'same-origin',
    body: formData,
  }).then((response) => {
    if (response.status === 401) {
      // user not logged in
      // typically dopostjson is not part of multiple requests
      // so it's ok to do redirect here. But first we throw an
      // error so that it can be dealt with by the component
      // e.g. to disply a message that it didn't work
      setTimeout(() => {
        window.Router.navigate('/login');
      }, 3000);
      return Promise.reject(new Error('Not logged in'));
    }
    return response.json();
  });
const doDelete = (url) =>
  fetch(url, {
    method: 'DELETE',
    credentials: 'same-origin',
  }).then((response) => {
    if (response.status === 401) {
      // user not logged in
      setTimeout(() => {
        window.Router.navigate('/login');
      }, 3000);
      return Promise.reject(new Error('Not logged in'));
    }
    return response.json();
  });

const getPractices = () => {
  if (state.practices) return state.practices;
  state.practices = doGet('/api/practices').catch((err) => {
    state.practices = null;
    throw err;
  }); // Otherwise an rejected promise can be assigned to state.practices
  return state.practices;
};

const getAllPractices = () => {
  if (state.allPractices) return state.allPractices;
  state.allPractices = doGet('/api/allPractices').catch((err) => {
    state.allPractices = null;
    throw err;
  }); // Otherwise an rejected promise can be assigned to state.allPractices
  return state.allPractices;
};

const getCCGs = () => {
  if (state.ccgs) return state.ccgs;
  state.ccgs = doGet('/api/ccgs').catch((err) => {
    state.ccgs = null;
    throw err;
  }); // Otherwise an rejected promise can be assigned to state.ccgs
  return state.ccgs;
};

const getAllCCGs = () => {
  if (state.allCcgs) return state.allCcgs;
  state.allCcgs = doGet('/api/allCcgs').catch((err) => {
    state.allCcgs = null;
    throw err;
  }); // Otherwise an rejected promise can be assigned to state.allCcgs
  return state.allCcgs;
};

const getPCNs = () => {
  if (state.pcns) return state.pcns;
  state.pcns = doGet('/api/pcns').catch((err) => {
    state.pcns = null;
    throw err;
  }); // Otherwise an rejected promise can be assigned to state.pcns
  return state.pcns;
};

export default {
  isLoggedIn: () => doGet('/auth/isLoggedIn'),
  getUser: () => doGet('/auth/user'),
  getUserAndUpdate: () => doGet('/auth/userUpdated'), // changes the session user in case user object has updated since login
  login: (formData) => doPostForm('/auth/login', formData),
  register: (formData) => doPostForm('/auth/register', formData),
  forgot: (formData) => doPostForm('/auth/forgot', formData),
  reset: (token) => doGet(`/auth/reset/${token}`),
  resetFromLink: (formData, token) =>
    doPostForm(`/auth/reset/${token}`, formData),
  changePassword: (formData) => doPostForm('/auth/password/change', formData),
  confirmEmail: (token) =>
    doPostJson('/auth/confirmEmail', { token }).catch(() => {}),
  resendConfirmEmail: (email) =>
    doPostJson('/auth/resendConfirmEmail', { email }).catch(() => {}),

  // The catch suppresses the error as we try and parse and empty response as json
  event: (type, href, text, xpath) => {
    console.log(
      'EVENT:',
      type,
      href,
      text,
      xpath,
      Date.now(),
      state.practiceId
    );
  },

  clearPractices: () => {
    delete state.practices;
    delete state.allPractices;
  },

  practices: getPractices,
  allPractices: getAllPractices,
  ccgs: getCCGs,
  allCcgs: getAllCCGs,
  pcns: getPCNs,

  practiceLookup: () => {
    if (state.practiceLookup) return state.practiceLookup;
    state.practiceLookup = getAllPractices()
      .then(objFromArray)
      .catch(() => {
        state.practiceLookup = null;
      });
    return state.practiceLookup;
  },

  ccgLookup: () => {
    if (state.ccgLookup) return state.ccgLookup;
    state.ccgLookup = getAllCCGs()
      .then(objFromArray)
      .catch(() => {
        state.ccgLookup = null;
      });
    return state.ccgLookup;
  },

  summary: (practiceId, dateId, comparisonDateId) =>
    !practiceId || practiceId === '0'
      ? new Promise((resolve) => resolve({ tableData: [], summaryData: {} }))
      : doGet(
          `/api/practice/${practiceId}/summaryfordate/${dateId}/comparedWith/${comparisonDateId}`
        ),

  ccgSummary: (indicatorId, dateId, comparisonDateId, ccgId) =>
    !indicatorId || indicatorId === '0'
      ? doGet(`/api/indicator/all/summaryfordate/${dateId}/ccg/${ccgId}`)
      : doGet(
          `/api/indicator/${indicatorId}/summaryfordate/${dateId}/comparedWith/${comparisonDateId}/ccg/${ccgId}`
        ),

  allCcgSummary: (indicatorId, dateId) =>
    !indicatorId || indicatorId === '0'
      ? doGet(`/api/indicator/all/summaryfordate/${dateId}/allccgs`)
      : doGet(`/api/indicator/${indicatorId}/summaryfordate/${dateId}/allccgs`),

  pcnSummary: (indicatorId, dateId, comparisonDateId, pcnId) =>
    !indicatorId || indicatorId === '0'
      ? doGet(`/api/indicator/all/summaryfordate/${dateId}/pcn/${pcnId}`)
      : doGet(
          `/api/indicator/${indicatorId}/summaryfordate/${dateId}/comparedWith/${comparisonDateId}/pcn/${pcnId}`
        ),

  affected: (practiceId, indicatorId, dateId, comparisonDateId) =>
    doGet(
      `/api/patients/${practiceId}/${dateId}/${comparisonDateId}/${indicatorId}/numerator`
    ),

  existing: (practiceId, indicatorId, dateId, comparisonDateId) =>
    doGet(
      `/api/patients/${practiceId}/${dateId}/${comparisonDateId}/${indicatorId}/existing`
    ),

  resolved: (practiceId, indicatorId, dateId, comparisonDateId) =>
    doGet(
      `/api/patients/${practiceId}/${dateId}/${comparisonDateId}/${indicatorId}/resolved`
    ),

  new: (practiceId, indicatorId, dateId, comparisonDateId) =>
    doGet(
      `/api/patients/${practiceId}/${dateId}/${comparisonDateId}/${indicatorId}/new`
    ),

  multiple: (practiceId, dateId) =>
    doGet(`/api/patients/${practiceId}/multiple/on/${dateId}`),
  carehome: (practiceId, dateId) =>
    doGet(`/api/patients/${practiceId}/carehome/on/${dateId}`),

  datesForDisplay: () => {
    if (state.dates) return state.dates;
    state.dates = doGet('/api/datesForDisplay').catch((err) => {
      state.dates = null;
      throw err;
    }); // Otherwise an rejected promise can be assigned to state.dates
    return state.dates;
  },

  indicators: () => {
    if (state.indicators) return state.indicators;
    state.indicators = doGet('/api/indicators').catch((err) => {
      state.indicators = null;
      throw err;
    }); // Otherwise an rejected promise can be assigned to state.indicators
    return state.indicators;
  },

  practiceIndicators: (practiceId) => {
    if (!state.practiceIndicators) state.practiceIndicators = [];
    if (state.practiceIndicators[practiceId])
      return state.practiceIndicators[practiceId];
    state.practiceIndicators[practiceId] = doGet(
      `/api/indicators/${practiceId}`
    ).catch((err) => {
      state.practiceIndicators[practiceId] = null;
      throw err;
    }); // Otherwise an rejected promise can be assigned to state.practiceIndicators
    return state.practiceIndicators[practiceId];
  },

  allIndicators: () => {
    if (state.allIndicators) return state.allIndicators;
    state.allIndicators = doGet('/api/allIndicators').catch((err) => {
      state.allIndicators = null;
      throw err;
    }); // Otherwise an rejected promise can be assigned to state.allIndicators
    return state.allIndicators;
  },

  visibleIndicators: () => {
    if (state.visibleIndicators) return state.visibleIndicators;
    state.visibleIndicators = doGet('/api/visibleIndicators').catch((err) => {
      state.visibleIndicators = null;
      throw err;
    }); // Otherwise an rejected promise can be assigned to state.visibleIndicators
    return state.visibleIndicators;
  },

  notesDelete: (patientId) => doDelete(`/api/note/${patientId}`),

  notesUpsert: (note) => doPostJson('/api/note', note),

  setTrainingComplete: (trainingComplete) =>
    doPostJson('/api/trainingComplete', { trainingComplete }),

  users: () => doGet('/api/users'),

  user: (email) => doGet(`/api/users/${email}`),

  userCreate: (formData) => doPostForm('/api/users/create', formData),
  userEdit: (email, formData) => doPostForm(`/api/users/${email}`, formData),

  userDelete: (email) => doDelete(`/api/users/${email}`),

  userAuthorise: (email, ccgId, practiceId, placeName) =>
    doPostJson('/api/users/authorise', {
      email,
      ccgId,
      practiceId,
      placeName,
    }).catch(() => {}),
  userReject: (email, ccgId, practiceId, placeName) =>
    doPostJson('/api/users/reject', {
      email,
      ccgId,
      practiceId,
      placeName,
    }).catch(() => {}),

  requestAccess: (ccgId, practiceId, placeName) =>
    doPostJson('/auth/access/request', { ccgId, practiceId, placeName }).catch(
      () => {}
    ),
  revokeAccess: (ccgId, practiceId, placeName) =>
    doPostJson('/auth/access/revoke', { ccgId, practiceId, placeName }).catch(
      () => {}
    ),
  resendRequestEmail: (ccgId, practiceId, placeName) =>
    doPostJson('/auth/access/resend', { ccgId, practiceId, placeName }).catch(
      () => {}
    ),

  updatePractice: (practiceId, practice) =>
    doPostJson(`/api/practices/${practiceId}`, practice),
  updateIndicator: (indicatorId, indicator) =>
    doPostJson(`/api/indicators/${indicatorId}`, indicator),
  updateCCG: (ccgId, ccg) => doPostJson(`/api/ccgs/${ccgId}`, ccg),

  usage: (ccgId) => doGet(`/api/usage/${ccgId}`),

  clearCache: () => doDelete('api/application/cache/contents'),

  cacheStats: () => doGet('api/application/cache/stats'),
};
