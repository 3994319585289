const htmlEncode = value => (value
  ? value
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\//g, '&#x2F;')
  : '');

const usersWithAccessOptions = (indicator, users) => users
  .sort((a, b) => {
    if (a.email < b.email) return -1;
    return a.email === b.email ? 0 : 1;
  })
  .map(user => `
    <option value="${user.email}" ${indicator.usersWithAccess && indicator.usersWithAccess.indexOf(user.email) > -1 ? 'selected' : ''}>${user.email}</option>
  `)
  .join('');
const practicesWithAccessOptions = (indicator, practices) => practices
  .sort((a, b) => {
    if (a.short_name < b.short_name) return -1;
    return a.short_name === b.short_name ? 0 : 1;
  })
  .map(practice => `
    <option value="${practice._id}" ${indicator.practicesWithAccess && indicator.practicesWithAccess.indexOf(practice._id) > -1 ? 'selected' : ''}>${practice.short_name}</option>
  `)
  .join('');

const getIndicatorRows = (indicators, users, practices) => indicators.sort((a, b) => {
  if (a.code < b.code) return -1;
  return a.code === b.code ? 0 : 1;
}).map((indicator, idx) => `
  <tr ${idx % 2 === 0 ? 'style="background-color: #eee"' : ''}>
    <td style="line-height:33px">${indicator._id}</td>
    <td style="line-height:33px">${indicator.code}</td>
    <td>
      <input type="text" class="form-control short_name" data-indicator-id="${indicator._id}" data-original-value="${indicator.short_name || ''}" value="${indicator.short_name || ''}">
    </td>
    <td>
      <input type="text" class="form-control long_name" data-indicator-id="${indicator._id}" data-original-value="${indicator.long_name || ''}" value="${indicator.long_name || ''}">
    </td>
    <td>
      <input name="isVisible" type="checkbox" ${indicator.isVisible ? 'checked' : ''} data-indicator-id="${indicator._id}" data-original-value="${indicator.isVisible}">
    </td>
    <td>
      <select class="form-control selectpicker indicator-usersWithAccess" multiple name="usersWithAccess" data-size="6" data-selected-text-format="count" data-width="150px" data-live-search="true" data-indicator-id="${indicator._id}" data-original-value="${indicator.usersWithAccess.join(',')}">
        ${usersWithAccessOptions(indicator, users)}
      </select>
    </td>
    <td>
      <select class="form-control selectpicker indicator-practicesWithAccess" multiple name="practicesWithAccess" data-size="6" data-selected-text-format="count > 0" data-width="150px" data-live-search="true" data-indicator-id="${indicator._id}" data-original-value="${indicator.practicesWithAccess.join(',')}">
        ${practicesWithAccessOptions(indicator, practices)} 
      </select>
    </td>
    <td>
      <button class="btn btn-warning save-indicator" data-indicator-id="${indicator._id}" disabled>Save</button>
    </td>
  </tr>
  <tr ${idx % 2 === 0 ? 'style="background-color: #eee"' : ''}>
    <td colspan="8" style="border-top-width: 0">
      <div data-indicator-id="${indicator._id}" style="float:left; text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 70vw;"><strong>Summary:</strong> ${htmlEncode(indicator.info)}</div><div><i data-indicator-id="${indicator._id}" class="fa fa-edit" style="cursor:pointer"></i></div>
      <textarea style="display:none" class="form-control info_text" data-indicator-id="${indicator._id}" data-original-value="${htmlEncode(indicator.info)}">${htmlEncode(indicator.info)}</textarea>
    </td>
  </tr>
  `).join('');

const getNewIndicators = indicators => indicators
  .filter(x => !x.info || !x.short_name || !x.long_name);
const getExistingIndicators = indicators => indicators
  .filter(x => x.info && x.short_name && x.long_name);

export default (indicators, users, practices) => {
  // those without info assigned
  const newIndicatorRows = getIndicatorRows(getNewIndicators(indicators), users, practices);
  // those with info assigned
  const indicatorRows = getIndicatorRows(getExistingIndicators(indicators), users, practices);
  const table = rowData => `
  <table class="table table-condensed">
    <thead>
      <tr>
        <th title="The internal mongodb id" data-toggle="tooltip" data-container="body" data-placement="top">Id</th>
        <th title="The indicator code - nationally recognised system" data-toggle="tooltip" data-container="body" data-placement="top">Code</th>
        <th title="Short name for displaying when not much space" data-toggle="tooltip" data-container="body" data-placement="top">Short name</th>
        <th title="Longer name for when there is more space" data-toggle="tooltip" data-container="body" data-placement="top">Long name</th>
        <th title="Whether visible to all or restricted to whitelisted users and practices" data-toggle="tooltip" data-container="body" data-placement="top">Is Visible?</th>
        <th title="If visibility is restricted, these users can still see the indicator" data-toggle="tooltip" data-container="body" data-placement="top">User whitelist</th>
        <th title="If visibility is restricted, these practices can still see the indicator" data-toggle="tooltip" data-container="body" data-placement="top">Practice whitelist</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      ${rowData}
    </tbody>
  </table>
  `;
  return `
    <h1>New indicators</h1>
    <p>The following have recently appeared in the data but haven't yet been assigned all of: short name, long name, and an evidence summary.</p>
    ${table(newIndicatorRows)}
    <hr>
    <h1>Existing indicators</h1>
    <p>The following already appear in SMASH.</p>
    ${table(indicatorRows)}
  `;
};
