import state from '../state';
import api from '../api';
import {
  showAlert,
  hideAlert,
  showLogin,
  hideLogin,
  updateActive,
  updateBreadcrumbs,
  showMessage,
} from './common';
import loginComponent from '../components/auth/login';
import forgotComponent from '../components/auth/forgot';
import registerComponent from '../components/auth/register';
import confirmEmailComponent from '../components/auth/confirmEmail';
import resetComponent from '../components/auth/reset';
import changePasswordComponent from '../components/auth/changePassword';
import {
  accountComponent,
  practiceActionTable,
  ccgActionTable,
} from '../components/auth/account';
import revokeAccessModalComponent from '../components/auth/revokeAccessModal';
import requestAccessModalComponent from '../components/auth/requestAccessModal';
import practiceListComponent from '../components/admin/practiceList';
import { displayLoader } from '../loader';
import modal from './modalController';
import CCGList from '../components/admin/ccgList';

const disableFormButton = (formId) =>
  $(`#${formId} button`).attr('disabled', true);
const enableFormButton = (formId) =>
  $(`#${formId} button`).attr('disabled', false);
const showFormButtonLoading = (formId) =>
  $(`#${formId} button i`).addClass('fa-sync fa-spin');
const hideFormButtonLoading = (formId) =>
  $(`#${formId} button i`).removeClass('fa-sync fa-spin');
const disableForm = (formId) => {
  disableFormButton(formId);
  showFormButtonLoading(formId);
};
const enableForm = (formId) => {
  enableFormButton(formId);
  hideFormButtonLoading(formId);
};

export function login(callback) {
  // To avoid a flash of the bare dashboard (e.g. side menu etc)
  // we call this now which hides the main dashboard and sets up
  // the page ready for the login stuff
  showLogin();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionExpired = urlParams.get('sessionExpired');

  if (sessionExpired) {
    showAlert(
      'Sorry - your session has expired. Please log in again',
      'danger'
    );
  }

  displayLoader(api.isLoggedIn(), (response) => {
    const loginHtml = loginComponent(response.isLoggedIn, response.isJustCcg);
    document.getElementById('loginContent').innerHTML = loginHtml;

    // form post login
    $('#loginForm').submit((e) => {
      e.preventDefault();

      // prevent click twice by diabling
      disableForm('loginForm');

      const formdata = $('#loginForm').serialize();
      api
        .login(formdata)
        .then((resp) => {
          if (resp.status === 'success') {
            hideLogin();
            const doRouting = () => {
              if (
                !state.initialPath ||
                state.initialPath === '' ||
                state.initialPath === 'login' ||
                state.initialPath.indexOf('confirm') > -1
              ) {
                if (
                  resp.roles &&
                  resp.roles.includes('pan-ccg') &&
                  (!resp.practices || resp.practices.length === 0)
                ) {
                  // nothing selected and this user has no practices and is ccg so load the ccg
                  // page first
                  window.Router.navigate('/allccgs');
                } else if (
                  resp.roles &&
                  resp.roles.includes('ccg') &&
                  (!resp.practices || resp.practices.length === 0)
                ) {
                  // nothing selected and this user has no practices and is ccg so load the ccg
                  // page first
                  window.Router.navigate('/ccg');
                } else {
                  window.Router.navigate('/practice');
                }
              } else {
                window.Router.navigate(`/${state.initialPath}`);
              }
            };
            // redirect to initial request
            if (resp.trainingComplete && resp.trainingComplete === 'notyet') {
              modal.showTrainingCompleteModal(resp.trainingComplete, doRouting);
            } else {
              doRouting();
            }
          } else {
            showAlert(resp.message, 'danger');
          }
        })
        .catch(() => {
          showAlert('Some sort of error occurred', 'danger');
        })
        .then(() => {
          // re-enable
          enableForm('loginForm');
        });
    });
  })
    .catch(() => {
      showAlert('Some sort of error occurred', 'danger');
    })
    .then(() => {
      // Really should be finally - but not enough browser support
      if (callback) callback();
    });
}

export function forgot(callback) {
  displayLoader(api.isLoggedIn(), (response) => {
    showLogin();

    const forgotHtml = forgotComponent(response.isLoggedIn);
    document.getElementById('loginContent').innerHTML = forgotHtml;

    // form post login
    $('#forgotForm').submit((e) => {
      e.preventDefault();

      // prevent click twice by diabling
      disableForm('forgotForm');

      const formdata = $('#forgotForm').serialize();
      api
        .forgot(formdata)
        .then((resp) => {
          if (resp.status === 'success') {
            document.getElementById('loginContent').innerHTML = showMessage(
              'Email sent',
              resp.message,
              '/login',
              'Back to SMASH login'
            );
          } else {
            showAlert(resp.message, 'danger');
          }
        })
        .catch(() => {
          showAlert('Some sort of error occurred', 'danger');
        })
        .then(() => {
          // re-enable
          enableForm('forgotForm');
        });
    });

    if (callback) callback();
  })
    .catch(() => {
      showAlert('Some sort of error occurred', 'danger');
    })
    .then(() => {
      // Really should be finally - but not enough browser support
      if (callback) callback();
    });
}

export function reset(callback, token) {
  displayLoader(api.reset(token), (response) => {
    const resetHtml = resetComponent(response);
    document.getElementById('loginContent').innerHTML = resetHtml;

    showLogin();

    // form post login
    $('#resetPasswordForm').submit((e) => {
      e.preventDefault();

      // prevent click twice by diabling
      disableForm('resetPasswordForm');

      const formdata = $('#resetPasswordForm').serialize();
      api
        .resetFromLink(formdata, token)
        .then((resp) => {
          if (resp.status === 'success') {
            hideLogin();
            window.Router.navigate('/practice');
          } else {
            showAlert(resp.message, 'danger');
          }
        })
        .catch(() => {
          showAlert('Some sort of error occurred', 'danger');
        })
        .then(() => {
          // re-enable
          enableForm('resetPasswordForm');
        });
    });

    if (callback) callback();
  }).catch((e) => {
    console.log(e);
    if (callback) callback();
  });
}

const createElementFromHTML = (htmlString) => {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  return div.firstChild;
};

export function account(callback) {
  displayLoader(
    Promise.all([
      api.allPractices(),
      api.allCcgs(),
      api.getUserAndUpdate(),
      api.cacheStats(),
    ]),
    ([allPractices, ccgs, user, cacheStats]) => {
      if (user) {
        hideLogin();

        updateActive('link-account');
        const crumbs = [{ label: 'Account' }];
        updateBreadcrumbs(crumbs);
        const accountHtml = accountComponent(
          allPractices,
          ccgs,
          user,
          cacheStats
        );
        document.getElementById('page').innerHTML = accountHtml;

        const redrawCCGTable = (newUser) => {
          const tableHtml = ccgActionTable(ccgs, newUser);
          const tableNode = createElementFromHTML(tableHtml);
          const ccgActionTableEl = document.getElementById('ccg-action-table');
          ccgActionTableEl.parentNode.replaceChild(tableNode, ccgActionTableEl);
        };

        const redrawPracticeTable = (newUser) => {
          const tableHtml = practiceActionTable(ccgs, allPractices, newUser);
          const tableNode = createElementFromHTML(tableHtml);
          const practiceActionTableEl = document.getElementById(
            'practice-action-tables'
          );
          practiceActionTableEl.parentNode.replaceChild(
            tableNode,
            practiceActionTableEl
          );
        };

        $('#accountComponent').on('click', '.request-access', (e) => {
          const { ccgId, practiceId, placeName, authorise } =
            e.currentTarget.dataset;

          const isPractice = !!practiceId;
          const itemId = ccgId || practiceId;

          const apiPromise = authorise ? api.requestAccess : api.revokeAccess;

          const accessModalComponent = authorise
            ? requestAccessModalComponent
            : revokeAccessModalComponent;

          // launch modal
          $('#modal')
            .html(accessModalComponent(placeName, isPractice, itemId))
            .modal();

          const modalClick = (modalEvent) => {
            if (modalEvent.target.dataset.id) {
              // request/revoke access
              const row = ccgId
                ? $(`.request-access[data-ccg-id="${ccgId}"]`).parent().parent()
                : $(`.request-access[data-practice-id="${practiceId}"]`)
                    .parent()
                    .parent();
              $('#modal').modal('hide');
              row.addClass('removing');
              apiPromise(ccgId, practiceId, placeName)
                .then((resp) => {
                  if (resp.status === 'success') {
                    // redraw table
                    if (ccgId) redrawCCGTable(resp.user);
                    else redrawPracticeTable(resp.user);
                    showAlert(resp.message, 'success');
                  } else {
                    showAlert(resp.message, 'danger');
                  }
                })
                .catch(() => {
                  showAlert('Might not have worked', 'danger');
                  row.removeClass('removing');
                });
            } else if (modalEvent.target.classList.contains('close-me')) {
              $('#modal').modal('hide');
            }
          };

          const modalElement = document.getElementById('modal');
          modalElement.onclick = modalClick;
        });

        $('#accountComponent').on('click', '.resend-email', (e) => {
          const { ccgId, practiceId, placeName } = e.currentTarget.dataset;

          const row = ccgId
            ? $(`.resend-email[data-ccg-id="${ccgId}"]`).parent().parent()
            : $(`.resend-email[data-practice-id="${practiceId}"]`)
                .parent()
                .parent();

          row.addClass('removing');
          api
            .resendRequestEmail(ccgId, practiceId, placeName)
            .then((resp) => {
              if (resp.status === 'success') {
                row.removeClass('removing');
                showAlert(resp.message, 'success');
              } else {
                showAlert(resp.message, 'danger');
              }
            })
            .catch(() => {
              showAlert('Might not have worked', 'danger');
              row.removeClass('removing');
            });
        });

        $('#accountComponent').on('click', '.clear-cache', () => {
          api
            .clearCache()
            .then((resp) => {
              if (resp.status === 'success') {
                showAlert(resp.message, 'success');
              } else {
                showAlert(resp.message, 'danger');
              }
            })
            .catch((e) => {
              showAlert(
                `Something went wrong with the endpoint: ${e}`,
                'danger'
              );
            });
        });

        if (callback) callback();
      } else {
        window.Router.navigate('/login');
      }
    }
  )
    .catch(() => {
      showAlert('Some sort of error occurred', 'danger');
    })
    .then(() => {
      // Really should be finally - but not enough browser support
      if (callback) callback();
    });
}

export function changePassword(callback) {
  displayLoader(api.isLoggedIn(), (response) => {
    if (response.isLoggedIn) {
      hideLogin();

      updateActive('link-change-password');
      const crumbs = [{ label: 'Change password' }];
      updateBreadcrumbs(crumbs);

      const changePasswordHtml = changePasswordComponent();
      document.getElementById('page').innerHTML = changePasswordHtml;
      // form post login
      $('#changePasswordForm').submit((e) => {
        e.preventDefault();

        // prevent double click by disabling form button
        disableForm('changePasswordForm');

        const formdata = $('#changePasswordForm').serialize();
        api
          .changePassword(formdata)
          .then((resp) => {
            if (resp.status === 'success') {
              document.getElementById('page').innerHTML = showMessage(
                'Password changed',
                resp.message,
                '/practice',
                'Back to SMASH'
              );
            } else {
              showAlert(resp.message, 'danger');
            }
          })
          .catch(() => {
            showAlert('Some sort of error occurred', 'danger');
          })
          .then(() => {
            // prevent double click by disabling form button
            enableForm('changePasswordForm');
          });
      });

      if (callback) callback();
    } else {
      window.Router.navigate('/login');
    }
  })
    .catch(() => {
      showAlert('Some sort of error occurred', 'danger');
    })
    .then(() => {
      // Really should be finally - but not enough browser support
      if (callback) callback();
    });
}

const refreshForm = () => {
  const isCCGChecked = document.querySelector('[name="isCCG"]:checked');
  const ccgId = document.querySelector('[name="ccgs"]').value;
  const practiceId = document.querySelector('[name="practices"]').value;
  const signupOptionMessage = document.getElementById('signupOptionMessage');

  signupOptionMessage.innerHTML = '';

  // display a clarification message based on what option a user selects
  if (isCCGChecked && isCCGChecked.value !== 'false') {
    signupOptionMessage.innerHTML =
      isCCGChecked.value === 'all'
        ? 'Almost all users should select either of the first two options. If you want to use SMASH to view at-risk patients, please select the first option above. If you want to compare practices within a CCG please select the second option.'
        : 'You will not see patient level data. If you want to use SMASH to view at-risk patients, please select the first option above.';
  }

  // display ccg drop down if isCCG is checked and not pan-ccg user
  if (isCCGChecked && isCCGChecked.value !== 'all') {
    $('#ccgsGroup').show();
  } else {
    $('#ccgsGroup').hide();
  }

  // remove ccg required field if pan-ccg user
  if (isCCGChecked && isCCGChecked.value === 'all') {
    $('[name="ccgs"]').prop('required', false);
  } else {
    $('[name="ccgs"]').prop('required', true);
  }

  // display practice drop down if not ccg user and ccg selected
  if (isCCGChecked && isCCGChecked.value === 'false' && ccgId) {
    $('[name="practices"]').prop('required', true);
    $('#practicesGroup').show();
  } else {
    $('[name="practices"]').prop('required', false);
    $('#practicesGroup').hide();
  }

  // display role drop down if practice selected
  if (isCCGChecked && isCCGChecked.value === 'false' && practiceId) {
    $('[name="practiceRole"]').prop('required', true);
    $('#role').show();
  } else {
    $('[name="practiceRole"]').prop('required', false);
    $('#role').hide();
  }

  // display training complete if role specifies it
  // display declaration if role selected
  const practiceRole = document.querySelector('[name="practiceRole"]').value;
  if (isCCGChecked && isCCGChecked.value === 'false' && practiceRole) {
    $('[name="selfDeclaration"]').prop('required', true);
    $('#selfDeclaration').show();

    if (
      document.querySelector('[name="practiceRole"] :checked').dataset
        .trainingRequired
    ) {
      $('[name="trainingComplete"]').prop('required', true);
      $('#training').show();
    } else {
      $('[name="trainingComplete"]').prop('required', false);
      $('#training').hide();
    }
  } else {
    $('[name="trainingComplete"]').prop('required', false);
    $('#training').hide();
    $('[name="selfDeclaration"]').prop('required', false);
    $('#selfDeclaration').hide();
  }
};

export function register(callback) {
  hideAlert();
  displayLoader(
    Promise.all([api.allPractices(), api.allCcgs(), api.isLoggedIn()]),
    ([allPractices, ccgs, { isLoggedIn }]) => {
      showLogin();

      const registerHtml = registerComponent(isLoggedIn, ccgs, allPractices);
      document.getElementById('loginContent').innerHTML = registerHtml;

      // wire up tooltips
      $('.tooltip').tooltip('hide');
      $('[data-toggle="tooltip"]').tooltip();

      // wire up the bootstrap-select thing
      $('.selectpicker').selectpicker();

      $('[name="practiceRole"').on('change', () => {
        refreshForm();
      });

      $('[name="isCCG"]').on('change', () => {
        // reset CCG select list to default everytime isCCG gets changed
        const ccgSelect = document.querySelector('[name="ccgs"]');
        ccgSelect.innerHTML = CCGList(ccgs, null);
        $('.selectpicker[name="ccgs"]').selectpicker('refresh');

        // reset practices select list everytime isCCG gets changed
        const practiceSelect = document.querySelector('[name="practices"]');
        practiceSelect.innerHTML = practiceListComponent(
          ccgs,
          allPractices,
          null
        );
        $('.selectpicker[name="practices"]').selectpicker('refresh');

        // reset role evertime isCCG gets changed
        document.querySelector('[name="practiceRole"]').innerHTML =
          document.querySelector('[name="practiceRole"]').innerHTML;
        $('.selectpicker[name="practiceRole"]').selectpicker('refresh');

        // reset self declartion and training complete everytime isCCG gets changed
        $('input[name=trainingComplete]').prop('checked', false);
        $('input[name=selfDeclaration]').prop('checked', false);

        refreshForm();
      });

      $('[name="ccgs"]').on('change', () => {
        const ccgId = document.querySelector('[name="ccgs"]').value;
        const practiceSelect = document.querySelector('[name="practices"]');
        practiceSelect.innerHTML = practiceListComponent(ccgs, allPractices, {
          ccgs: [ccgId],
          practices: [],
        });
        // wire up the bootstrap-select thing
        $('.selectpicker[name="practices"]').selectpicker('refresh');
        refreshForm();
      });

      $('[name="practices"]').on('change', () => {
        refreshForm();
      });

      // form post login
      $('#registerForm').submit((e) => {
        e.preventDefault();

        // prevent double click by disabling form button
        disableForm('registerForm');

        const formdata = $('#registerForm').serialize();
        api
          .register(formdata)
          .then((resp) => {
            if (resp.status === 'success') {
              document.getElementById('loginContent').innerHTML = showMessage(
                'Registration successful',
                resp.message,
                '/login',
                'Back to SMASH login'
              );
            } else if (resp.status === 'partial-success') {
              document.getElementById('loginContent').innerHTML = showMessage(
                'Registration successful',
                resp.message,
                '/login',
                'Back to SMASH login'
              );
              showAlert(resp.warning, 'warning');
            } else {
              showAlert(resp.message, 'danger');
            }
          })
          .catch(() => {
            showAlert('Some sort of error occurred', 'danger');
          })
          .then(() => {
            // re-enable form button
            enableForm('registerForm');
          });
      });

      if (callback) callback();
    }
  )
    .catch(() => {
      showAlert('Some sort of error occurred', 'danger');
    })
    .then(() => {
      // Really should be finally - but not enough browser support
      if (callback) callback();
    });
}

export function confirmEmail(callback, token) {
  displayLoader(api.confirmEmail(token), (response) => {
    showLogin();

    const confirmEmailtHtml = confirmEmailComponent(response);
    document.getElementById('loginContent').innerHTML = confirmEmailtHtml;

    if (callback) callback();
  })
    .catch(() => {
      showAlert('Some sort of error occurred', 'danger');
    })
    .then(() => {
      // Really should be finally - but not enough browser support
      if (callback) callback();
    });
}

export function resendConfirmEmail(callback, email) {
  hideAlert();
  displayLoader(api.resendConfirmEmail(email), (response) => {
    showLogin();

    document.getElementById('loginContent').innerHTML = showMessage(
      'Email successfully resent',
      response.message,
      '/login',
      'Back to SMASH login'
    );

    if (callback) callback();
  })
    .catch(() => {
      showAlert('Some sort of error occurred', 'danger');
    })
    .then(() => {
      // Really should be finally - but not enough browser support
      if (callback) callback();
    });
}
