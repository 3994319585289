import practiceList from './practiceList';
import CCGList from './ccgList';

export default (ccgs, practices, user, showRoles) => {
  const practiceOptions = practiceList(ccgs, practices, user);

  const ccgOptions = CCGList(ccgs, user);

  return `
    <input type="hidden" id="dontSendEmail" name="dontSendEmail" />
    <div class="form-group">
      <label for="name" class="col-sm-3 control-label">Full name:</label>
      <div class="col-sm-9">
        <input class="form-control" type="text" name="name" placeholder="Full name" required autofocus ${user ? `value="${user.name}"` : ''}/>
      </div>
    </div>
    <div class="form-group">
      <label for="email" class="col-sm-3 control-label">Email:</label>
      <div class="col-sm-9">
        <input class="form-control" type="text" name="email" placeholder="Email address" required ${user ? `value="${user.email}"` : ''}/>
        <input class="form-control" type="hidden" name="originalEmail" ${user ? `value="${user.email}"` : ''}/>
      </div>
    </div>
    ${user
    ? ''
    : `
      <div class="form-group">
        <label for="password" class="col-sm-3 control-label">Password:</label>
        <div class="col-sm-9">
          <input class="form-control" type="password" name="password" placeholder="Password" required />
        </div>
      </div>
    `}
    ${showRoles
    ? ''
    : `
      <div class="form-group">
        <label for="isCCG" class="col-sm-3 control-label">I want to...</label>
        <div class="col-sm-9">
          <div class="radio">
            <label>
              <input type="radio" name="isCCG" value="false" required>
              view NHS numbers of at risk patients in one or more practices
            </label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" name="isCCG" value="true" required>
              view high level data for all practices in a CCG
            </label>
          </div>
          <div class="radio">
            <label>
              <input type="radio" name="isCCG" value="all" required>
              view high level data for all ccgs in Greater Manchester
            </label>
          </div>
          <div style="color:red;font-style: italic; padding-top:0.5em;" id="signupOptionMessage">
          </div>
        </div>
      </div>
    `}
    <div class="form-group" id="ccgsGroup" style="display:none">
      <label for="ccgs" class="col-sm-3 control-label">CCG:</label>
      <div class="col-sm-9">
        <select class="form-control selectpicker" data-size="6" name="ccgs" ${showRoles ? 'multiple' : 'required'} title="Select a CCG">
          ${ccgOptions}
        </select>
      </div>
    </div>
    <div class="form-group" id="practicesGroup" style="display:none">
      <label for="practices" class="col-sm-3 control-label">Practice(s):</label>
      <div class="col-sm-9">
        <select class="form-control selectpicker" name="practices" data-container="#container" data-live-search="true" multiple ${showRoles ? 'title="Select one or more practices"' : 'required title="Now select one or more practices"'}>
          ${showRoles ? practiceOptions : ''}
        </select>
        <p style="color:blue;font-style: italic; padding-top:0.5em;" id="practiceDropdownMessage">
        Note: You can select one or multiple practices 
         </p>
      </div>
    </div>
    ${showRoles
    ? `
      <div class="form-group">
        <div class="col-sm-offset-3 col-sm-9">
          <div class="checkbox">
            <label>
              <input name="isAdmin" type="checkbox" ${(user && user.roles.indexOf('admin') > -1) ? 'checked' : ''} />Is Admin
            </label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-offset-3 col-sm-9">
          <div class="checkbox">
            <label>
              <input name="isCCG" type="checkbox" ${(user && user.roles.indexOf('ccg') > -1) ? 'checked' : ''}/>Is CCG user
            </label>
          </div>
        </div>
      </div>`
    : `
    <div class="form-group" id="role" style="display:none">
      <label for="practiceRole" class="col-sm-3 control-label">Role:</label>
      <div class="col-sm-9">
        <select class="form-control selectpicker" data-container="#container" name="practiceRole" required title="Select your role">
          <optgroup label="Pharmacy">
            <option data-training-required="true" value="Practice Pharmacist">Practice Pharmacist</option>
            <option data-training-required="true" value="Pharmacy Technician">Pharmacy Technician</option>
            <option value="CCG Pharmacist">CCG Pharmacist</option>
          </optgroup>
          <optgroup label="Clinical">
            <option value="GP">GP</option>
            <option value="Practice Nurse">Practice Nurse</option>
            <option value="ANP">ANP</option>
            <option value="Practice HCA">Practice HCA</option>
            <option value="Other – clinical">Other – clinical</option>
          </optgroup>
          <optgroup label="Admin / Manager">
            <option value="Practice Manager">Practice Manager</option>
            <option value="Practice Admin Staff">Practice Admin Staff</option>
            <option value="Other – Admin">Other – Admin</option>
          </optgroup>
        </select>
      </div>
    </div>
    <div class="form-group" id="training" style="display:none">
      <label for="trainingComplete" class="col-sm-3 control-label">
        <span 
          class="glyphicon glyphicon-info-sign" 
          style="color:#4169e1;cursor:pointer" 
          aria-hidden="true"
          title="If you are a practice pharmacist or pharmacy technician you probably require SMASH training"
          data-toggle="tooltip" 
          data-container="body" 
          data-placement="top">
        </span>  
        Training
      </label>
      <div class="col-sm-9">
        <div class="radio">
          <label>
            <input type="radio" name="trainingComplete" value="yes" required>
            I have completed the SMASH training
          </label>
        </div>
        <div class="radio">
          <label>
            <input type="radio" name="trainingComplete" value="notyet" required>
            I have not completed the SMASH training yet but intend to
          </label>
        </div>
        <div class="radio">
          <label>
            <input type="radio" name="trainingComplete" value="no" required>
            I do not need to complete the training
          </label>
        </div>
      </div>
    </div>
    <div class="form-group" id="selfDeclaration" style="display:none">
      <label for="selfDeclaration" class="col-sm-3 control-label">Declaration</label>
      <div class="col-sm-9">
        <div class="checkbox">
          <label>
            <input name="selfDeclaration" type="checkbox" required />I confirm that I have permission to access patient level records at this/these practices (this will also be checked with the practice manager). I confirm my usage of SMASH is for direct patient care.
          </label>
        </div>
      </div>
    </div>`
}
    
  `;
};
