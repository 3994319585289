import state from '../state';
import api from '../api';
import {
  updateActive,
  updateBreadcrumbs,
  hideLogin,
  getDate,
  getComparisonDate,
  showAlert,
} from './common';
import { displayPracticeIndicatorChart } from '../charts';
import modal from './modalController';
import messageComponent from '../components/alert';
import filterBarPracticeIndicatorComponent from '../components/filterBarPracticeIndicator';
import practiceIndicatorComponent from '../components/practiceIndicator';
import { displayLoader } from '../loader';

export default (
  callback,
  practiceId,
  dateId,
  comparisonDateId,
  indicatorId,
  reportType,
  chartOrTable,
  tabId
) => {
  state.reportType = reportType || 'affected';

  // get dates here

  api.datesForDisplay().then((dates) => {
    const comparisonDates = JSON.parse(JSON.stringify(dates.slice(1)));
    dateId = getDate(dates, dateId);
    comparisonDateId = getDate(dates, comparisonDateId, () =>
      getComparisonDate(comparisonDates, state.dateId)
    );

    displayLoader(
      Promise.all([
        api.practices(),
        api[state.reportType](
          practiceId,
          indicatorId,
          dateId,
          comparisonDateId
        ),
        api.practiceIndicators(practiceId),
      ]),
      ([practices, summary, indicators]) => {
        hideLogin();

        state.practiceId = practiceId;
        state.dateId = dateId;
        state.indicatorId = indicatorId;
        state.comparisonDateId = comparisonDateId;
        state.practiceIndicatorTabId = tabId || state.practiceIndicatorTabId;
        state.practiceIndicatorChartOrTable = chartOrTable || 'table';

        window.Router.shift(
          `/practice/${state.practiceId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/indicator/${state.indicatorId}/${state.reportType}/show/${state.practiceIndicatorChartOrTable}/tab/${state.practiceIndicatorTabId}`
        );

        const showTable = state.practiceIndicatorChartOrTable === 'table';

        let selectedIndicator;
        indicators.forEach((i) => {
          if (i._id === +state.indicatorId) {
            i.isSelected = true;
            selectedIndicator = i;
          } else {
            delete i.isSelected;
          }
        });

        const patientLookup = {};
        if (summary.patients) {
          summary.patients.forEach((p) => {
            if (!p.indicatorNotes) p.indicatorNotes = [];
            p.indicators.forEach((indicator) => {
              indicator.note = '';
              p.indicatorNotes.forEach((note) => {
                if (note.id === indicator.id) {
                  indicator.note = note.note;
                }
              });
            });
            patientLookup[p.id] = p;
          });
        }

        practices.forEach((p) => {
          if (p._id === state.practiceId) {
            state.practiceName = p.short_name;
          }
        });

        dates.forEach((d) => {
          if (d._id === +state.dateId) {
            d.isSelected = true;
          } else {
            delete d.isSelected;
          }
          d.shouldDisplay = d.value.indexOf('day') < 0;
        });

        comparisonDates.forEach((c) => {
          if (c._id === +state.comparisonDateId) {
            c.isSelected = true;
          }
          c.shouldDisplay = c._id < +state.dateId;
        });

        updateActive('tab-practice');
        const crumbs = [
          {
            label: state.practiceName,
            path: `/practice/${practiceId}/date/${dateId}/comparedWith/${comparisonDateId}/tab/${state.practiceTabId}`,
          },
          { label: selectedIndicator.short_name },
        ];
        updateBreadcrumbs(crumbs);

        const filterBarHtml = filterBarPracticeIndicatorComponent(
          indicators,
          state.reportType,
          dates,
          comparisonDates
        );

        const practiceIndicatorHtml =
          summary.status === 'error'
            ? messageComponent(summary.message, 'danger')
            : practiceIndicatorComponent(
                summary,
                reportType,
                showTable,
                state.practiceIndicatorTabId,
                selectedIndicator.info
              );

        document.getElementById('page').innerHTML =
          filterBarHtml + practiceIndicatorHtml;

        $('.tooltip').tooltip('hide');
        $('[data-toggle="tooltip"]').tooltip();

        // wire up the bootstrap-select thing
        $('.selectpicker').selectpicker();

        const $exportButton = $('#practiceIndicatorExport');
        $exportButton.on('click', () => {
          showAlert(
            'Once the file is downloaded it will not be updated when there are any changes to any patient or when someone else adds a note. To get the up-to-date data always visit this page.',
            'warning'
          );

          const exportUrl =
            reportType === 'affected'
              ? `/api/patients/${practiceId}/${dateId}/${comparisonDateId}/${indicatorId}/numerator/sort/${state.patientsListSort}/dir/${state.patientsListSortDirection}/export`
              : `/api/patients/${practiceId}/${dateId}/${comparisonDateId}/${indicatorId}/${reportType}/sort/${state.patientsListSort}/dir/${state.patientsListSortDirection}/export`;

          window.location = exportUrl;
        });
        // cause navigation on the select drop down changing
        document
          .getElementById('indicatorList')
          .addEventListener('change', (event) => {
            if (event.target.value !== state.indicatorId) {
              window.Router.navigate(
                `/practice/${state.practiceId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/indicator/${event.target.value}/${state.reportType}/show/${state.practiceIndicatorChartOrTable}/tab/${state.practiceIndicatorTabId}`
              );
            }
          });

        document
          .getElementById('typeList')
          .addEventListener('change', (event) => {
            if (event.target.value !== state.reportType) {
              window.Router.navigate(
                `/practice/${state.practiceId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/indicator/${state.indicatorId}/${event.target.value}/show/${state.practiceIndicatorChartOrTable}/tab/${state.practiceIndicatorTabId}`
              );
            }
          });

        document
          .getElementById('dateList')
          .addEventListener('change', (event) => {
            if (event.target.value !== state.dateId) {
              window.Router.navigate(
                `/practice/${state.practiceId}/date/${event.target.value}/comparedWith/${state.comparisonDateId}/indicator/${state.indicatorId}/${state.reportType}/show/${state.practiceIndicatorChartOrTable}/tab/${state.practiceIndicatorTabId}`
              );
            }
          });

        document
          .getElementById('dateCompareList')
          .addEventListener('change', (event) => {
            if (event.target.value !== state.comparisonDateId) {
              window.Router.navigate(
                `/practice/${state.practiceId}/date/${state.dateId}/comparedWith/${event.target.value}/indicator/${state.indicatorId}/${state.reportType}/show/${state.practiceIndicatorChartOrTable}/tab/${state.practiceIndicatorTabId}`
              );
            }
          });

        state.tables.patientTable = $('#patientTable').DataTable({
          info: false, // we don't want showing 1 to n of n
          searching: false, // we don't want a search box
          stateSave: true, // let's remember which page/sorting etc
          paging: false, // always want all indicators
          order: [[1, 'desc']],
          columnDefs: [
            { type: 'no-indicators', targets: 1 },
            { type: 'any-date', targets: 2 },
          ],
          scrollY: '50vh',
          scrollCollapse: true,
        });

        $('#patientTable').on('order.dt', () => {
          const order = state.tables.patientTable.order();
          state.patientsListSort = order[0][0];
          state.patientsListSortDirection = order[0][1];
        });

        state.tables.trendTable = $('#trendTable').DataTable({
          info: false, // we don't want showing 1 to n of n
          searching: false, // we don't want a search box
          stateSave: true, // let's remember which page/sorting etc
          order: [[0, 'desc']],
          paging: false, // always want all indicators
          columnDefs: [{ type: 'any-date', targets: 0 }],
          scrollY: '50vh',
          scrollCollapse: true,
        });

        $('li a[role="tab"]').on('shown.bs.tab', (e) => {
          state.practiceIndicatorTabId = $(e.currentTarget).data('id');

          if (+state.practiceIndicatorTabId === 1) {
            $('#practiceIndicatorExport').show();
          } else {
            $('#practiceIndicatorExport').hide();
          }
          if (+state.practiceIndicatorTabId === 2) {
            $('#toggleChart').show();
          } else {
            $('#toggleChart').hide();
          }
          window.Router.shift(
            `/practice/${state.practiceId}/date/${state.dateId}/comparedWith/${state.comparisonDateId}/indicator/${state.indicatorId}/${state.reportType}/show/${state.practiceIndicatorChartOrTable}/tab/${state.practiceIndicatorTabId}`,
            true
          );

          if (e.currentTarget.id === 'tableTab') {
            // ensure headers display correctly on hidden tab
            state.tables.patientTable.columns.adjust().draw(false);
          } else if (e.currentTarget.id === 'trendTableTab') {
            // ensure headers display correctly on hidden tab
            state.tables.trendTable.columns.adjust().draw(false);
          }
        });

        const addStartEndDateListeners = () => {
          document
            .getElementById('endDate')
            .addEventListener('change', (event) => {
              if (event.target.value !== state.practiceIndicatorEndDate) {
                state.practiceIndicatorEndDate = event.target.value;
                displayPracticeIndicatorChart(
                  summary.chartData,
                  state.practiceIndicatorStartDate,
                  state.practiceIndicatorEndDate
                );
                addStartEndDateListeners();
              }
            });

          document
            .getElementById('startDate')
            .addEventListener('change', (event) => {
              if (event.target.value !== state.practiceIndicatorStartDate) {
                state.practiceIndicatorStartDate = event.target.value;
                displayPracticeIndicatorChart(
                  summary.chartData,
                  state.practiceIndicatorStartDate,
                  state.practiceIndicatorEndDate
                );
                addStartEndDateListeners();
              }
            });
        };

        modal.initialize(patientLookup);

        // add chart
        if (state.practiceIndicatorChartOrTable === 'chart') {
          setTimeout(() => {
            displayPracticeIndicatorChart(
              summary.chartData,
              state.practiceIndicatorStartDate,
              state.practiceIndicatorEndDate
            );
            addStartEndDateListeners();
          }, 0);
        }

        if (callback) callback();
      }
    ).catch((err) => {
      if (err.message === 'Not logged in') {
        window.Router.navigate('/login');
      }
      if (callback) callback();
    });
  });
};
