import api from '../api';
import {
  updateActive,
  updateBreadcrumbs,
  showAlert,
  hideLogin,
} from './common';
import ccgEditorComponent from '../components/admin/ccgEditor';
import { displayLoader } from '../loader';

const isCcgDirty = (ccgId) => {
  let isDirty = false;

  // Things with a single value
  $(
    `input[type="text"][data-ccg-id="${ccgId}"], textarea[data-ccg-id="${ccgId}"]`
  ).each(function eachInput() {
    isDirty = isDirty || $(this).val() !== $(this).data('originalValue');
  });

  // Checkboxes
  $(`input[type="checkbox"][data-ccg-id="${ccgId}"]`).each(
    function eachInput() {
      isDirty =
        isDirty || $(this).is(':checked') !== $(this).data('originalValue');
    }
  );

  // Things with multiple values
  $(`select[data-ccg-id="${ccgId}"]`).each(function eachInput() {
    isDirty =
      isDirty || $(this).val().join('') !== $(this).data('originalValue');
  });

  return isDirty;
};

export default (callback) => {
  displayLoader(
    Promise.all([api.allCcgs(), api.allPractices()]),
    ([ccgs, practices]) => {
      hideLogin();

      updateActive('link-ccgs');
      const crumbs = [{ label: 'CCG Editor' }];
      updateBreadcrumbs(crumbs);

      // const modifiedIndicators = indicators.map((indicator) => {
      //   indicator.usersWithAccess = indicator.usersWithAccess || [];
      //   indicator.practicesWithAccess = indicator.practicesWithAccess || [];
      //   indicator.isVisible = indicator.isVisible || false;
      //   return indicator;
      // });

      const adminHtml = ccgEditorComponent(ccgs, practices);

      document.getElementById('page').innerHTML = adminHtml;

      $('.tooltip').tooltip('hide');
      $('[data-toggle="tooltip"]').tooltip();

      // wire up the bootstrap-select thing
      $('.selectpicker').selectpicker();

      $('.save-ccg').on('click', (e) => {
        const { ccgId } = e.target.dataset;
        const ccg = {
          short_name: $(`.short_name[data-ccg-id="${ccgId}"]`).val(),
          long_name: $(`.long_name[data-ccg-id="${ccgId}"]`).val(),
          info: $(`.info_text[data-ccg-id="${ccgId}"]`).val(),
          isVisible: $(`[name="isVisible"][data-ccg-id="${ccgId}"]`).is(
            ':checked'
          ),
          usersWithAccess: $(
            `[name="usersWithAccess"][data-ccg-id="${ccgId}"]`
          ).val(),
          practicesWithAccess: $(
            `[name="practicesWithAccess"][data-ccg-id="${ccgId}"]`
          ).val(),
        };

        api
          .updateCCG(ccgId, ccg)
          .then((resp) => {
            if (resp.status === 'success') {
              showAlert(resp.message, 'success');
            } else {
              showAlert(resp.message, 'danger');
            }
            $(
              `input[data-ccg-id="${ccgId}"], textarea[data-ccg-id="${ccgId}"], select[data-ccg-id="${ccgId}"]`
            ).each(function eInput() {
              $(this).data('originalValue', $(this).val());
            });
            const isDirty = isCcgDirty(ccgId);
            $(`button[data-ccg-id="${ccgId}"]`).prop('disabled', !isDirty);
            setTimeout(() => {
              window.location.reload();
            }, 400);
          })
          .catch(() => {
            showAlert('Some sort of error occurred', 'danger');
          });
      });

      $('input, textarea').on('keyup', (e) => {
        const { ccgId } = e.target.dataset;

        const isDirty = isCcgDirty(ccgId);

        $(`button[data-ccg-id="${ccgId}"]`).prop('disabled', !isDirty);
      });
      $('.fa-edit').on('click', (e) => {
        const { ccgId } = e.target.dataset;
        $(`.info_text[data-ccg-id="${ccgId}"]`).show();
      });

      $('select, [type="checkbox"]').on('change', (e) => {
        const { ccgId } = e.target.dataset;

        const isDirty = isCcgDirty(ccgId);

        $(`button[data-ccg-id="${ccgId}"]`).prop('disabled', !isDirty);
      });
      if (callback) callback();
    }
  ).catch((err) => {
    if (err.message === 'Not logged in') {
      window.Router.navigate('/login');
    }
    if (callback) callback();
  });
};
