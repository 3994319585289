export default (name, isAdmin) => `
    <a class="dropdown-toggle" href="#" data-toggle="dropdown">
      <span id="usersName">${name}</span>
      <i class="fa fa-user fa-fw"></i>
      <i class="caret"></i>
    </a>
    <ul class="dropdown-menu">
      <li id="link-account"><a href="javascript:void(0)" data-href="/account"><i class="fa fa-user fa-fw"></i> Your account</a></li>
      <li id="link-change-password"><a href="javascript:void(0)" data-href="/password/change"><i class="fa fa-key fa-fw"></i> Change password</a></li>
      ${isAdmin
    ? `
        <li class="divider"></li>
        <li id="link-ccgs"><a href="javascript:void(0)" data-href="/editor/ccgs"><i class="fas fa-map-marker-alt fa-fw"></i> CCG editor</a></li>
        <li id="link-practices"><a href="javascript:void(0)" data-href="/editor/practices"><i class="fas fa-clinic-medical fa-fw"></i> Practice editor</a></li>
        <li id="link-indicators"><a href="javascript:void(0)" data-href="/editor/indicators"><i class="fas fa-vial fa-fw"></i> Indicator editor</a></li>
        <li id="link-user-list"><a href="javascript:void(0)" data-href="/user/list"><i class="fa fa-user fa-fw"></i> User management</a></li>
      `
    : ''}
      
      <li class="divider"></li>
      <li><a id="logout-button" href="/auth/logout"><i class="fa fa-sign-out-alt fa-fw"></i> Signout</a></li>
    </ul>
  `;
