const practiceOptions = practices => practices
  .sort((a, b) => {
    if (a.long_name > b.long_name) return 1;
    if (a.long_name === b.long_name) return 0;
    return -1;
  })
  .map(practice => `
    <option value="${practice._id}"${practice.isSelected ? ' selected' : ''}>${practice.long_name}${practice.ccgName ? ` (${practice.ccgName})` : ''}</option>
  `)
  .join('');

const practiceList = (practices, userPractices) => `
<select class="form-control inline-select selectpicker" id="practiceList" data-live-search="true" data-width="auto" title="Select a practice">
  ${userPractices && userPractices.length > 0
    ? `
    <optgroup label="Practices where you have patient level access">
      ${practiceOptions(practices.filter(x => userPractices.indexOf(x._id) > -1))}
    </optgroup>
  `
    : ''}
  <optgroup label="Practices where you don't have patient level access">
    ${practiceOptions(practices.filter(x => userPractices.indexOf(x._id) < 0))}
  </optgroup>  
</select>
`;

const dateList = (dates, id, name, title) => `
<div class="form-group left-buffer align-bottom">
  <label for="${name}" style="display:block;">${title}:</label>
  <select class="inline-select" id="${id}" name="${name}">
    ${dates
    .filter(date => date.shouldDisplay)
    .map(date => `
      <option value="${date._id}"${date.isSelected ? ' selected' : ''}>${date.valueToShow || date.value}</option>
    `).join('')}
  </select>
</div>
`;

export default (practices, dates, comparisonDates, userPractices) => `
<form id="filterBarPractice" class="form-inline">
  <div class="form-group">
    ${practices.length === 1
    ? `<h2>${practices[0].short_name}</h2>`
    : practiceList(practices, userPractices)}    
  </div>
  ${dateList(dates, 'dateList', 'reportDate', 'Report date')}
  ${dateList(comparisonDates, 'dateCompareList', 'comparisonDate', 'Comparison date')}
  
</form>
  `;
