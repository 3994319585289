export default (users, ccgUsersToAuthorise, practiceUsersToAuthorise, GMUsersToAuthorise) => {
  const ccgUsersToAuthRows = ccgUsersToAuthorise.map(user => `
    <tr>
      <td>${user.email}</td>
      <td>${user.name}</td>
      <td>${user.ccgName}</td>
      <td>
        <a class="btn btn-xs user-decision" title="Approve user" data-toggle="tooltip" data-container="body" data-placement="top" href="javascript:void(0)" style="color:green" data-authorise="true" data-email="${user.email}" data-ccg-id="${user.ccgId}" data-place-name="${user.ccgName}">
          <i class="fas fa-check"></i>
        </a>
        <a class="btn btn-xs user-decision" title="Reject user" data-toggle="tooltip" data-container="body" data-placement="top" style="color:red" data-email="${user.email}" data-ccg-id="${user.ccgId}" data-place-name="${user.ccgName}" href="javascript:void(0)">
          <i class="fas fa-times"></i>
        </a>
      </td>
    </tr>
  `).join('');
  const practiceUsersToAuthRows = practiceUsersToAuthorise.map(user => `
    <tr>
      <td>${user.email}</td>
      <td>${user.name}</td>
      <td>${user.practiceName}</td>
      <td>
        <a class="btn btn-xs user-decision" title="Approve user" data-toggle="tooltip" data-container="body" data-placement="top" href="javascript:void(0)" style="color:green" data-authorise="true" data-email="${user.email}" data-practice-id="${user.practiceId}" data-place-name="${user.practiceName}">
          <i class="fas fa-check"></i>
        </a>
        <a class="btn btn-xs user-decision" title="Reject user" data-toggle="tooltip" data-container="body" data-placement="top" style="color:red" data-email="${user.email}" data-practice-id="${user.practiceId}" data-place-name="${user.practiceName}" href="javascript:void(0)">
          <i class="fas fa-times"></i>
        </a>
      </td>
    </tr>
  `).join('');
  const GMUsersToAuthRows = GMUsersToAuthorise.map(user => `
    <tr>
      <td>${user.email}</td>
      <td>${user.name}</td>
      <td>
        <a class="btn btn-xs user-decision" title="Approve user" data-toggle="tooltip" data-container="body" data-placement="top" href="javascript:void(0)" style="color:green" data-authorise="true" data-email="${user.email}" data-ccg-id="GM" data-place-name="Greater Manchester">
          <i class="fas fa-check"></i>
        </a>
        <a class="btn btn-xs user-decision" title="Reject user" data-toggle="tooltip" data-container="body" data-placement="top" style="color:red" data-email="${user.email}" data-ccg-id="GM" data-place-name="Greater Manchester" href="javascript:void(0)">
          <i class="fas fa-times"></i>
        </a>
      </td>
    </tr>
  `).join('');
  const userRows = users
    .sort((a, b) => {
      if (a.email > b.email) return 1;
      return a.email === b.email ? 0 : -1;
    })
    .map(user => `
    <tr>
      <td>${user.email}</td>
      <td>${user.name}</td>
      <td title="${user.practiceNames ? user.practiceNames.join(', ') : ''}" data-toggle="tooltip" data-container="body" data-placement="left">
        ${user.practiceNames
    ? user.practiceNames.slice(0, 2).join(', ') + (user.practiceNames.length > 2 ? '...' : '')
    : 'No practices assigned'}
      </td>
      <td>${user.ccgNames.join(', ')}</td>
      <td>${user.roles.filter(x => x !== 'authenticated').join(', ')}</td>
      <td>
        <a class="btn btn-xs" title="Edit user" data-toggle="tooltip" data-container="body" data-placement="top" href="javascript:void(0)" style="color:orange" data-href="/user/edit/${user.email}">
          <i class="fas fa-pencil-alt"></i>
        </a>
        <a class="btn btn-xs delete-user" title="Delete user" data-toggle="tooltip" data-container="body" data-placement="top" style="color:red" data-email="${user.email}" href="javascript:void(0)">
          <i class="fas fa-trash-alt"></i>
        </a>
      </td>
    </tr>
  `).join('');
  return `
    <div><a class="btn btn-info" href="javascript:void(0)" data-href="/user/create">Add User</a></div>
    ${GMUsersToAuthRows.length > 0
    ? `
    <h2>Users requiring authorisation at GM level</h2>
    <table class="table table-striped table-condensed">
      <thead>
        <tr>
          <th>Email</th>
          <th>Name</th>
          <th style="width:70px">Actions</th>
        </tr>
      </thead>
      <tbody>
        ${GMUsersToAuthRows}        
      </tbody>
    </table>
    `
    : ''}
    ${ccgUsersToAuthRows.length > 0
    ? `
      <h2>Users requiring authorisation at CCG level</h2>
      <table class="table table-striped table-condensed">
      <thead>
        <tr>
          <th>Email</th>
          <th>Name</th>
          <th>CCG</th>
          <th style="width:70px">Actions</th>
        </tr>
      </thead>
      <tbody>
        ${ccgUsersToAuthRows}        
      </tbody>
    </table>
    `
    : ''}
    ${practiceUsersToAuthRows.length > 0
    ? `
      <h2>Users requiring authorisation at practice level</h2>
      <table class="table table-striped table-condensed">
      <thead>
        <tr>
          <th>Email</th>
          <th>Name</th>
          <th>Practice</th>
          <th style="width:70px">Actions</th>
        </tr>
      </thead>
      <tbody>
        ${practiceUsersToAuthRows}        
      </tbody>
    </table>
    `
    : ''}
    <h2>Authorised users</h2>
    <table class="table table-striped table-condensed">
      <thead>
        <tr>
          <th>Email</th>
          <th>Name</th>
          <th>Practices</th>
          <th>CCGs</th>
          <th>Roles</th>
          <th style="width:70px">Actions</th>
        </tr>
      </thead>
      <tbody>
        ${userRows}        
      </tbody>
    </table>
    <div id="modal" class="modal"></div>
    <a class="btn btn-info" href="javascript:void(0)" data-href="/user/create">Add User</a>
  `;
};
