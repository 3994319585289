import api from '../api';
import {
  updateActive,
  updateBreadcrumbs,
  showAlert,
  hideLogin,
} from './common';
import practiceEditorComponent from '../components/admin/practiceEditor';
import { displayLoader } from '../loader';

const isPracticeDirty = (practiceId) => {
  let isDirty = false;

  $(
    `input[data-practice-id="${practiceId}"], select[data-practice-id="${practiceId}"]`
  ).each(function eachInput() {
    isDirty = isDirty || $(this).val() !== $(this).data('originalValue');
  });

  return isDirty;
};

export default (callback) => {
  displayLoader(
    Promise.all([api.allPractices(), api.allCcgs()]),
    ([practices, ccgs]) => {
      hideLogin();

      updateActive('link-practices');
      const crumbs = [{ label: 'Practice Editor' }];
      updateBreadcrumbs(crumbs);

      const adminHtml = practiceEditorComponent(practices, ccgs);

      document.getElementById('page').innerHTML = adminHtml;

      $('.tooltip').tooltip('hide');
      $('[data-toggle="tooltip"]').tooltip();

      // wire up the bootstrap-select thing
      $('.selectpicker').selectpicker();

      $('.save-practice').on('click', (e) => {
        const { practiceId } = e.target.dataset;
        const practice = {
          short_name: $(`.short_name[data-practice-id="${practiceId}"]`).val(),
          long_name: $(`.long_name[data-practice-id="${practiceId}"]`).val(),
          ccg: $(`.ccg[data-practice-id="${practiceId}"]`).val(),
        };

        api
          .updatePractice(practiceId, practice)
          .then((resp) => {
            if (resp.status === 'success') {
              showAlert(resp.message, 'success');
            } else {
              showAlert(resp.message, 'danger');
            }
            $(
              `input[data-practice-id="${practiceId}"], select[data-practice-id="${practiceId}"]`
            ).each(function eInput() {
              $(this).data('originalValue', $(this).val());
            });
            const isDirty = isPracticeDirty(practiceId);
            $(`button[data-practice-id="${practiceId}"]`).prop(
              'disabled',
              !isDirty
            );
            setTimeout(() => {
              window.location.reload();
            }, 400);
          })
          .catch(() => {
            showAlert('Some sort of error occurred', 'danger');
          });
      });

      $('input').on('keyup', (e) => {
        const { practiceId } = e.target.dataset;

        const isDirty = isPracticeDirty(practiceId);

        $(`button[data-practice-id="${practiceId}"]`).prop(
          'disabled',
          !isDirty
        );
      });

      $('select').on('change', (e) => {
        const { practiceId } = e.target.dataset;

        const isDirty = isPracticeDirty(practiceId);

        $(`button[data-practice-id="${practiceId}"]`).prop(
          'disabled',
          !isDirty
        );
      });

      if (callback) callback();
    }
  ).catch((err) => {
    if (err.message === 'Not logged in') {
      window.Router.navigate('/login');
    }
    if (callback) callback();
  });
};
