const dateList = (dates, id, name, title) => `
<div class="form-group left-buffer align-bottom">
  <label for="${name}" style="display:block;">${title}:</label>
  <select class="inline-select" id="${id}" name="${name}">
    ${dates
    .filter(date => date.shouldDisplay)
    .map(date => `
      <option value="${date._id}" ${date.isSelected ? 'selected' : ''}>${date.value}</option>
    `).join('')}
  </select>
</div>
`;

export default dates => `
<form id="filterBarPractice" class="form-inline">
  ${dateList(dates, 'dateList', 'reportDate', 'Report date')}
</form>
`;
