const ccgList = ccgs => ((!ccgs || ccgs.length < 2)
  ? ''
  : `
  <div class="form-group">
    <select class="form-control inline-select selectpicker" id="ccgList" data-width="auto" title="Select a CCG">
    ${ccgs.sort((a, b) => {
    if (a.short_name > b.short_name) return 1;
    if (a.short_name === b.short_name) return 0;
    return -1;
  }).map(ccg => `
        <option value="${ccg._id}" ${ccg.isSelected ? 'selected' : ''}>${ccg.short_name}</option>`).join('')}
    </select>
  </div>
`);

export default ccgs => `
<form id="filterBarCcg" class="form-inline">  
  <div class="form-group">
  ${ccgs && ccgs.length === 1
    ? `<h2>${ccgs[0].short_name}</h2>`
    : ccgList(ccgs)}
  </div>
</form>
  `;
