const shortMonthLookup = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const longMonthLookup = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getLongDate = (date) =>
  date
    ? `${new Date(date).getDate()} ${
        longMonthLookup[new Date(date).getMonth()]
      } ${new Date(date).getFullYear()}`
    : 'Unknown';

const getShortDate = (date) => {
  if (Number.isNaN(Date.parse(date))) return false; // Can't parse [date] as a Date
  return `${new Date(date).getDate()} ${
    shortMonthLookup[new Date(date).getMonth()]
  } ${new Date(date).getFullYear()}`;
};

const objFromArray = (arr, id) =>
  arr.reduce((prev, curr) => {
    prev[curr[id || '_id']] = curr;
    return prev;
  }, {});

const escapeHTML = (html) =>
  html
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');

export { objFromArray, getLongDate, getShortDate, escapeHTML };
