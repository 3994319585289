const notesToDisplay = patient => patient.indicatorNotes
  .filter(note => patient.indicators.some(indicator => indicator.id === note.id));

const isPatientNote = patient => !!patient.patientNote
  || notesToDisplay(patient).length > 0;

export default patient => `
${isPatientNote(patient) ? `<p>${patient.patientNote}</p>` : ''}
${notesToDisplay(patient).map(x => `
<div>
  <p><strong>${x.name}:</strong></p>
  <ul>
    <li>${x.note}</li>
  </ul>
</div>`).join('')}
${isPatientNote(patient)
    ? `
      <p><small>Note last updated by ${patient.patientNoteUpdatedBy} on ${patient.patientNoteUpdatedLocaleString}</small></p>
      <button class="btn btn-default" data-type="edit" data-patient-id="${patient.id}" type="button">Edit Note</button>
      <button class="btn btn-default" data-type="delete" data-patient-id="${patient.id}" type="button">Delete Note</button>`
    : `
      <button class="btn btn-default" data-type="add" data-patient-id="${patient.id}" type="button">Add Note</button>`
}  
`;
