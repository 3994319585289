import state from '../state';
import api from '../api';
import { updateActive, updateBreadcrumbs, hideLogin } from './common';
import evidenceComponent from '../components/evidence';
import { displayLoader } from '../loader';

export default (callback, indicatorId) => {
  displayLoader(api.indicators(), (indicators) => {
    hideLogin();

    // if navigated here via /evidence but there is already
    // a previously selected indicator, then we default to that.
    if (indicatorId) state.indicatorId = indicatorId;
    else if (state.indicatorId) {
      // changes url from /evidence to /evidence/:indicatorId but
      // ensures the history is correct. E.g. back goes to the thing
      // before /evidence
      window.Router.shift(`/evidence/${state.indicatorId}`);
    }

    // process the data from the server
    let selectedIndicator;
    indicators.forEach((indicator) => {
      if (indicator._id === +state.indicatorId) {
        indicator.isSelected = true;
        selectedIndicator = indicator;
      } else {
        delete indicator.isSelected;
      }
    });

    // selected tab and breadcrumbs
    updateActive('tab-evidence');
    const crumbs = [
      { label: 'Indicator Evidence Summaries', path: '/evidence' },
    ];
    if (indicatorId) crumbs.push({ label: selectedIndicator.short_name });
    updateBreadcrumbs(crumbs);

    // render page
    document.getElementById('page').innerHTML = evidenceComponent(
      indicators,
      selectedIndicator
    );

    // wire up the bootstrap-select thing
    $('.selectpicker').selectpicker();

    // cause navigation on the select drop down changing
    document
      .getElementById('indicatorList')
      .addEventListener('change', (event) => {
        if (event.target.value !== state.indicatorId) {
          window.Router.navigate(`/evidence/${event.target.value}`);
        }
      });

    if (callback) callback();
  }).catch((err) => {
    if (err.message === 'Not logged in') {
      window.Router.navigate('/login');
    }
    if (callback) callback();
  });
};
