export default patient => `
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close"><span aria-hidden="true" class="close-me">&times;</span></button>
      <h4 class="modal-title" id="myModalLabel">Delete note</h4>
    </div>
    <div class="modal-body">
      <h4>Delete all notes for patient: ${patient.nhs}</h4>
      <p>Are you sure</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" data-patient-id="${patient.id}">Delete note</button>
      <button type="button" class="btn btn-default close-me">Cancel</button>
    </div>
  </div>
</div>
`;
