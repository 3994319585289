// If lots of routes then split into files in a
// directory called 'routes'
import ccg from './controllers/ccgController';
import pcn from './controllers/pcnController';
import allccgs from './controllers/allCcgController';
import evidence from './controllers/evidenceController';
import help from './controllers/helpController';
import usage from './controllers/usageController';
import practice from './controllers/practiceController';
import practiceIndicator from './controllers/practiceIndicatorController';
import multiplePatients from './controllers/multiplePatientsController';
import carehomePatients from './controllers/carehomePatientsController';
import { createUser, listUsers, editUser } from './controllers/userController';
import practiceEditor from './controllers/practiceEditorController';
import indicatorEditor from './controllers/indicatorEditorController';
import ccgEditor from './controllers/ccgEditorController';
import { login, forgot, reset, changePassword, account, register, confirmEmail, resendConfirmEmail } from './controllers/authController';

export default [
  { controller: practice, regex: /''/, isDefault: true },

  { controller: multiplePatients, regex: /^practice\/(.*)\/date\/(.*)\/multiple/ },
  { controller: carehomePatients, regex: /^practice\/(.*)\/date\/(.*)\/carehome/ },

  // /practice/:practiceId/date/:dateId/comparedWith/:comparisonDateId
  // /indicator/:indicatorId/:reportType/show/:chartOrTable/tab/:tabId
  { controller: practiceIndicator, regex: /^practice\/(.*)\/date\/(.*)\/comparedWith\/(.*)\/indicator\/(.*)\/(.*)\/show\/(.*)\/tab\/(.*)\/sort\/(.*)\/dir\/(.*)/ },
  { controller: practiceIndicator, regex: /^practice\/(.*)\/date\/(.*)\/comparedWith\/(.*)\/indicator\/(.*)\/(.*)\/show\/(.*)\/tab\/(.*)/ },
  { controller: practiceIndicator, regex: /^practice\/(.*)\/date\/(.*)\/comparedWith\/(.*)\/indicator\/(.*)\/(.*)\/show\/(.*)/ },
  { controller: practiceIndicator, regex: /^practice\/(.*)\/date\/(.*)\/comparedWith\/(.*)\/indicator\/(.*)\/(.*)/ },
  { controller: practiceIndicator, regex: /^practice\/(.*)\/date\/(.*)\/comparedWith\/(.*)\/indicator\/(.*)/ },

  { controller: practice, regex: /^practice\/(.*)\/date\/(.*)\/comparedWith\/(.*)\/tab\/(.*)\/chart\/(.*)\/sort\/(.*)\/dir\/(.*)/ },
  { controller: practice, regex: /^practice\/(.*)\/date\/(.*)\/comparedWith\/(.*)\/tab\/(.*)\/chart\/(.*)/ },
  { controller: practice, regex: /^practice\/(.*)\/date\/(.*)\/comparedWith\/(.*)\/tab\/(.*)/ },
  { controller: practice, regex: /^practice/ },

  { controller: ccg, regex: /^ccg\/(.*)\/date\/(.*)\/comparedWith\/(.*)\/tab\/(.*)\/indicator\/(.*)\/chart\/(.*)\/sort\/(.*)\/dir\/(.*)/ },
  { controller: ccg, regex: /^ccg\/(.*)\/date\/(.*)\/comparedWith\/(.*)\/tab\/(.*)\/indicator\/(.*)\/chart\/(.*)/ },
  { controller: ccg, regex: /^ccg\/(.*)\/date\/(.*)\/comparedWith\/(.*)\/tab\/(.*)\/indicator\/(.*)/ },
  { controller: ccg, regex: /^ccg\/(.*)\/date\/(.*)\/comparedWith\/(.*)\/tab\/(.*)/ },
  { controller: ccg, regex: /^ccg\/date\/(.*)\/comparedWith\/(.*)\/tab\/(.*)/ },
  { controller: ccg, regex: /^ccg/ },

  { controller: pcn, regex: /^pcn\/(.*)\/date\/(.*)\/comparedWith\/(.*)\/indicator\/(.*)\/sort\/(.*)\/dir\/(.*)/ },
  { controller: pcn, regex: /^pcn\/(.*)\/date\/(.*)\/comparedWith\/(.*)\/indicator\/(.*)/ },
  { controller: pcn, regex: /^pcn\/(.*)\/date\/(.*)\/comparedWith\/(.*)/ },
  { controller: pcn, regex: /^pcn\/date\/(.*)\/comparedWith\/(.*)/ },
  { controller: pcn, regex: /^pcn/ },

  { controller: allccgs, regex: /^allccgs\/date\/(.*)\/indicator\/(.*)\/sort\/(.*)\/dir\/(.*)/ },
  { controller: allccgs, regex: /^allccgs\/date\/(.*)\/indicator\/(.*)/ },
  { controller: allccgs, regex: /^allccgs\/date\/(.*)/ },
  { controller: allccgs, regex: /^allccgs/ },

  { controller: evidence, regex: /^evidence\/?(.*)/ },

  { controller: usage, regex: /^usage\/?(.*)\/sort\/(.*)\/dir\/(.*)/ },
  { controller: usage, regex: /^usage\/?(.*)/ },
  { controller: usage, regex: /^usage/ },

  { controller: help, regex: /^help/ },

  // Admin routes
  { controller: editUser, regex: /^user\/edit\/(.*)/ },
  { controller: createUser, regex: /^user\/create/ },
  { controller: listUsers, regex: /^user\/list/ },

  { controller: practiceEditor, regex: /^editor\/practices/ },
  { controller: indicatorEditor, regex: /^editor\/indicators/ },
  { controller: ccgEditor, regex: /^editor\/ccgs/ },

  { controller: login, regex: /^login/ },
  { controller: reset, regex: /^reset\/(.*)/ },
  { controller: forgot, regex: /^forgot/ },
  { controller: register, regex: /^register/ },
  { controller: confirmEmail, regex: /^confirm\/(.*)/ },
  { controller: changePassword, regex: /^password\/change/ },
  { controller: account, regex: /^account/ },

  { controller: resendConfirmEmail, regex: /^resendConfirm\/(.*)/ },
];
