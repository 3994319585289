export default (summary, indicatorId, activeTab, activeChart) => `
<div class="pull-right">
  <button class="btn btn-primary" id="export" type="button"${+activeTab === 1 ? '' : ' style="display:none;"'}>Export</button>
</div>
<ul class="nav nav-tabs" role="tablist">
  <li${+activeTab === 1 ? ' class="active"' : ''} role="presentation"><a id="tableTab" href="#table" aria-controls="table" role="tab" data-toggle="tab" data-id="1">Table</a></li>
  <li${+activeTab === 2 ? ' class="active"' : ''} role="presentation"><a href="#charts" aria-controls="charts" role="tab" data-toggle="tab" data-id="2">Charts</a></li>
</ul>
<div class="tab-content">
  <div class="tab-pane tab-borders fade${+activeTab === 1 ? ' active in' : ''}" id="table" role="tabpanel">
    <div class="clearfix"></div>
    <div class="top-buffer">
      <table class="table table-striped" id="indicatorTable">
        <thead>
          <tr>
            <th class="pointer" title="The GP practice" data-toggle="tooltip" data-container="body" data-placement="top">Practice</th>
  ${indicatorId === 0
    ? `
            <th class="pointer" title="The number of patients affected by any one of the prescribing safety indicators across all practices" data-toggle="tooltip" data-container="body" data-placement="top"">Affected patients</th>
            <th class="pointer" title="Proportion of eligible patients across all practices who are affected by at least one indicator" data-toggle="tooltip" data-container="body" data-placement="top"">% of eligible patients affected</th>
            <th class="pointer" title="Proportion of eligible patients across practices in this CCG who are affected by at least one indicator" data-toggle="tooltip" data-container="body" data-placement="top"">CCG Avg (%)</th>
            <th class="pointer" title="The number of patients affected by more than 1 prescribing safety indicator across all practices" data-toggle="tooltip" data-container="body" data-placement="top"">More than one indicator</th>`
    : `
            <th class="pointer" title="The number of patients affected by this indicator across all practices" data-toggle="tooltip" data-container="body" data-placement="top">Affected patients</th>
            <th class="pointer" title="The population of patients across all practices that this indicator could affect" data-toggle="tooltip" data-container="body" data-placement="top">Eligible patients</th>
            <th class="pointer" title="Proportion of the population in focus that are affected by this indicator across all practices" data-toggle="tooltip" data-container="body" data-placement="top">% of eligible patients affected</th>
            <th class="pointer" title="Proportion of the population in focus that are affected by this indicator across all practices in the local CCG" data-toggle="tooltip" data-container="body" data-placement="top">CCG Avg (%)</th>
            <th class="pointer" title="The number of patients affected by more than 1 indicator across all practices" data-toggle="tooltip" data-container="body" data-placement="top">More than one indicator</th>
            <th class="pointer" title="Patients no longer affected by this indicator since the last comparison date across all practices" data-toggle="tooltip" data-container="body" data-placement="top">Successful intervention</th>
            <th class="pointer" title="Patients who remain affected by this indicator since the last comparison date" data-toggle="tooltip" data-container="body" data-placement="top">Action pending</th>
            <th class="pointer" title="Patients affected by this indicator who weren't affected on the last comparison date" data-toggle="tooltip" data-container="body" data-placement="top">New cases</th>
            <th class="pointer" title="Difference in the number of affected patients between last comparison date and current" data-toggle="tooltip" data-container="body" data-placement="top">Trend</th>
    `}
          </tr>
        </thead>
        <tbody>
    ${summary.tableData.map(item => `
          <tr class="no-priority">
            <td class="prominentLink" title="Click to view practice" data-toggle="tooltip" data-container="body" data-placement="left" data-href="/practice/${item.id}/date/${summary.dateId}/comparedWith/${summary.comparisonDateId}/tab/undefined"><a class="prominentLink" href="javascript:void(0)">${item.short_name}</a></td>
            <td>${item.num}</td>
    ${indicatorId === 0
    ? `     <td>${item.avg}</td>
            <td>${item.ccg}</td>
            <td>${item.patientsMultiple}</td>`
    : `     <td>${item.denom}</td>
            <td>${item.avg}</td>
            <td>${item.ccg}</td>
            <td>${item.patientsMultiple}</td>
            <td>${item.resolved}</td>
            <td>${item.existing}</td>
            <td>${item.new}</td>
            <td>${item.trendValue}</td>
    `}
          </tr>
    `).join('')}
        </tbody>
      </table>
    </div>
  </div>
  <div class="tab-pane tab-borders fade${+activeTab === 2 ? ' active in' : ''}" id="charts" role="tabpanel">
    <div style="padding:10px;">
      <select class="selectpicker" id="id_chart" data-width="auto" title="Please select a chart to view...">
  ${indicatorId === 0
    ? ` <option value="1"${+activeChart === 1 ? ' selected' : ''}>Proportion of eligible patients affected</option>
        <option value="2"${+activeChart === 2 ? ' selected' : ''}>Split of patients affected by 1 indicator and those affected by 2 or more</option>
        <option value="3"${+activeChart === 3 ? ' selected' : ''}>Number of affected patients over time</option>
        <option value="4"${+activeChart === 4 ? ' selected' : ''}>Number of affected patients over time (all practices combined)</option>
        <option value="5"${+activeChart === 5 ? ' selected' : ''}>Proportion of eligible patients affected over time</option>
        <option value="6"${+activeChart === 6 ? ' selected' : ''}>Number of patients affected by more than one indicator over time</option>`
    : ` <option value="7"${+activeChart === 7 ? ' selected' : ''}>Proportion of eligible patients affected</option>
        <option value="8"${+activeChart === 8 ? ' selected' : ''}>Number of affected patients over time</option>
        <option value="9"${+activeChart === 9 ? ' selected' : ''}>Proportion of eligible patients affected over time</option>
  `}
      </select>

      ${+activeChart === 2 ?  `
      Sort by:
      <select class="selectpicker" id="id_chart_sort" data-width="auto" title="Please select sort...">
      <option value="1" selected="selected"}>Total</option>
      <option value="2" }>2+ Indicators</option>
      </select>
      `  : ""}
    </div>
    <div id="chartPanel"></div>
  </div>
</div>
`;
